import * as React from "react";
import { useNavigate } from "react-router-dom";

import type { SessionUser } from "@corbado/types";

import { useStyletron } from "baseui";
import { AppNavBar, NavItem, setItemActive } from "baseui/app-nav-bar";

import {
  adminIcon,
  editIcon,
  gasIcon,
  heartIcon,
  keyIcon,
  legacyIcon,
  logoutIcon,
  mapIcon,
  oilIcon,
  peopleIcon,
  roadIcon,
  routeIcon,
  trackIcon,
  usersIcon,
  xIcon
} from "@smc/components";

import { SiteTitle } from "./site-title";

interface NavBarProps {
  user?: SessionUser;
  logout: () => void;
}

export default function NavBar({ user, logout }: Readonly<NavBarProps>) {
  const [css] = useStyletron();
  const navigate = useNavigate();

  const [mainItems, setMainItems] = React.useState<NavItem[]>([
    {
      icon: roadIcon,
      label: "Journeys",
      info: { link: false },
      children: [
        {
          icon: trackIcon,
          label: "Track",
          info: { link: true, destination: "journeys/track" }
        },
        {
          icon: routeIcon,
          label: "Watch",
          info: { link: true, destination: "journeys/watch" }
        },
        {
          icon: mapIcon,
          label: "Map",
          info: { link: true, destination: "journeys/map" }
        },
        {
          icon: editIcon,
          label: "Edit",
          info: { link: true, destination: "journeys/edit" }
        }
      ]
    },
    { icon: heartIcon, label: "Vitals", info: { link: true } },
    {
      icon: adminIcon,
      label: "Admin",
      info: { link: false },
      children: [
        {
          icon: usersIcon,
          label: "Users",
          info: { link: true, destination: "admin/users" }
        }
      ]
    },
    {
      icon: legacyIcon,
      label: "Legacy",
      navExitIcon: xIcon,
      info: { link: false, menu: true },
      children: [
        {
          icon: gasIcon,
          label: "Petrol",
          info: { link: true },
          children: [{ icon: oilIcon, label: "Add", info: { link: true } }]
        },
        { icon: peopleIcon, label: "Pagans", info: { link: true } }
      ]
    }
  ]);

  const userItems = [
    { icon: keyIcon, label: "Passkeys", info: { link: true } },
    { icon: logoutIcon, label: "Logout" }
  ];

  function handleMainItemSelect(item: NavItem) {
    setMainItems((prev) => setItemActive(prev, item));

    if (item.info.destination) {
      navigate(item.info.destination);
    } else if (item.info.link) {
      navigate(item.label.toLowerCase());
    }
  }

  function handleUserItemSelect(item: NavItem) {
    switch (item.label) {
      case "Logout":
        logout();
        break;
      case "Passkeys":
        navigate(item.label.toLowerCase());
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div
        className={css({
          boxSizing: "border-box",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0"
        })}
      >
        <AppNavBar
          title={<SiteTitle />}
          mainItems={mainItems}
          userItems={userItems}
          onMainItemSelect={handleMainItemSelect}
          onUserItemSelect={handleUserItemSelect}
          username={user?.name}
          usernameSubtitle={user?.email}
          userImgUrl=""
        />
      </div>
      {/* TODO: this isn't right */}
      <div className={css({ height: "64px" })} />
    </>
  );
}
