var _ProcessHistoryHandler_instances, _ProcessHistoryHandler_handleNavigationEvents, _ProcessHistoryHandler_abortController, _ProcessHistoryHandler_getCurrentLocationHash;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { BlockTypes } from './constants';
export class ProcessHistoryHandler {
    constructor(handleNavigationEvents) {
        _ProcessHistoryHandler_instances.add(this);
        _ProcessHistoryHandler_handleNavigationEvents.set(this, void 0);
        _ProcessHistoryHandler_abortController.set(this, void 0);
        __classPrivateFieldSet(this, _ProcessHistoryHandler_handleNavigationEvents, handleNavigationEvents, "f");
        __classPrivateFieldSet(this, _ProcessHistoryHandler_abortController, new AbortController(), "f");
    }
    init(maybeSwitchToBlock, askForAbort) {
        if (!__classPrivateFieldGet(this, _ProcessHistoryHandler_handleNavigationEvents, "f")) {
            return null;
        }
        window.addEventListener('hashchange', () => {
            const blockNameFromHash = __classPrivateFieldGet(this, _ProcessHistoryHandler_instances, "m", _ProcessHistoryHandler_getCurrentLocationHash).call(this);
            const blockType = blockNameFromHash;
            const switchWasPossible = maybeSwitchToBlock(blockType);
            if (!switchWasPossible) {
                askForAbort();
                return;
            }
        }, { signal: __classPrivateFieldGet(this, _ProcessHistoryHandler_abortController, "f").signal });
        const currentLocationHash = __classPrivateFieldGet(this, _ProcessHistoryHandler_instances, "m", _ProcessHistoryHandler_getCurrentLocationHash).call(this);
        if (currentLocationHash) {
            // we define those two overrides because they are more intuitive than the hash values
            if (currentLocationHash === 'register') {
                return BlockTypes.SignupInit;
            }
            if (currentLocationHash === 'login') {
                return BlockTypes.LoginInit;
            }
            return currentLocationHash;
        }
        return null;
    }
    registerBlockChange(blockType) {
        if (!__classPrivateFieldGet(this, _ProcessHistoryHandler_handleNavigationEvents, "f")) {
            return;
        }
        // for block types that mark the end of an auth process we do not want to change the hash
        if (blockType === BlockTypes.Completed || blockType === BlockTypes.ContinueOnOtherEnv) {
            return;
        }
        history.pushState(null, '', `#${blockType}`);
    }
    dispose() {
        if (!__classPrivateFieldGet(this, _ProcessHistoryHandler_handleNavigationEvents, "f")) {
            return;
        }
        __classPrivateFieldGet(this, _ProcessHistoryHandler_abortController, "f").abort();
    }
}
_ProcessHistoryHandler_handleNavigationEvents = new WeakMap(), _ProcessHistoryHandler_abortController = new WeakMap(), _ProcessHistoryHandler_instances = new WeakSet(), _ProcessHistoryHandler_getCurrentLocationHash = function _ProcessHistoryHandler_getCurrentLocationHash() {
    return window.location.hash.replace('#', '');
};
