export type Health = {
  status: DependencyHealth;
  dependencies: DependencyHealth[];
};

export enum HealthStatus {
  ok = "ok",
  degraded = "degraded",
  error = "error",
  unknown = "unknown"
}

export type DependencyHealth = {
  name: string;
  health: {
    status: HealthStatus;
    performance: number;
    error?: string;  
  };
};
