import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCorbado } from '../../hooks/useCorbado';
import { PrimaryButton } from '../ui/buttons/PrimaryButton';
import { Dialog } from '../ui/Dialog';
import { AddIcon } from '../ui/icons/AddIcon';
export const PasskeyCreate = memo(({ fetchPasskeys }) => {
    var _a, _b, _c;
    const { t } = useTranslation('translation', { keyPrefix: 'passkey-list' });
    const { appendPasskey } = useCorbado();
    const [dialogError, setDialogError] = useState();
    const [loading, setLoading] = useState(false);
    const translatedTexts = useMemo(() => ({
        buttonText: t('button_createPasskey'),
    }), [t]);
    const translatedError = useMemo(() => {
        if (!dialogError) {
            return null;
        }
        switch (dialogError.name) {
            case 'errors.passkeyAlreadyExists':
                return {
                    dialogHeader: t('dialog_passkeyAlreadyExists.header'),
                    dialogBody: t('dialog_passkeyAlreadyExists.body'),
                    dialogConfirmText: t('dialog_passkeyAlreadyExists.button_confirm'),
                };
            case 'errors.passkeysNotSupported':
                return {
                    dialogHeader: t('dialog_passkeysNotSupported.header'),
                    dialogBody: t('dialog_passkeysNotSupported.body'),
                    dialogConfirmText: t('dialog_passkeysNotSupported.button_confirm'),
                };
            default:
                return null;
        }
    }, [t, dialogError]);
    const showError = (error) => {
        setDialogError(error);
    };
    const closeDialog = () => {
        setDialogError(undefined);
    };
    const createPasskey = async () => {
        setLoading(true);
        const result = await appendPasskey();
        if (result === null || result === void 0 ? void 0 : result.err) {
            showError(result.val);
        }
        if (result === null || result === void 0 ? void 0 : result.ok) {
            await fetchPasskeys();
        }
        setLoading(false);
    };
    return (React.createElement("div", null,
        React.createElement(PrimaryButton, { className: 'cb-passkey-list-primary-button', isLoading: loading, onClick: () => void createPasskey() },
            React.createElement(AddIcon, null),
            " ",
            translatedTexts.buttonText),
        React.createElement(Dialog, { isOpen: translatedError !== null, header: (_a = translatedError === null || translatedError === void 0 ? void 0 : translatedError.dialogHeader) !== null && _a !== void 0 ? _a : '', body: (_b = translatedError === null || translatedError === void 0 ? void 0 : translatedError.dialogBody) !== null && _b !== void 0 ? _b : '', confirmText: (_c = translatedError === null || translatedError === void 0 ? void 0 : translatedError.dialogConfirmText) !== null && _c !== void 0 ? _c : '', onClose: closeDialog, onConfirm: closeDialog })));
});
