import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField, LoadingSpinner, PasskeyListErrorBoundary, PhoneInputField, Text } from '../../components';
import { useCorbado } from '../../hooks/useCorbado';
export const User = () => {
    const { corbadoApp, isAuthenticated, globalError, getFullUser } = useCorbado();
    const { t } = useTranslation('translation', { keyPrefix: 'user' });
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        const abortController = new AbortController();
        void getCurrentUser(abortController);
        return () => {
            abortController.abort();
        };
    }, [isAuthenticated]);
    const headerText = useMemo(() => t('header'), [t]);
    const nameFieldLabel = useMemo(() => t('name'), [t]);
    const usernameFieldLabel = useMemo(() => t('username'), [t]);
    const emailFieldLabel = useMemo(() => t('email'), [t]);
    const phoneFieldLabel = useMemo(() => t('phone'), [t]);
    const socialFieldLabel = useMemo(() => t('social'), [t]);
    const verifiedText = useMemo(() => t('verified'), [t]);
    const unverifiedText = useMemo(() => t('unverified'), [t]);
    const processUser = useMemo(() => {
        var _a;
        if (!currentUser) {
            return {
                name: '',
                emails: [],
                phoneNumbers: [],
                socialAccounts: [],
            };
        }
        return {
            name: currentUser.fullName,
            username: (_a = currentUser.identifiers.find(id => id.type === 'username')) === null || _a === void 0 ? void 0 : _a.value,
            emails: currentUser.identifiers.filter(id => id.type === 'email'),
            phoneNumbers: currentUser.identifiers.filter(id => id.type === 'phone'),
            socialAccounts: currentUser.socialAccounts,
        };
    }, [currentUser]);
    const getCurrentUser = useCallback(async (abortController) => {
        setLoading(true);
        const result = await getFullUser(abortController);
        if (result.err && result.val.ignore) {
            return;
        }
        if (!result || (result === null || result === void 0 ? void 0 : result.err)) {
            throw new Error(result === null || result === void 0 ? void 0 : result.val.name);
        }
        setCurrentUser(result.val);
        setLoading(false);
    }, [corbadoApp]);
    if (!isAuthenticated) {
        return React.createElement("div", null, t('warning_notLoggedIn'));
    }
    if (loading) {
        return React.createElement(LoadingSpinner, null);
    }
    return (React.createElement(PasskeyListErrorBoundary, { globalError: globalError },
        React.createElement("div", { className: 'cb-user-details-section' },
            React.createElement(Text, { level: '5', fontWeight: 'bold', className: 'cb-user-details-section-header' }, headerText),
            processUser.name && (React.createElement(InputField, { className: 'cb-user-details-section-indentifier', key: `user-entry-${processUser.name}`, label: nameFieldLabel, value: processUser.name, disabled: true })),
            processUser.username && (React.createElement(InputField, { className: 'cb-user-details-section-indentifier', key: `user-entry-${processUser.username}`, label: usernameFieldLabel, value: processUser.username, disabled: true })),
            React.createElement("div", { className: 'cb-user-details-section-indentifiers-list' }, processUser.emails.map((email, i) => (React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item', key: `user-details-email-${email.value}` },
                React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item-field' },
                    React.createElement(InputField, { className: 'cb-user-details-section-indentifiers-list-item-field-input', key: email.value, label: i === 0 ? emailFieldLabel : undefined, value: email.value, disabled: true })),
                React.createElement("div", { className: `cb-user-details-section-indentifiers-list-item-badge cb-user-details-section-indentifiers-list-item-badge-${email.status === 'verified' ? 'primary' : 'secondary'}` },
                    React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', fontWeight: 'bold', className: 'cb-user-details-section-indentifiers-list-item-badge-text' }, email.status === 'verified' ? verifiedText : unverifiedText)))))),
            React.createElement("div", { className: 'cb-user-details-section-indentifiers-list' }, processUser.phoneNumbers.map((phone, i) => (React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item', key: `user-details-phone-${phone.value}` },
                React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item-field' },
                    React.createElement(PhoneInputField, { className: 'cb-user-details-section-indentifiers-list-item-field-input', key: phone.value, label: i === 0 ? phoneFieldLabel : undefined, initialPhoneNumber: phone.value, disabled: true })),
                React.createElement("div", { className: `cb-user-details-section-indentifiers-list-item-badge cb-user-details-section-indentifiers-list-item-badge-${phone.status === 'verified' ? 'primary' : 'secondary'}` },
                    React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', fontWeight: 'bold', className: 'cb-user-details-section-indentifiers-list-item-badge-text' }, phone.status === 'verified' ? verifiedText : unverifiedText)))))),
            React.createElement("div", { className: 'cb-user-details-section-indentifiers-list' }, processUser.socialAccounts.map((social, i) => (React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item', key: `user-details-email-${social.providerType}` },
                React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item-field' },
                    React.createElement(InputField, { className: 'cb-user-details-section-indentifiers-list-item-field-input', key: social.providerType, label: i === 0 ? socialFieldLabel : undefined, value: `${social.fullName} - ${social.identifierValue}`, disabled: true })),
                React.createElement("div", { className: 'cb-user-details-section-indentifiers-list-item-badge cb-user-details-section-indentifiers-list-item-badge-primary' },
                    React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', fontWeight: 'bold', className: 'cb-user-details-section-indentifiers-list-item-badge-text' }, t(`providers.${social.providerType}`) || social.providerType)))))))));
};
