import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

import { Theme } from "baseui";

import { DarkStealTheme, LightStealTheme } from "./theme";

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
  chooseTheme: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: DarkStealTheme,
  toggleTheme: () => {},
  chooseTheme: () => {}
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>(DarkStealTheme);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === LightStealTheme ? DarkStealTheme : LightStealTheme
    );
    console.log("Mode Switched!");
  };

  const chooseTheme = (theme: Theme) => {
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, chooseTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const useThemeDetector = () => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: {
    matches: boolean | ((prevState: boolean) => boolean);
  }) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);
  return isDarkTheme;
};
