import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { DeeTeeOh } from "../../types/dee-tee-oh";
import LockWindow from "../../types/lock-window";

export function StealChart(props: {
  deeteeoh?: DeeTeeOh;
  lockWindows?: LockWindow[];
  enablePetrol?: boolean;
}) {
  return (
    <ResponsiveContainer width="95%" height={600}>
      <LineChart
        data-testid="line-chart"
        data={props.deeteeoh?.history}
        margin={{
          top: 5,
          right: 40,
          left: 20,
          bottom: 5
        }}
      >
        <Line
          yAxisId="left"
          type="linear"
          dataKey="mileage"
          stroke="#0284c7"
          strokeWidth={3}
          dot={false}
          activeDot={{ r: 8 }}
          animationDuration={4500}
        />
        {props.enablePetrol && (
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="petrolPercentage"
            stroke="#059669"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 8 }}
            animationBegin={1000}
            animationDuration={3000}
          />
        )}

        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="date" tick={false} stroke="#09090b" />
        <YAxis
          yAxisId="left"
          type="number"
          domain={[
            props.deeteeoh!.mileageChartBoundaries.min,
            props.deeteeoh!.mileageChartBoundaries.max
          ]}
          stroke="#09090b"
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          type="number"
          domain={[0, 100]}
          stroke="#09090b"
        />

        {props.lockWindows?.map((l) => (
          <ReferenceArea
            key={l.Start.toString()}
            yAxisId="right"
            x1={l.Start.toString()}
            x2={l.End.toString()}
            y1="0"
            y2="50"
            stroke={l.IsLocked ? "green" : "red"}
            label={l.IsLocked ? "locked" : "unlocked"}
            strokeOpacity={0.3}
          />
        ))}

        <Tooltip
          data-testid="tooltip"
          cursor={{ stroke: "orange", strokeWidth: 2 }}
          animationDuration={1000}
          offset={200}
          labelStyle={{
            color: "#334155",
            backgroundColor: "#334155"
          }}
        />
        <Legend fontFamily="Fira Sans" fontStyle="Cursive" />
      </LineChart>
    </ResponsiveContainer>
  );
}
