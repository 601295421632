import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

import { useStyletron } from "baseui";
import { Segment, SegmentedControl } from "baseui/segmented-control";

import { googleIcon, leafletIcon, mapboxIcon, mapkitIcon } from "../icons";
import StealMapType from "./StealMapType";

const defaultMap = StealMapType.Google;

interface MapContextType {
  map: StealMapType;
}

export const MapContext = createContext<MapContextType>({
  map: defaultMap
});

const styleToTitleCase = (style: string) => {
  // return style.charAt(0).toUpperCase() + style.slice(1);
  return style.charAt(0).toUpperCase();
};

export const MapProvider = ({ children }: { children: ReactNode }) => {
  const [map, setMap] = useState<StealMapType>(defaultMap);

  const [css] = useStyletron();

  useEffect(() => {
    setMap(map);
  }, [map]);

  return (
    <MapContext.Provider value={{ map }}>
      {children}
      <div
        className={css({
          position: "fixed",
          bottom: "10px",
          right: "10px",
          padding: "1rem",

          fontSize: "2rem",
          cursor: "cell",
          zIndex: 1000000
        })}
      >
        <SegmentedControl
          activeKey={map}
          onChange={({ activeKey }) => {
            setMap(activeKey as StealMapType);
          }}
        >
          <Segment
            key={StealMapType.Google}
            artwork={googleIcon}
            label={styleToTitleCase(StealMapType.Google)}
          />
          <Segment
            key={StealMapType.Mapbox}
            artwork={mapboxIcon}
            label={styleToTitleCase(StealMapType.Mapbox)}
          />
          <Segment
            key={StealMapType.Leaflet}
            artwork={leafletIcon}
            label={styleToTitleCase(StealMapType.Leaflet)}
          />
          <Segment
            key={StealMapType.Mapkit}
            artwork={mapkitIcon}
            label={styleToTitleCase(StealMapType.Mapkit)}
            disabled
          />
        </SegmentedControl>
      </div>
    </MapContext.Provider>
  );
};

export const useMap = (): MapContextType => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};
