import githubSrc from '@corbado/shared-ui/assets/github.svg';
import githubDarkSrc from '@corbado/shared-ui/assets/github-dark.svg';
import React from 'react';
import useTheme from '../../../hooks/useTheme';
import { Icon } from './Icon';
export const GithubIcon = props => {
    const { darkMode } = useTheme();
    return (React.createElement(Icon, { src: darkMode ? githubDarkSrc : githubSrc, ...props }));
};
export default GithubIcon;
