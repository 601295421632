import { SocialDataStatusEnum } from '@corbado/web-core';
import { AuthType } from '@corbado/web-core';
import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class SignupInitBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, data) {
        var _a, _b, _c, _d, _e;
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.SignupInit;
        this.authType = AuthType.Signup;
        this.initialScreen = ScreenNames.SignupInit;
        let email = null;
        let phone = null;
        let userName = null;
        let fullName = null;
        data.identifiers.forEach(item => {
            switch (item.type) {
                case 'email':
                    email = {
                        value: item.identifier,
                        translatedError: errorTranslator.translateWithIdentifier(item.error, item.type),
                    };
                    break;
                case 'phone':
                    phone = {
                        value: item.identifier,
                        translatedError: errorTranslator.translateWithIdentifier(item.error, item.type),
                    };
                    break;
                case 'username':
                    userName = {
                        value: item.identifier,
                        translatedError: errorTranslator.translateWithIdentifier(item.error, item.type),
                    };
                    break;
            }
        });
        if (data.fullName) {
            fullName = { value: data.fullName.fullName, translatedError: errorTranslator.translate(data.fullName.error) };
        }
        this.data = {
            fullName: fullName,
            email: email,
            phone: phone,
            userName: userName,
            socialData: {
                providers: ((_b = (_a = data.socialData) === null || _a === void 0 ? void 0 : _a.providers) === null || _b === void 0 ? void 0 : _b.map(provider => {
                    return { name: provider };
                })) || [],
                oAuthUrl: (_c = data.socialData) === null || _c === void 0 ? void 0 : _c.oauthUrl,
                started: ((_d = data.socialData) === null || _d === void 0 ? void 0 : _d.status) === SocialDataStatusEnum.Started || false,
                finished: ((_e = data.socialData) === null || _e === void 0 ? void 0 : _e.status) === SocialDataStatusEnum.Finished || false,
            },
        };
        // errors in social logins should not be displayed in the login form (like we do for identifiers) but should appear on top of the screen
        if (data.error) {
            this.setError(data.error);
        }
    }
    async startSocialVerify(providerType) {
        const redirectUrl = window.location.origin + window.location.pathname;
        const res = await this.app.authProcessService.startSocialVerification(providerType, redirectUrl, AuthType.Signup);
        if (!res) {
            return;
        }
        this.updateProcess(res);
    }
    async updateUserData(identifiers, fullName) {
        const loginIdentifiers = [];
        if (identifiers.email) {
            loginIdentifiers.push({ type: 'email', identifier: identifiers.email });
        }
        if (identifiers.phone) {
            loginIdentifiers.push({ type: 'phone', identifier: identifiers.phone });
        }
        if (identifiers.userName) {
            loginIdentifiers.push({ type: 'username', identifier: identifiers.userName });
        }
        const b = await this.app.authProcessService.initSignup(loginIdentifiers, fullName);
        this.updateProcess(b);
    }
    switchToLogin() {
        const newPrimary = this.alternatives[0];
        const newAlternatives = [this];
        this.updateProcessFrontend(newPrimary, newAlternatives);
    }
    async finishSocialVerification(abortController) {
        const res = await this.app.authProcessService.finishSocialVerification(abortController);
        this.updateProcess(res);
    }
}
