import * as React from "react";

import { DatePicker } from "baseui/datepicker";

const DatePickerComponent = () => {
  const [value] = React.useState([new Date()]);
  return (
    <DatePicker
      value={value}
      placeholder="datepicker"
      overrides={{
        Input: {
          props: {
            overrides: {
              Root: {
                style: {
                  marginBottom: "1rem"
                }
              }
            }
          }
        }
      }}
    />
  );
};

export default DatePickerComponent;
