import { useEffect, useState } from "react";

import { faFirefoxBrowser } from "@fortawesome/free-brands-svg-icons";
import {
  faCity,
  faCloudBolt,
  faWindowMaximize
} from "@fortawesome/free-solid-svg-icons";

import { useStyletron } from "baseui";
import { Accordion } from "baseui/accordion";
import { Button, KIND } from "baseui/button";
import { Card } from "baseui/card";

import { getSemanticVersion } from "@smc-legacy/functions/utils";

import { useAxios } from "@smc/hooks";
import { StealMyVersionV5 } from "@smc/types/steal-my-version-v5";
import { appVersion } from "@smc/version";

import { VitalPanel } from "./VitalPanel";

export default function ComponentVersions() {
  const { apiClient } = useAxios();
  const [css] = useStyletron();
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const [apiVer, setApiVer] = useState<StealMyVersionV5>({
    infrastructureVersion: "0.1.0",
    managedApiVersion: "0.1.0",
    dllVersion: "0.1.0.0"
  });

  useEffect(() => {
    fetchVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVersions = async () => {
    const response = await apiClient.get("version");
    setApiVer(await response.data);
  };

  const panels = [
    {
      title: "Web",
      icon: faFirefoxBrowser,
      children: appVersion
    },
    {
      title: "Managed API",
      icon: faWindowMaximize,
      children: apiVer?.managedApiVersion
    },
    {
      title: "Legacy API",
      icon: faCloudBolt,
      children: getSemanticVersion(apiVer?.dllVersion)
    },
    {
      title: "Infrastructure",
      icon: faCity,
      children: apiVer?.infrastructureVersion
    }
  ];

  return (
    <Card title="Component Versions">
      <Accordion accordion={isAccordionActive}>
        {panels.map(({ title, icon, children }) => (
          <VitalPanel
            title={title}
            icon={icon}
            key={title}
            children={children}
          />
        ))}
      </Accordion>
      <div className={css({ height: "10px" })}></div>
      <div
        className={css({
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        })}
      >
        <Button onClick={fetchVersions}>Refresh</Button>
        <Button
          kind={KIND.secondary}
          onClick={() => setIsAccordionActive(!isAccordionActive)}
        >
          Do Nothing
        </Button>
      </div>
    </Card>
  );
}
