import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useStyletron } from "baseui";

import { Title } from "@smc/components";
import { usePermissions } from "@smc/hooks";

function AdminContainer() {
  const [css] = useStyletron();
  const { isAdmin } = usePermissions();

  useEffect(() => {
    isAdmin();
  }, [isAdmin]);

  return (
    <div
      className={css({
        textAlign: "center",
        margin: "auto",
        width: "95vw"
      })}
    >
      <Title title="Megladmin" subtitle="You're in bandit country" />
      <Outlet />
    </div>
  );
}

export default AdminContainer;
