import secureIconSrc from '@corbado/shared-ui/assets/secure-icon.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const SecureIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, secureIconSrc);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'secure-icon', ...props }));
});
