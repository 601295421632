import { styled } from "styletron-react";

import { toDecimalPlaces } from "../../functions/utils";

const JagHeader = styled("h2", {
  color: "#0284c7",
  fontSize: "2.25rem",
  lineHeight: "2.5rem",
  fontWeight: "bold",
  fontVariant: "small-caps",
  fontFamily: "sans-serif",
  margin: 0
});

const SummaryHeader = styled("h3", {
  color: "#059669",
  fontSize: "1.25rem",
  lineHeight: "1.75rem",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  marginTop: 0,
  marginBottom: "0.5rem"
});

export function LineChartHeader(
  props: Readonly<{
    count?: number;
    totalMileage?: number;
    averageMileage?: number;
  }>
) {
  return (
    <div>
      <JagHeader data-testid="jag-header">{props.count} Jags</JagHeader>
      <SummaryHeader title="mileage-header">
        Total: {props.totalMileage} | Average:{" "}
        {toDecimalPlaces(props.averageMileage ?? 0, 2)}
      </SummaryHeader>
    </div>
  );
}
