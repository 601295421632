import React, { useEffect, useState } from 'react';
import ThemeContext from './ThemeContext';
export const ThemeProvider = ({ children, theme, darkModeSubject }) => {
    var _a;
    const [themeState, setThemeState] = useState(theme);
    const [darkMode, setDarkMode] = useState((_a = darkModeSubject === null || darkModeSubject === void 0 ? void 0 : darkModeSubject.getValue()) !== null && _a !== void 0 ? _a : false);
    const [themeUpdateTS, setThemeUpdatedTs] = useState(0);
    useEffect(() => {
        const observerCallback = () => {
            setThemeUpdatedTs(Date.now());
        };
        const observer = new MutationObserver(observerCallback);
        observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            subtree: false,
        });
        return () => {
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        updateTheme(theme !== null && theme !== void 0 ? theme : '');
    }, [theme]);
    useEffect(() => {
        if (!darkModeSubject) {
            return;
        }
        darkModeSubject.subscribe(isDarkMode => {
            setDarkMode(isDarkMode);
        });
        return () => {
            darkModeSubject.unsubscribe();
        };
    }, [darkModeSubject]);
    const updateTheme = (theme) => {
        setThemeState(theme);
        setThemeUpdatedTs(Date.now());
    };
    return (React.createElement(ThemeContext.Provider, { value: { theme: themeState, darkMode, themeUpdateTS } }, children));
};
