import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useStyletron } from "baseui";
import { TreeNodeData, TreeView, toggleIsExpanded } from "baseui/tree-view";
import { DateTime } from "luxon";

import { Journey } from "@smc-api/middleware/db/schema";

import { SelectAJourney } from "@smc/components";
import { useAxios } from "@smc/hooks";

export default function WatchRouteList() {
  const { apiClient } = useAxios();
  const [css] = useStyletron();
  const navigate = useNavigate();
  const [branches, setBranches] = useState<TreeNodeData<Journey>[]>([]);
  const [selectedJourney, setSelectedJourney] = useState<number | null>(null);

  const fetchJourneys = useCallback(async () => {
    const response = await apiClient.get("journeys");
    const data = await response.data;

    // const journeysMap = data.map((journey: Journey) => ({
    //   id: journey.id,
    //   label: `${journey.startTime}`,
    //   isExpanded: false,
    //   children: []
    // }));

    // const groupedJourneys = journeysMap.reduce((acc: any, journey: any) => {
    //   const date = DateTime.fromISO(journey.label).toFormat("dd/MM/yyyy");
    //   if (!acc[date]) {
    //     acc[date] = {
    //       id: date,
    //       label: DateTime.fromFormat(date, "dd/MM/yyyy").toRelative(),
    //       children: []
    //     };
    //   }
    //   acc[date].children.push(journey);
    //   return acc;
    // }, {});
    console.log("data fetched", data.length);
    if (data) {
      const journeysMap = data.map((journey: Journey) => ({
        id: journey.id,
        label: `${journey.startTime}`,
        isExpanded: false,
        info: { drive: true, journey }
      }));
      const groupedJourneys = journeysMap.reduce((acc: any, journey: any) => {
        const date = DateTime.fromISO(journey.label).toFormat("dd/MM/yyyy");
        if (!acc[date]) {
          acc[date] = {
            id: date,
            label: DateTime.fromFormat(date, "dd/MM/yyyy").toRelative(),
            isExpanded: false,
            children: []
          };
        }
        journey.label = `${["usr-3008606942561864928", "usr-3672506391550766358"].includes(journey.info.journey?.user) ? "🤠" : "🧑‍✈️"} ${DateTime.fromISO(journey.label).toFormat("HH:mm")}`;
        acc[date].children.push(journey);
        return acc;
      }, {});

      setBranches(Object.values(groupedJourneys));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchJourneys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={css({
        height: "100vh",
        display: "flex",
        margin: "auto"
      })}
    >
      <div className={css({ width: "20vw" })}>
        <TreeView
          data={branches}
          onToggle={async (node) => {
            setBranches((prevData) => toggleIsExpanded(prevData, node));
            if (node.info?.drive) {
              setSelectedJourney(parseInt(node.id as string, 10));
              navigate(`/journeys/watch/${node.id}`);
            }
          }}
        />
      </div>
      {selectedJourney !== null ? (
        <Outlet context={selectedJourney} />
      ) : (
        <SelectAJourney />
      )}
    </div>
  );
}
