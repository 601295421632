import addSrc from '@corbado/shared-ui/assets/add.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const AddIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, addSrc, '--cb-button-text-primary-color');
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'add', ...props }));
});
