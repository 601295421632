import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, InputField, PrimaryButton, SecondaryButton } from '../../../components';
export const EditEmail = ({ block }) => {
    const { t } = useTranslation('translation', { keyPrefix: `${block.authType}.email-verify.edit-email` });
    const [email, setEmail] = useState(block.data.email);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const emailInputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = emailInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const headerText = useMemo(() => t('header'), [t]);
    const primaryButtonText = useMemo(() => t(`button_submit.${block.data.verificationMethod === 'email-otp' ? 'emailOtp' : 'emailLink'}`), [t]);
    const secondaryButtonText = useMemo(() => t('button_cancel'), [t]);
    const handleConfirm = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        const error = await block.updateEmail(email);
        if (error) {
            setErrorMessage(error);
            setLoading(false);
            return;
        }
    }, [block, email]);
    return (React.createElement("form", { className: 'cb-edit-data-section', onSubmit: e => void handleConfirm(e) },
        React.createElement(Header, { size: 'md', className: 'cb-edit-data-section-header' }, headerText),
        React.createElement(InputField, { value: email, errorMessage: errorMessage, ref: emailInputRef, onChange: e => setEmail(e.target.value) }),
        React.createElement(PrimaryButton, { type: 'submit', isLoading: loading, onClick: e => {
                const noChange = email === block.data.email;
                if (noChange) {
                    block.showEmailVerificationScreen();
                    return;
                }
                void handleConfirm(e);
            } }, primaryButtonText),
        React.createElement(SecondaryButton, { className: 'cb-edit-data-section-back-button', onClick: () => block.showEmailVerificationScreen() }, secondaryButtonText)));
};
