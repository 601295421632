import React from 'react';
import { PrimaryButton } from './buttons/PrimaryButton';
import { SecondaryButton } from './buttons/SecondaryButton';
import { CancelIcon } from './icons/CancelIcon';
import { Header } from './typography/Header';
import { Text } from './typography/Text';
export const Dialog = ({ isOpen, header, body, confirmText = 'Yes', cancelText, onClose, onConfirm, }) => {
    const [loadingCloseAction, setLoadingCloseAction] = React.useState(false);
    const [loadingConfirmAction, setLoadingConfirmAction] = React.useState(false);
    if (!isOpen) {
        return null;
    }
    const closeAction = async () => {
        if (onClose) {
            setLoadingCloseAction(true);
            await onClose();
            setLoadingCloseAction(false);
        }
    };
    const confirmAction = async () => {
        if (onConfirm) {
            setLoadingConfirmAction(true);
            await onConfirm();
            setLoadingConfirmAction(false);
        }
    };
    return (React.createElement("div", { className: 'cb-dialog', onClick: () => void closeAction() },
        React.createElement("div", { className: 'cb-dialog-content', onClick: e => e.stopPropagation() },
            React.createElement("div", { className: 'cb-dialog-header' },
                React.createElement(Header, { className: 'cb-dialog-header-text' }, header),
                React.createElement(CancelIcon, { className: 'cb-dialog-x-button', onClick: () => void closeAction() })),
            body ? (React.createElement("div", { className: 'cb-dialog-body' },
                React.createElement(Text, { level: '3', fontFamilyVariant: 'secondary' }, body))) : null,
            React.createElement("div", { className: 'cb-dialog-footer' },
                React.createElement(PrimaryButton, { className: 'cb-dialog-button', colorVariant: 'error', isLoading: loadingConfirmAction, disabled: loadingCloseAction, onClick: () => void confirmAction() }, confirmText),
                cancelText ? (React.createElement("span", { className: 'cb-dialog-button' },
                    React.createElement(SecondaryButton, { disabled: loadingCloseAction, onClick: () => void closeAction() }, cancelText))) : null))));
};
