// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./typography.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./common.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./buttons.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./inputs.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./user.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./corbado-auth.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./passkey-list.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./themes/emerald-funk.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Note: The order of the imports is important. CSS specificity is based on the order of the rules in the CSS file. The last rule defined will take precedence over the previous ones. So, if you want to override a rule, make sure to import the overriding CSS file after the original one. */
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAGA,gSAAgS","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n\n/* Note: The order of the imports is important. CSS specificity is based on the order of the rules in the CSS file. The last rule defined will take precedence over the previous ones. So, if you want to override a rule, make sure to import the overriding CSS file after the original one. */\n@import './variables.css';\n@import './typography.css';\n@import './common.css';\n@import './buttons.css';\n@import './inputs.css';\n@import './user.css';\n@import './corbado-auth.css';\n@import './passkey-list.css';\n@import './themes/emerald-funk.css';\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
