import { faCar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StyledAppName } from "baseui/app-nav-bar";
import { StyledLink } from "baseui/link";

export function SiteTitle() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FontAwesomeIcon icon={faCar} />
      <div style={{ marginLeft: "10px" }} />
      <StyledLink href="/" animateUnderline>
        <StyledAppName>Steal My Car</StyledAppName>
      </StyledLink>
    </div>
  );
}
