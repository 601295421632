import { CorbadoError } from '@corbado/web-core';
import { parsePhoneNumber } from 'libphonenumber-js';
export class Block {
    constructor(app, flowHandler, common, errorTranslator) {
        this.alternatives = [];
        this.flowHandler = flowHandler;
        this.app = app;
        this.common = common;
        this.errorTranslator = errorTranslator;
    }
    updateScreen(newScreen) {
        this.flowHandler.updateScreen(newScreen);
        return;
    }
    updateProcess(processUpdateRes) {
        if (processUpdateRes.err && processUpdateRes.val.ignore) {
            return;
        }
        if (processUpdateRes.err) {
            this.flowHandler.handleError(processUpdateRes.val);
            return;
        }
        this.flowHandler.handleProcessUpdateBackend(processUpdateRes.val);
        return;
    }
    updateProcessFrontend(newPrimaryBlock, newAlternatives = []) {
        this.flowHandler.handleProcessUpdateFrontend(newPrimaryBlock, newAlternatives);
        return;
    }
    static getFormattedPhoneNumber(raw) {
        // for obscured phone numbers, parsePhoneNumber will throw an error
        try {
            const parsedPhoneNumber = parsePhoneNumber(raw);
            if (parsedPhoneNumber) {
                return parsedPhoneNumber.formatInternational();
            }
            return raw;
        }
        catch (e) {
            return raw;
        }
    }
    setAlternatives(alternatives) {
        this.alternatives = alternatives;
    }
    setError(error) {
        const corbadoError = new CorbadoError(true, false);
        const maybeTranslation = this.errorTranslator.translate(error);
        if (maybeTranslation) {
            corbadoError.translatedMessage = maybeTranslation;
        }
        else {
            corbadoError.message = error.message;
        }
        this.error = corbadoError;
    }
    init() {
        return;
    }
    async confirmAbort() {
        const newBlock = await this.app.authProcessService.resetAuthProcess();
        this.updateProcess(newBlock);
        return;
    }
    cancelAbort(originalBlock) {
        this.updateProcessFrontend(originalBlock, originalBlock.alternatives);
        return;
    }
}
