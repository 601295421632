import { getPlatformType } from '@corbado/shared-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, PrimaryButton, SecondaryButton, Text, UserInfo } from '../../../components';
import { FaceIdIcon } from '../../../components/ui/icons/FaceIdIcon';
import { FingerPrintIcon } from '../../../components/ui/icons/FingerPrintIcon';
import { PersonIcon } from '../../../components/ui/icons/PersonIcon';
export const PasskeyErrorLight = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `login.passkey-verify.passkey-error-light`,
    });
    const [loading, setLoading] = useState(false);
    const [changingBlock, setChangingBlock] = useState(false);
    const [userInfo, setUserInfo] = useState(block.data.identifierValue);
    const platform = useMemo(() => getPlatformType(), []);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyTitleText = useMemo(() => t('body_title'), [t]);
    const bodyDescriptionText = useMemo(() => t('body_description'), [t]);
    const dividerText = useMemo(() => t('text_divider'), [t]);
    const tryAgainButtonText = useMemo(() => t('button_tryAgain'), [t]);
    const tryAgainSubText = useMemo(() => t('subtext_tryAgain'), [t]);
    const fallbacksAvailable = block.data.availableFallbacks.length > 0;
    const passkeyLogin = useCallback(async () => {
        setLoading(true);
        await block.passkeyLogin();
        setLoading(false);
    }, [block]);
    useEffect(() => {
        return () => {
            block.cancelPasskeyOperation();
        };
    }, []);
    useEffect(() => {
        setUserInfo(block.getFormattedPhoneNumber());
    }, [block]);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void passkeyLogin();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [passkeyLogin]);
    async function userInfoChange() {
        setLoading(true);
        await block.confirmAbort();
        setLoading(false);
    }
    return (React.createElement("div", { className: 'cb-pk-error' },
        React.createElement(Header, { className: 'cb-pk-error-light-header' }, headerText),
        React.createElement("div", { className: 'cb-pk-error-bloc-icons-section' },
            React.createElement(FingerPrintIcon, { className: 'cb-pk-error-bloc-icons-section-icon', platform: platform }),
            React.createElement(FaceIdIcon, { className: 'cb-pk-error-bloc-icons-section-icon', platform: platform })),
        React.createElement("div", { className: 'cb-pk-error-user-info-edit-section' },
            React.createElement(Text, { level: '2', fontWeight: 'bold', className: 'cb-pk-error-user-info-edit-section-title' }, bodyTitleText),
            React.createElement(UserInfo, { userData: userInfo, leftIcon: React.createElement(PersonIcon, { className: 'cb-user-info-section-left-icon' }), onRightIconClick: () => void userInfoChange() })),
        React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', className: 'cb-pk-error-light-description' }, bodyDescriptionText),
        React.createElement(PrimaryButton, { onClick: () => void passkeyLogin(), isLoading: loading, disabled: changingBlock }, tryAgainButtonText),
        React.createElement(Text, { fontFamilyVariant: 'secondary' }, tryAgainSubText),
        fallbacksAvailable && (React.createElement(Divider, { label: dividerText, className: 'cb-pk-error-light-divider' })),
        block.data.availableFallbacks.map(fallback => (React.createElement(SecondaryButton, { key: fallback.label, disabled: changingBlock, onClick: () => {
                setChangingBlock(true);
                return void fallback.action();
            } }, t(fallback.label))))));
};
