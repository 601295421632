import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, PrimaryButton, SecondaryButton, Text } from '../../../components';
import PasskeyHybridIcon from '../../../components/ui/icons/PasskeyHybridIcon';
export const PasskeyHybrid = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `login.passkey-verify.passkey-hybrid`,
    });
    const [loading, setLoading] = useState(false);
    const [changingBlock, setChangingBlock] = useState(false);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyPromptText = useMemo(() => t('body_prompt'), [t]);
    const bodyPasskeyVerificationMethod1Text = useMemo(() => t('body_passkeyVerificationMethod1'), [t]);
    const bodyPasskeyVerificationMethod2Text = useMemo(() => t('body_passkeyVerificationMethod2'), [t]);
    const dividerText = useMemo(() => t('text_divider'), [t]);
    const loginButtonText = useMemo(() => t('button_login'), [t]);
    const fallbacksAvailable = block.data.availableFallbacks.length > 0;
    const passkeyLogin = useCallback(async () => {
        setLoading(true);
        await block.passkeyLogin();
        setLoading(false);
    }, [block]);
    useEffect(() => {
        return () => {
            block.cancelPasskeyOperation();
        };
    }, []);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void passkeyLogin();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [passkeyLogin]);
    return (React.createElement("div", { className: 'cb-pk-hybrid' },
        React.createElement(Header, null, headerText),
        React.createElement(Text, { level: '2', className: 'cb-pk-hybrid-prompt' }, bodyPromptText),
        React.createElement(PasskeyHybridIcon, { className: 'cb-pk-hybrid-icon' }),
        React.createElement("div", { className: 'cb-pk-hybrid-verification-methods-section' },
            React.createElement(Text, { level: '2' }, bodyPasskeyVerificationMethod1Text),
            React.createElement(Text, { level: '2' }, bodyPasskeyVerificationMethod2Text)),
        React.createElement(PrimaryButton, { onClick: () => void passkeyLogin(), isLoading: loading, disabled: changingBlock }, loginButtonText),
        fallbacksAvailable && (React.createElement(Divider, { label: dividerText, className: 'cb-pk-hybrid-divider' })),
        block.data.availableFallbacks.map(fallback => (React.createElement(SecondaryButton, { key: fallback.label, disabled: changingBlock, onClick: () => {
                setChangingBlock(true);
                return void fallback.action();
            } }, t(fallback.label))))));
};
