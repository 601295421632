import { useEffect, useState } from "react";

import {
  faCarBurst,
  faCircleCheck,
  faFlagCheckered,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyletron } from "baseui";
import { Button, SHAPE, SIZE } from "baseui/button";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { Pagination } from "baseui/pagination";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Segment, SegmentedControl } from "baseui/segmented-control";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { DateTime } from "luxon";

import { MapImage } from "@smc/components";
import { useAxios, usePermissions } from "@smc/hooks";
import { Drive, DriveType } from "@smc/types/drive";

export default function EditJourneys() {
  const [css] = useStyletron();
  const { apiClient } = useAxios();
  const { isAdmin } = usePermissions();

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<number | null>(null);
  const [limit, setLimit] = useState(10);
  const [rows, setRows] = useState<Drive[]>([
    {
      id: 0,
      latitude: "0",
      longitude: "0",
      timestamp: DateTime.local().toISO(),
      driveType: DriveType.Start
    }
  ]);

  useEffect(() => {
    isAdmin();
    fetchMeta().then(setCount);
    fetchDrives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit]);

  const fetchDrives = async () => {
    const response = await apiClient.get(
      `drives?limit=${limit}&page=${currentPage}`
    );

    setRows(response.data);
    setLoading(false);
  };

  const fetchMeta = async () => {
    const response = await apiClient.get("drives/meta");

    const { count } = response.data;
    return count;
  };

  function handleDelete() {
    setDeleteLoading(selectedRow);
    apiClient.delete(`drives/${selectedRow}`).then(() => {
      setDeleteLoading(null);
      setSelectedRow(null);
      fetchDrives();
      fetchMeta().then(setCount);
    });
  }

  return (
    <>
      <div
        className={css({
          textAlign: "center",
          margin: "auto",
          width: "95vw"
        })}
      >
        <TableBuilder
          data={rows}
          isLoading={loading}
          loadingMessage="Loading..."
          divider="horizontal"
          overrides={{
            TableHeadCell: {
              style: {
                textAlign: "center"
              }
            },
            TableBodyCell: {
              style: {
                textAlign: "center"
              }
            }
          }}
        >
          <TableBuilderColumn header={<FontAwesomeIcon icon={faCircleCheck} />}>
            {(row: Drive) => (
              <Checkbox
                onChange={(e) => setSelectedRow(row.id)}
                checkmarkType={STYLE_TYPE.toggle_round}
                checked={row.id === selectedRow}
              />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header={<FontAwesomeIcon icon={faTrashCan} />}>
            {(row: Drive) => (
              <Button
                size={SIZE.compact}
                shape={SHAPE.pill}
                disabled={row.id !== selectedRow}
                isLoading={row.id === deleteLoading}
                onClick={handleDelete}
                overrides={
                  row.id !== selectedRow
                    ? {}
                    : {
                        BaseButton: {
                          style: ({ $theme }) => ({
                            outline: `${$theme.colors.warning} solid`,
                            backgroundColor: $theme.colors.warning
                          })
                        }
                      }
                }
              >
                Delete
              </Button>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header="Time" key="date">
            {(row: Drive) => (
              <StatefulPopover
                content={() =>
                  DateTime.fromISO(row.timestamp)
                    .setLocale("gb")
                    .toLocaleString(DateTime.DATETIME_MED)
                }
                animateOutTime={100}
                placement={PLACEMENT.leftTop}
                triggerType="hover"
              >
                {DateTime.fromISO(row.timestamp).toRelative()}
              </StatefulPopover>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header="Location" numeric>
            {(row: Drive) =>
              row.latitude.substring(0, 6) + "," + row.longitude.substring(0, 7)
            }
          </TableBuilderColumn>
          <TableBuilderColumn header="Type" numeric>
            {(row: Drive) =>
              row.driveType === DriveType.Start ? (
                <FontAwesomeIcon icon={faCarBurst} />
              ) : (
                <FontAwesomeIcon icon={faFlagCheckered} />
              )
            }
          </TableBuilderColumn>
          <TableBuilderColumn header="Map">
            {(row: Drive) => <MapImage drive={row} />}
          </TableBuilderColumn>
        </TableBuilder>
        <div className={css({ height: "10px" })} />
        <div
          className={css({
            display: "flex",
            height: "50px",
            margin: "auto",
            justifyContent: "space-around",
            width: "80%",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
            flexWrap: "wrap"
          })}
        >
          <div>
            <Pagination
              numPages={Math.ceil(count / limit)}
              currentPage={currentPage}
              onPageChange={({ nextPage }) => {
                setCurrentPage(nextPage);
              }}
            />
          </div>
          <div>
            <SegmentedControl
              activeKey={limit.toString()}
              onChange={({ activeKey }) => {
                setLimit(activeKey as number);
                setLoading(true);
                setCurrentPage(1);
              }}
            >
              {[5, 10, 20, 50, 100].map((limit) => (
                <Segment key={limit} label={limit.toString()} />
              ))}
            </SegmentedControl>
          </div>
        </div>
      </div>
      <div className={css({ height: "20px" })} />
    </>
  );
}
