import { useStyletron } from "baseui";
import { Card } from "baseui/card";

export default function SelectAJourney() {
  const [css, theme] = useStyletron();
  console.log(theme.colors);
  return (
    <div
      className={css({
        textAlign: "center",
        justifyContent: "center",
        justifyItems: "center",
        width: "20%",
        paddingLeft: "10rem"
      })}
    >
      <Card
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              outline: `${$theme.colors.accent400} solid`,
              backgroundColor: $theme.colors.accent100,
              color: $theme.colors.accent600
            })
          },
          Body: {
            style: ({ $theme }) => ({
              color: $theme.colors.gray600
            })
          }
        }}
      >
        Select a Journey
      </Card>
    </div>
  );
}
