import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, PasskeyCreate, PasskeyListErrorBoundary, PasskeyListItem, Text } from '../../components';
import { useCorbado } from '../../hooks/useCorbado';
const PasskeyList = () => {
    var _a;
    const { getPasskeys, globalError, isAuthenticated } = useCorbado();
    const { t } = useTranslation('translation', { keyPrefix: 'passkey-list' });
    const [passkeys, setPasskeys] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        const abortController = new AbortController();
        void fetchPasskeys(abortController);
        return () => {
            abortController.abort();
        };
    }, [isAuthenticated]);
    const fetchPasskeys = useCallback(async (abortController) => {
        setLoading(true);
        const result = await getPasskeys(abortController);
        if (result.err && result.val.ignore) {
            return;
        }
        if (result.err) {
            throw new Error(result.val.name);
        }
        setPasskeys(result.val);
        setLoading(false);
    }, [getPasskeys]);
    if (!isAuthenticated) {
        return React.createElement("div", null, t('warning_notLoggedIn'));
    }
    if (loading) {
        return React.createElement(LoadingSpinner, null);
    }
    return (React.createElement(PasskeyListErrorBoundary, { globalError: globalError },
        React.createElement("div", { className: 'cb-passkey-list-container' },
            React.createElement("div", { className: 'cb-passkey-list-title' },
                React.createElement(Text, { className: 'cb-passkey-list-title' }, t('title'))), (_a = passkeys === null || passkeys === void 0 ? void 0 : passkeys.passkeys.map(passkey => (React.createElement(PasskeyListItem, { key: passkey.id, passkey: passkey, fetchPasskeys: fetchPasskeys })))) !== null && _a !== void 0 ? _a : React.createElement("div", null, t('message_noPasskeys')),
            React.createElement(PasskeyCreate, { fetchPasskeys: fetchPasskeys }))));
};
export default PasskeyList;
