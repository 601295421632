var _WebAuthnService_abortController;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { create, get } from '@github/webauthn-json';
import log from 'loglevel';
import { Err, Ok } from 'ts-results';
import { checkIfOnlyHybridPasskeysAvailable, CorbadoError } from '../utils';
const clientHandleKey = 'cbo_client_handle';
/**
 * AuthenticatorService handles all interactions with webAuthn platform authenticators.
 * Currently, this includes the creation of passkeys and the login with existing passkeys.
 */
export class WebAuthnService {
    constructor() {
        _WebAuthnService_abortController.set(this, void 0);
    }
    async createPasskey(serializedChallenge) {
        try {
            const abortController = this.abortOngoingOperation();
            const challenge = JSON.parse(serializedChallenge);
            challenge.signal = abortController.signal;
            __classPrivateFieldSet(this, _WebAuthnService_abortController, abortController, "f");
            const signedChallenge = await create(challenge);
            const serializedResponse = JSON.stringify(signedChallenge);
            return Ok(serializedResponse);
        }
        catch (e) {
            if (e instanceof DOMException) {
                return Err(CorbadoError.fromDOMException(e));
            }
            else {
                return Err(CorbadoError.fromUnknownFrontendError(e));
            }
        }
    }
    async login(serializedChallenge, conditional, skipIfOnlyHybrid = false) {
        try {
            const abortController = this.abortOngoingOperation();
            const challenge = JSON.parse(serializedChallenge);
            if (skipIfOnlyHybrid) {
                const hasOnlyHybridPasskeys = checkIfOnlyHybridPasskeysAvailable(challenge);
                if (hasOnlyHybridPasskeys) {
                    return Err(CorbadoError.onlyHybridPasskeyAvailable());
                }
            }
            challenge.signal = abortController.signal;
            __classPrivateFieldSet(this, _WebAuthnService_abortController, abortController, "f");
            if (conditional) {
                challenge.mediation = 'conditional';
            }
            const signedChallenge = await get(challenge);
            const serializedResponse = JSON.stringify(signedChallenge);
            return Ok(serializedResponse);
        }
        catch (e) {
            if (e instanceof DOMException) {
                return Err(CorbadoError.fromDOMException(e));
            }
            else {
                return Err(CorbadoError.fromUnknownFrontendError(e));
            }
        }
    }
    static async doesBrowserSupportPasskeys() {
        return (window.PublicKeyCredential && (await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()));
    }
    static async doesBrowserSupportConditionalUI() {
        return window.PublicKeyCredential && (await window.PublicKeyCredential.isConditionalMediationAvailable());
    }
    static async canUseBluetooth() {
        try {
            const availability = await navigator.bluetooth.getAvailability();
            if (availability) {
                return true;
            }
            return false;
        }
        catch (e) {
            log.debug('Error checking bluetooth availability', e);
            return false;
        }
    }
    static getClientHandle() {
        return localStorage.getItem(clientHandleKey);
    }
    static async getHighEntropyValues() {
        try {
            if (!navigator.userAgentData) {
                return;
            }
            const ua = await navigator.userAgentData.getHighEntropyValues(['platformVersion']);
            const platform = ua.platform;
            const mobile = ua.mobile;
            const platformVersion = ua.platformVersion;
            if (!platform || mobile === undefined || !platformVersion) {
                return;
            }
            return {
                platform,
                mobile,
                platformVersion,
            };
        }
        catch (e) {
            return;
        }
    }
    static setClientHandle(clientHandle) {
        localStorage.setItem(clientHandleKey, clientHandle);
    }
    abortOngoingOperation() {
        if (__classPrivateFieldGet(this, _WebAuthnService_abortController, "f")) {
            __classPrivateFieldGet(this, _WebAuthnService_abortController, "f").abort();
        }
        return new AbortController();
    }
}
_WebAuthnService_abortController = new WeakMap();
