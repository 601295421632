import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class CompletedBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.Completed;
        this.initialScreen = ScreenNames.End;
        this.authType = blockBody.authType;
        this.data = blockBody.data;
    }
}
