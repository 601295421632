import syncIconSrc from '@corbado/shared-ui/assets/sync.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const SyncIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, syncIconSrc, '--cb-passkey-list-badge-color');
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'sync-icon', ...props }));
});
