import { Outlet } from "react-router-dom";

import { useStyletron } from "baseui";

import { Title } from "@smc/components";

export default function JourneysContainer() {
  const [css] = useStyletron();

  return (
    <div className={css({ textAlign: "center", zIndex: 3 })}>
      <Title title="Journeys" subtitle="Where you been, where you going?" />
      <Outlet />
    </div>
  );
}
