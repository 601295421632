import React from 'react';
export class PasskeyListErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: undefined };
    }
    static getDerivedStateFromError(error) {
        console.error(error);
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }
    render() {
        if (this.props.globalError) {
            return (React.createElement("div", { className: 'cb-error-page' },
                React.createElement("div", null, "Something went wrong. Please try again in a few moments.")));
        }
        return this.props.children;
    }
}
