import { UAParser } from 'ua-parser-js';
export var PlatformType;
(function (PlatformType) {
    PlatformType["Apple"] = "apple";
    PlatformType["Android"] = "android";
    PlatformType["Windows"] = "windows";
})(PlatformType || (PlatformType = {}));
export function getUserAgent() {
    const { browser, os, device } = UAParser(navigator.userAgent);
    return { browser, os, device };
}
export function isUserAgentWindows(userAgentOS) {
    //Windows 10 and 11 are the only versions that support WebAuthn
    return ((userAgentOS.name === 'Windows' && userAgentOS.version && /^1[01]$/.test(userAgentOS.version)) ||
        userAgentOS.name === 'Windows Phone');
}
export function isUserAgentApple(userAgentOS) {
    return userAgentOS.name === 'iOS' || userAgentOS.name === 'Mac OS';
}
export function isUserAgentAndroid(userAgentOS) {
    return userAgentOS.name === 'Android';
}
export function isUserAgentMobile(userAgentDevice) {
    return userAgentDevice.type === 'mobile';
}
export function getPlatformType() {
    const ua = getUserAgent();
    if (isUserAgentApple(ua.os)) {
        return PlatformType.Apple;
    }
    if (isUserAgentAndroid(ua.os)) {
        return PlatformType.Android;
    }
    if (isUserAgentWindows(ua.os)) {
        return PlatformType.Windows;
    }
    return undefined;
}
