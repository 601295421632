import { PlatformType } from '@corbado/shared-ui';
import faceIdAndroid from '@corbado/shared-ui/assets/face-id-android.svg';
import faceIdApple from '@corbado/shared-ui/assets/face-id-apple.svg';
import faceIdDefault from '@corbado/shared-ui/assets/face-id-default.svg';
import faceIdWindows from '@corbado/shared-ui/assets/face-id-windows.svg';
import React, { memo, useRef } from 'react';
import { ColorType, useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
const getPlatformIcon = (platform) => {
    switch (platform) {
        case PlatformType.Apple:
            return faceIdApple;
        case PlatformType.Android:
            return faceIdAndroid;
        case PlatformType.Windows:
            return faceIdWindows;
        default:
            return faceIdDefault;
    }
};
export const FaceIdIcon = memo(({ platform, ...props }) => {
    const svgRef = useRef(null);
    const faceIdSrc = getPlatformIcon(platform);
    const { logoSVG } = useIconWithTheme(svgRef, faceIdSrc, '--cb-text-primary-color', ColorType.FillAndStroke);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'face-id-icon', ...props }));
});
