import { getPlatformType } from '@corbado/shared-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import { Divider } from '../../../components/ui/Divider';
import { DeviceIcon } from '../../../components/ui/icons/DeviceIcon';
import { EditIcon } from '../../../components/ui/icons/EditIcon';
import { FaceIdIcon } from '../../../components/ui/icons/FaceIdIcon';
import { FingerPrintIcon } from '../../../components/ui/icons/FingerPrintIcon';
import { SecureIcon } from '../../../components/ui/icons/SecureIcon';
import { Text } from '../../../components/ui/typography/Text';
export const PasskeyAppend = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `signup.passkey-append.passkey-append`,
    });
    const [passkeyUserHandle, setPasskeyUserHandle] = useState(block.data.userHandle);
    const [loading, setLoading] = useState(false);
    const [changingBlock, setChangingBlock] = useState(false);
    const platform = useMemo(() => getPlatformType(), []);
    const headerText = useMemo(() => t(`header.${block.data.canBeSkipped ? 'append' : 'create'}`), [t]);
    const bodyPoint1Text = useMemo(() => {
        if (!platform) {
            return t('body_point1');
        }
        return t(`platformBasedText.${platform}.body_point1`);
    }, [t]);
    const bodyPoint2Text = useMemo(() => {
        if (!platform) {
            return t('body_point2');
        }
        return t(`platformBasedText.${platform}.body_point2`);
    }, [t]);
    const bodySubtext = useMemo(() => t('body_subtext'), [t]);
    const textDivider = useMemo(() => t('text_divider'), [t]);
    const primaryButtonText = useMemo(() => t(`button_start.${block.data.canBeSkipped ? 'append' : 'create'}`), [t]);
    const skipButtonText = useMemo(() => t('button_skip'), [t]);
    const appendPasskey = useCallback(() => {
        setLoading(true);
        void block.passkeyAppend();
    }, [block]);
    useEffect(() => {
        if (block.data.userHandleType !== 'phone') {
            setPasskeyUserHandle(block.data.userHandle);
        }
        else {
            setPasskeyUserHandle(block.getFormattedPhoneNumber());
        }
    }, [block]);
    useEffect(() => {
        // we run auto submit with a slight delay to avoid issues with double execution (React mounts components twice in development mode)
        const t = setTimeout(() => {
            if (block.data.autoSubmit) {
                appendPasskey();
            }
        }, 50);
        return () => {
            clearTimeout(t);
            block.cancelPasskeyOperation();
        };
    }, [block]);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                appendPasskey();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [appendPasskey]);
    const fallbacksAvailable = block.data.availableFallbacks.length > 0;
    return (React.createElement("div", { className: 'cb-pk-append' },
        React.createElement(Text, { level: '6', fontWeight: 'bold', className: 'cb-pk-append-header', textColorVariant: 'header' }, headerText),
        React.createElement("span", { className: 'cb-pk-append-icons-section' },
            React.createElement(FingerPrintIcon, { className: 'cb-pk-append-icons-section-icon', platform: platform }),
            React.createElement(FaceIdIcon, { className: 'cb-pk-append-icons-section-icon', platform: platform })),
        React.createElement("div", { className: 'cb-pk-append-user-info-section' },
            React.createElement(Text, { textColorVariant: 'script', fontFamilyVariant: 'primary' }, passkeyUserHandle !== null && passkeyUserHandle !== void 0 ? passkeyUserHandle : ''),
            !block.data.canBeSkipped && (React.createElement(EditIcon, { className: 'cb-pk-append-user-info-section-edit-icon', color: 'primary', onClick: () => block.showEditUserData() }))),
        React.createElement("div", { className: 'cb-pk-append-points-section' },
            React.createElement("div", { className: 'cb-pk-append-points-section-point' },
                React.createElement(SecureIcon, { className: 'cb-pk-append-points-section-point-icon' }),
                React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, bodyPoint1Text)),
            React.createElement("div", { className: 'cb-pk-append-points-section-point' },
                React.createElement(DeviceIcon, { className: 'cb-pk-append-points-section-point-icon' }),
                React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, bodyPoint2Text))),
        React.createElement(PrimaryButton, { isLoading: loading, disabled: changingBlock, onClick: appendPasskey }, primaryButtonText),
        React.createElement("div", { className: 'cb-pk-append-subtext' },
            React.createElement(Text, { fontFamilyVariant: 'secondary' }, bodySubtext)),
        fallbacksAvailable && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { label: textDivider }),
            React.createElement("div", { className: 'cb-pk-append-buttons-section' }, block.data.availableFallbacks.map(fallback => (React.createElement(SecondaryButton, { key: fallback.label, onClick: () => {
                    setChangingBlock(true);
                    return void fallback.action();
                }, disabled: changingBlock }, t(fallback.label))))))),
        block.data.canBeSkipped && (React.createElement("div", { className: 'cb-pk-append-skip-button-section' },
            React.createElement(SecondaryButton, { onClick: () => void block.skipPasskeyAppend(), disabled: loading }, skipButtonText)))));
};
