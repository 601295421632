import { Component } from "react";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import { Pagan } from "../types/pagan";
import { IPaganMapState } from "../types/state/pagan-map-state";
import { LoadingScreen } from "./LoadingScreen";

export class PaganMap extends Component<any, IPaganMapState> {
  constructor() {
    super({});
    this.state = {
      loading: true,
      pagans: []
    };
  }

  componentDidMount() {
    this.getPaganData();
  }

  async getPaganData() {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/pagans`
    );

    const data: Pagan[] = await response.json();
    this.setState({ pagans: data, loading: false });
  }

  static renderMap(pagans: Pagan[]) {
    return (
      <MapContainer
        center={[51.2127084, 0.2018097]}
        zoom={5}
        scrollWheelZoom={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {pagans.map((pagan) => (
          <Marker
            position={[+pagan.latitude, +pagan.longitude]}
            key={pagan.rowKey}
          >
            <Popup>
              <div className="pagan-popup">
                <h3>{pagan.org}</h3>
                <p>
                  {pagan.timestamp}
                  <br></br>
                  {pagan.passCode} | {pagan.ipAddress}
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <LoadingScreen />
    ) : (
      PaganMap.renderMap(this.state.pagans)
    );
    return <div className="map-wrapper">{contents}</div>;
  }
}
