import React, { forwardRef } from 'react';
import { SecondaryButton } from '../buttons/SecondaryButton';
import ErrorMessage from '../errors/ErrorMessage';
import { Text } from '../typography/Text';
import { Input } from './Input';
export const InputField = forwardRef(({ label, labelLink, type = 'text', id, errorMessage, className, ...props }, ref) => (React.createElement("div", { className: `cb-input-field${className ? ` ${className}` : ''}` },
    label && (React.createElement("label", { htmlFor: id, className: 'cb-input-label' },
        React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', className: 'cb-input-label-text', textColorVariant: 'script' }, label),
        labelLink && (React.createElement("span", { className: 'cb-input-label-link' },
            React.createElement(SecondaryButton, { colorVariant: 'link', onClick: labelLink.onClick }, labelLink.text))))),
    React.createElement(Input, { ...props, type: type, id: id, "aria-label": label, ref: ref, hasError: !!errorMessage }),
    errorMessage && React.createElement(ErrorMessage, { message: errorMessage }))));
export default InputField;
