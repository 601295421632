var _ErrorTranslator_i18next;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
export class ErrorTranslator {
    constructor(i18next) {
        _ErrorTranslator_i18next.set(this, void 0);
        __classPrivateFieldSet(this, _ErrorTranslator_i18next, i18next, "f");
    }
    translate(error) {
        if (!error || !error.code) {
            return undefined;
        }
        return __classPrivateFieldGet(this, _ErrorTranslator_i18next, "f").t(`errors.${error.code}`);
    }
    translateWithIdentifier(error, type) {
        if (!error || !error.code) {
            return undefined;
        }
        return __classPrivateFieldGet(this, _ErrorTranslator_i18next, "f").t(`errors.${error.code}.${type}`);
    }
}
_ErrorTranslator_i18next = new WeakMap();
