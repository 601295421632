import { Panel } from "baseui/accordion";

import { PanelTitle } from "./PanelTitle";

export function VitalPanel(props: any) {
  return (
    <Panel
      {...props}
      title={<PanelTitle title={props.title} icon={props.icon} />}
      overrides={{
        ToggleIcon: {
          style: ({ $expanded }: { $expanded: boolean }) => ({
            transform: $expanded ? "rotate(270deg)" : "rotate(0deg)",
            transition: "transform 0.3s"
          })
        }
      }}
    />
  );
}
