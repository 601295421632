var _PasskeyAppendAfterHybridBlock_passkeyAborted;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class PasskeyAppendAfterHybridBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.PasskeyAppendAfterHybrid;
        this.initialScreen = ScreenNames.PasskeyAppendAfterHybrid;
        _PasskeyAppendAfterHybridBlock_passkeyAborted.set(this, false);
        this.authType = blockBody.authType;
        const data = blockBody.data;
        this.data = {
            passkeyIconSet: data.passkeyIconSet,
        };
    }
    // cancels the current passkey operation (if one has been started)
    // this should be called if a user leaves the passkey verify block without completing the passkey operation
    // (otherwise the operation will continue in the background and a passkey popup might occur much later when the user no longer expects it)
    cancelPasskeyOperation() {
        __classPrivateFieldSet(this, _PasskeyAppendAfterHybridBlock_passkeyAborted, true, "f");
        return this.app.authProcessService.dispose();
    }
    async passkeyAppend() {
        __classPrivateFieldSet(this, _PasskeyAppendAfterHybridBlock_passkeyAborted, false, "f");
        const res = await this.app.authProcessService.appendPasskey();
        if (res.err) {
            // This check is necessary because the user might have navigated away from the passkey block before the operation was completed
            if (!__classPrivateFieldGet(this, _PasskeyAppendAfterHybridBlock_passkeyAborted, "f")) {
                const newBlock = await this.app.authProcessService.skipBlock();
                this.updateProcess(newBlock);
            }
            return;
        }
        return this.updateProcess(res);
    }
    async skipPasskeyAppend() {
        this.cancelPasskeyOperation();
        const newBlock = await this.app.authProcessService.skipBlock();
        this.updateProcess(newBlock);
        return;
    }
    skipBlockInFuture(skip) {
        this.app.authProcessService.skipPasskeyAppendAfterHybrid(skip);
    }
}
_PasskeyAppendAfterHybridBlock_passkeyAborted = new WeakMap();
