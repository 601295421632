import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyletron } from "baseui";

interface PanelTitleProps {
  title: string;
  icon: IconDefinition;
}

export function PanelTitle({ title, icon }: PanelTitleProps) {
  const [css] = useStyletron();

  return (
    <div className={css({ display: "flex", alignItems: "center" })}>
      <FontAwesomeIcon icon={icon} />
      <div className={css({ marginLeft: "10px" })}>{title}</div>
    </div>
  );
}
