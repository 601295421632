import personIconSrc from '@corbado/shared-ui/assets/person.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const PersonIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, personIconSrc);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'person-icon', ...props }));
});
