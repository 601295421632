import { aaguidMappings } from '@corbado/shared-ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ShieldIcon } from '../ui/icons/ShieldIcon';
import { SyncIcon } from '../ui/icons/SyncIcon';
// import { VisibilityIcon } from '../ui/icons/VisibilityIcon';
import { Text } from '../ui/typography/Text';
export const PasskeyDetails = ({ passkey }) => {
    var _a, _b;
    const { t } = useTranslation('translation', { keyPrefix: 'passkey-list' });
    const passkeyBadges = [
    // {
    //   icon: <VisibilityIcon className='cb-passkey-list-header-badge-icon' />,
    //   text: t('badge.seen'),
    // },
    ];
    if (passkey.backupState) {
        passkeyBadges.push({
            icon: React.createElement(SyncIcon, { className: 'cb-passkey-list-header-badge-icon' }),
            text: t('badge.synced'),
        });
    }
    if (passkey.transport.includes('hybrid')) {
        passkeyBadges.push({
            icon: React.createElement(ShieldIcon, { className: 'cb-passkey-list-header-badge-icon' }),
            text: t('badge.hybrid'),
        });
    }
    const sourceBrowser = passkey.sourceBrowser;
    const sourceOS = passkey.sourceOS;
    const title = (_b = (_a = aaguidMappings[passkey.authenticatorAAGUID]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Passkey';
    const hasMultipleBadgesClassName = passkeyBadges.length > 1 ? ' cb-has-multiple-badges' : '';
    return (React.createElement("div", { className: 'cb-passkey-list-details' },
        React.createElement("div", { className: `cb-passkey-list-header-badge-top-section${hasMultipleBadgesClassName}` }, passkeyBadges.map((badge, index) => (React.createElement("div", { key: index, className: 'cb-passkey-list-header-badge' },
            badge.icon,
            React.createElement(Text, { className: 'cb-passkey-list-header-badge-text' }, badge.text))))),
        React.createElement("div", { className: 'cb-passkey-list-header' },
            React.createElement("div", { className: 'cb-passkey-list-header-title' },
                React.createElement(Text, { className: 'cb-passkey-list-header-title' }, title)),
            React.createElement("div", { className: `cb-passkey-list-header-badge-section${hasMultipleBadgesClassName}` }, passkeyBadges.map((badge, index) => (React.createElement("div", { key: index, className: 'cb-passkey-list-header-badge' },
                badge.icon,
                React.createElement(Text, { className: 'cb-passkey-list-header-badge-text' }, badge.text)))))),
        React.createElement("div", null,
            React.createElement(Text, { level: '3', fontFamilyVariant: 'secondary', textColorVariant: 'secondary', className: 'cb-passkey-list-description-text' },
                React.createElement(Trans, { i18nKey: 'field_created', t: t, values: {
                        date: passkey.created,
                        browser: sourceBrowser,
                        os: sourceOS,
                    } }))),
        React.createElement("div", null,
            React.createElement(Text, { level: '3', fontFamilyVariant: 'secondary', textColorVariant: 'secondary', className: 'cb-passkey-list-description-text' },
                t('field_lastUsed'),
                passkey.lastUsed))));
};
