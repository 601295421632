import { updateSvgColors, updateSvgFillColor, updateSvgStrokeColor } from '@corbado/shared-ui';
import { useEffect, useState } from 'react';
import useTheme from './useTheme';
export var ColorType;
(function (ColorType) {
    ColorType[ColorType["Fill"] = 0] = "Fill";
    ColorType[ColorType["Stroke"] = 1] = "Stroke";
    ColorType[ColorType["FillAndStroke"] = 2] = "FillAndStroke";
})(ColorType || (ColorType = {}));
function getColoringFunction(colorType) {
    switch (colorType) {
        case ColorType.Fill:
            return updateSvgFillColor;
        case ColorType.Stroke:
            return updateSvgStrokeColor;
        case ColorType.FillAndStroke:
            return updateSvgColors;
        default:
            return updateSvgFillColor;
    }
}
export function useIconWithTheme(svgRef, iconSrc, color = '--cb-text-primary-color', colorType = ColorType.Fill) {
    const { themeUpdateTS } = useTheme();
    const [logoSVG, setLogoSVG] = useState(iconSrc);
    useEffect(() => {
        if (svgRef.current === null) {
            return;
        }
        const rootStyle = getComputedStyle(svgRef.current);
        const fetchedColor = rootStyle.getPropertyValue(color).trim();
        const newSvg = getColoringFunction(colorType)(logoSVG, fetchedColor);
        setLogoSVG(newSvg);
    }, [themeUpdateTS]);
    return { logoSVG };
}
