import React, { useEffect } from 'react';
import { IconButton } from './buttons/IconButton';
import { Divider } from './Divider';
import GithubIcon from './icons/GithubLogo';
import GoogleIcon from './icons/GoogleLogo';
import MicrosoftIcon from './icons/MicrosoftLogo';
const socialLoginKey = 'socialLogin';
const Icons = {
    github: React.createElement(GithubIcon, null),
    google: React.createElement(GoogleIcon, null),
    microsoft: React.createElement(MicrosoftIcon, null),
};
export const SocialLoginButtons = ({ dividerText, socialLogins, t, socialLoadingInProgress, onClick, }) => {
    const [loadingSocial, setLoadingSocial] = React.useState();
    const socialLoginsAvailable = socialLogins.length > 0;
    const socialLoginButtonSize = socialLogins.length > 2 ? 'small' : 'large';
    useEffect(() => {
        if (socialLoadingInProgress) {
            const socialLogin = localStorage.getItem(socialLoginKey);
            if (socialLogin) {
                setLoadingSocial(socialLogin);
            }
        }
        else if (socialLoadingInProgress === false) {
            setLoadingSocial(undefined);
            localStorage.removeItem(socialLoginKey);
        }
    }, [socialLoadingInProgress]);
    const handleSocialLoginClick = (socialName) => {
        localStorage.setItem(socialLoginKey, socialName);
        setLoadingSocial(socialName);
        onClick(socialName);
    };
    if (!socialLoginsAvailable) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, { label: dividerText !== null && dividerText !== void 0 ? dividerText : '', className: 'cb-social-login-divider' }),
        React.createElement("div", { className: `cb-social-login-buttons-section cb-social-login-buttons-section-${socialLoginButtonSize}` }, socialLogins.map(social => {
            var _a;
            return (React.createElement(IconButton, { key: social.name, icon: (_a = Icons[social.name]) !== null && _a !== void 0 ? _a : null, label: t(`social_signup.${social.name}`) || t(`social_signup.default`, social.name), onClick: () => handleSocialLoginClick(social.name), showIconOnly: socialLoginButtonSize === 'small', disabled: !!(loadingSocial && loadingSocial !== social.name), loading: loadingSocial === social.name, labelProps: {
                    level: '2',
                    textColorVariant: 'primary',
                } }));
        }))));
};
