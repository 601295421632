var _ContinueOnOtherEnvBlock_instances, _ContinueOnOtherEnvBlock_mapReasonToEnum;
import { __classPrivateFieldGet } from "tslib";
import { ContinueOnOtherDeviceReasonEnum } from '@corbado/web-core';
import { BlockTypes, ContinueOnOtherEnvReasons, ScreenNames } from '../constants';
import { Block } from './Block';
export class ContinueOnOtherEnvBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, authType, continueOnOtherDevice) {
        super(app, flowHandler, common, errorTranslator);
        _ContinueOnOtherEnvBlock_instances.add(this);
        this.type = BlockTypes.ContinueOnOtherEnv;
        this.initialScreen = ScreenNames.ContinueOnOtherEnv;
        this.authType = authType;
        this.data = { reason: __classPrivateFieldGet(this, _ContinueOnOtherEnvBlock_instances, "m", _ContinueOnOtherEnvBlock_mapReasonToEnum).call(this, continueOnOtherDevice.reason) };
    }
}
_ContinueOnOtherEnvBlock_instances = new WeakSet(), _ContinueOnOtherEnvBlock_mapReasonToEnum = function _ContinueOnOtherEnvBlock_mapReasonToEnum(r) {
    switch (r) {
        case ContinueOnOtherDeviceReasonEnum.EmailLinkVerified:
            return ContinueOnOtherEnvReasons.EmailLinkVerified;
        case ContinueOnOtherDeviceReasonEnum.ProcessAlreadyCompleted:
            return ContinueOnOtherEnvReasons.ProcessAlreadyCompleted;
        default:
            return ContinueOnOtherEnvReasons.ProcessAlreadyCompleted;
    }
};
