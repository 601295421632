import { useNavigate } from "react-router-dom";

import { useCorbado } from "@corbado/react";

const usePermissions = () => {
  const { user, loading } = useCorbado();
  const navigate = useNavigate();

  const isAdmin = async () => {
    if (loading) {
      console.log("Checking Permissions...");
      await isAdmin();
    } else if (user!.email !== "ben@bengould.co.uk") {
      navigate("/unauthorized");
    }
  };

  return { isAdmin };
};

export default usePermissions;
