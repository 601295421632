import { useEffect, useState } from "react";

import { APIProvider, Map as GoogleMap } from "@vis.gl/react-google-maps";
import { useStyletron } from "baseui";
import { LatLng } from "leaflet";
import "mapbox-gl/dist/mapbox-gl.css";
import { Map as MapKitMap } from "mapkit-react";
import { MapContainer, TileLayer } from "react-leaflet";
import { StyleObject } from "styletron-react";

import { MapboxMap } from "./MapBoxMap";
import { MapProvider, useMap } from "./MapContext";
import Position from "./Position";
import StealMapType from "./StealMapType";

function StealMap(
  props: Readonly<{
    children: React.ReactNode;
    position?: Position;
    containerStyle?: StyleObject;
    zoom?: number;
  }>
) {
  const [css, theme] = useStyletron();
  const [content, setContent] = useState<React.ReactNode>(null);

  const { map } = useMap();

  const deltas = {
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421
  };

  const defaultPosition: Position = {
    latitude: 51.55006295263954,
    longitude: -0.3691509665164274
  };

  const mapContainer: StyleObject = {
    height: "70vh",
    width: "70vw",
    margin: "auto",
    padding: "5rem",
    border: `5px solid ${theme.colors.accent400}`
  };

  useEffect(() => {
    switch (map) {
      case StealMapType.Mapbox:
        setContent(
          <MapboxMap
            containerStyle={
              props.containerStyle ? props.containerStyle : mapContainer
            }
            center={props.position ? props.position : defaultPosition}
            children={props.children}
          ></MapboxMap>
        );

        break;
      case StealMapType.Leaflet:
        const leafletPosition = new LatLng(
          props.position?.latitude ?? defaultPosition.latitude,
          props.position?.longitude ?? defaultPosition.longitude
        );

        setContent(
          <MapContainer
            center={leafletPosition}
            zoom={props.zoom ?? 12}
            scrollWheelZoom={true}
            className={
              props.containerStyle
                ? css(props.containerStyle)
                : css(mapContainer)
            }
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {props.children}
          </MapContainer>
        );
        break;
      case StealMapType.Mapkit:
        setContent(
          <MapKitMap
            token={process.env.REACT_APP_MAPBOX ?? ""}
            initialRegion={
              props.position
                ? {
                    ...deltas,
                    centerLatitude: props.position.latitude,
                    centerLongitude: props.position.longitude
                  }
                : {
                    ...deltas,
                    centerLatitude: 51.55006295263954,
                    centerLongitude: -0.3691509665164274
                  }
            }
          >
            {props.children}
          </MapKitMap>
        );

        break;
      case StealMapType.Google:
        const position = {
          lat: props.position?.latitude ?? defaultPosition.latitude,
          lng: props.position?.longitude ?? defaultPosition.longitude
        };
        setContent(
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}>
            <GoogleMap
              mapId="7964dad41cc55e5d"
              center={position}
              zoom={props.zoom ?? 14}
              className={
                props.containerStyle
                  ? css({
                      ...props.containerStyle
                    })
                  : css({
                      ...mapContainer
                    })
              }
            >
              {props.children}
            </GoogleMap>
          </APIProvider>
        );
        break;
      default:
        setContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.position, props.children, props.containerStyle]);

  return (
    <MapProvider>
      <div
        className={css({
          textAlign: "center",
          justifyContent: "center",
          display: "flex"
        })}
      >
        {content}
      </div>
    </MapProvider>
  );
}

export default StealMap;
