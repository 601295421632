import { useNavigate } from "react-router-dom";

import { CorbadoAuth } from "@corbado/react";

export const AuthPage = () => {
  const navigate = useNavigate();

  const onLoggedIn = () => {
    navigate("/");
  };

  return <CorbadoAuth onLoggedIn={onLoggedIn} />;
};
