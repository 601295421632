// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cb-emerald-funk-theme {
  --cb-primary-color: #21ba45;
  --cb-primary-color-hover: #136e29;
  --cb-primary-color-disabled: #87c459;
  --cb-button-text-primary-color: #ffffff;
  --cb-text-primary-color: #ffffff;
  --cb-text-secondary-color: #6e6d6d;
  --cb-border-color: #21ba45;
  --cb-error-text-color: #ff6b6b;
  --cb-secondary-link-color: #b5ff7d;
  --cb-box-color: #1f1f1f;
  --cb-box-color-hover: #3b3b3b;
  --cb-dark: #ffffff;
  --cb-white: #1b1d1b;
  --cb-primary-font: 'Comic Sans MS', cursive, sans-serif;
  --cb-secondary-font: 'Comic Sans MS', cursive, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/styles/themes/emerald-funk.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,iCAAiC;EACjC,oCAAoC;EACpC,uCAAuC;EACvC,gCAAgC;EAChC,kCAAkC;EAClC,0BAA0B;EAC1B,8BAA8B;EAC9B,kCAAkC;EAClC,uBAAuB;EACvB,6BAA6B;EAC7B,kBAAkB;EAClB,mBAAmB;EACnB,uDAAuD;EACvD,yDAAyD;AAC3D","sourcesContent":[".cb-emerald-funk-theme {\n  --cb-primary-color: #21ba45;\n  --cb-primary-color-hover: #136e29;\n  --cb-primary-color-disabled: #87c459;\n  --cb-button-text-primary-color: #ffffff;\n  --cb-text-primary-color: #ffffff;\n  --cb-text-secondary-color: #6e6d6d;\n  --cb-border-color: #21ba45;\n  --cb-error-text-color: #ff6b6b;\n  --cb-secondary-link-color: #b5ff7d;\n  --cb-box-color: #1f1f1f;\n  --cb-box-color-hover: #3b3b3b;\n  --cb-dark: #ffffff;\n  --cb-white: #1b1d1b;\n  --cb-primary-font: 'Comic Sans MS', cursive, sans-serif;\n  --cb-secondary-font: 'Comic Sans MS', cursive, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
