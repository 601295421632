import { useCorbado } from "@corbado/react";

import axios from "axios";

const useAxios = () => {
  const { shortSession } = useCorbado();

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_V5_API_BASE_URL,
    headers: {
      "x-user-session": shortSession ?? ""
    },
    timeout: 10000
  });

  return { apiClient };
};

export default useAxios;
