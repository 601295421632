import { AuthType } from '../api';
import { AuthProcess } from './authProcess';
export class EmailVerifyFromUrl {
    constructor(data, token, isNewDevice, process, authType) {
        this.data = data;
        this.token = token;
        this.isNewDevice = isNewDevice;
        this.process = process;
        this.authType = authType;
    }
    static fromURL(encodedProcess, token, existingProcess) {
        console.log('maybeProcess', encodedProcess, existingProcess);
        const decoded = JSON.parse(atob(encodedProcess));
        const process = decoded.process;
        const data = {
            alternativeVerificationMethods: [],
            identifier: decoded.blockData.identifier,
            retryNotBefore: decoded.blockData.retryNotBefore,
            verificationMethod: decoded.blockData.verificationMethod,
            isPostLoginVerification: decoded.blockData.isPostLoginVerification,
        };
        const isNewDevice = (existingProcess === null || existingProcess === void 0 ? void 0 : existingProcess.id) !== process.id;
        let authType;
        if (decoded.authType === 0) {
            authType = AuthType.Login;
        }
        else {
            authType = AuthType.Signup;
        }
        const authProcess = new AuthProcess(process.id, process.expires, process.frontendApiUrl);
        return new EmailVerifyFromUrl(data, token, isNewDevice, authProcess, authType);
    }
}
