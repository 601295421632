// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cb-primary-text-color {
  color: var(--cb-text-primary-color);
}

.cb-secondary-text-color {
  color: var(--cb-text-secondary-color);
}

.cb-header-text-color {
  color: var(--cb-header-text-color);
}

.cb-script-text-color {
  color: var(--cb-script-text-color);
}

.cb-error-text-color {
  color: var(--cb-error-text-color);
}

.cb-bold-text-weight {
  font-weight: var(--cb-font-weight-bold);
}

.cb-normal-text-weight {
  font-weight: var(--cb-font-weight-normal);
}

.cb-text-1 {
  font-family: var(--cb-secondary-font);
  font-size: var(--cb-base-font-size);
}

.cb-text-2 {
  font-family: var(--cb-secondary-font);
  font-size: calc(var(--cb-base-font-size) * 1.25);
}

.cb-text-3 {
  font-family: var(--cb-secondary-font);
  font-size: calc(var(--cb-base-font-size) * 1.5);
}

.cb-text-4 {
  font-family: var(--cb-secondary-font);
  font-size: calc(var(--cb-base-font-size) * 1.9);
}

.cb-text-5 {
  font-family: var(--cb-primary-font);
  font-size: calc(var(--cb-base-font-size) * 1.75);
}

.cb-text-6 {
  font-family: var(--cb-primary-font);
  font-size: calc(var(--cb-base-font-size) * 1.95);
}

.cb-text-7 {
  font-family: var(--cb-primary-font);
  font-size: calc(var(--cb-base-font-size) * 2.3);
}
`, "",{"version":3,"sources":["webpack://./src/styles/typography.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;EACrC,mCAAmC;AACrC;;AAEA;EACE,qCAAqC;EACrC,gDAAgD;AAClD;;AAEA;EACE,qCAAqC;EACrC,+CAA+C;AACjD;;AAEA;EACE,qCAAqC;EACrC,+CAA+C;AACjD;;AAEA;EACE,mCAAmC;EACnC,gDAAgD;AAClD;;AAEA;EACE,mCAAmC;EACnC,gDAAgD;AAClD;;AAEA;EACE,mCAAmC;EACnC,+CAA+C;AACjD","sourcesContent":[".cb-primary-text-color {\n  color: var(--cb-text-primary-color);\n}\n\n.cb-secondary-text-color {\n  color: var(--cb-text-secondary-color);\n}\n\n.cb-header-text-color {\n  color: var(--cb-header-text-color);\n}\n\n.cb-script-text-color {\n  color: var(--cb-script-text-color);\n}\n\n.cb-error-text-color {\n  color: var(--cb-error-text-color);\n}\n\n.cb-bold-text-weight {\n  font-weight: var(--cb-font-weight-bold);\n}\n\n.cb-normal-text-weight {\n  font-weight: var(--cb-font-weight-normal);\n}\n\n.cb-text-1 {\n  font-family: var(--cb-secondary-font);\n  font-size: var(--cb-base-font-size);\n}\n\n.cb-text-2 {\n  font-family: var(--cb-secondary-font);\n  font-size: calc(var(--cb-base-font-size) * 1.25);\n}\n\n.cb-text-3 {\n  font-family: var(--cb-secondary-font);\n  font-size: calc(var(--cb-base-font-size) * 1.5);\n}\n\n.cb-text-4 {\n  font-family: var(--cb-secondary-font);\n  font-size: calc(var(--cb-base-font-size) * 1.9);\n}\n\n.cb-text-5 {\n  font-family: var(--cb-primary-font);\n  font-size: calc(var(--cb-base-font-size) * 1.75);\n}\n\n.cb-text-6 {\n  font-family: var(--cb-primary-font);\n  font-size: calc(var(--cb-base-font-size) * 1.95);\n}\n\n.cb-text-7 {\n  font-family: var(--cb-primary-font);\n  font-size: calc(var(--cb-base-font-size) * 2.3);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
