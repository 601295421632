var _LoginInitBlock_instances, _LoginInitBlock_conditionalUIStarted, _LoginInitBlock_isEnvAffectedByUserGestureDetection;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import { AuthType, PasskeyChallengeCancelledError, SocialDataStatusEnum } from '@corbado/web-core';
import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class LoginInitBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, data) {
        var _a, _b, _c, _d, _e, _f;
        super(app, flowHandler, common, errorTranslator);
        _LoginInitBlock_instances.add(this);
        this.type = BlockTypes.LoginInit;
        this.authType = AuthType.Login;
        this.initialScreen = ScreenNames.LoginInit;
        _LoginInitBlock_conditionalUIStarted.set(this, false);
        const loginIdentifierError = errorTranslator.translate(data.fieldError);
        const lastIdentifierError = app.authProcessService.getLastIdentifier();
        this.data = {
            loginIdentifier: (_a = data.identifierValue) !== null && _a !== void 0 ? _a : '',
            loginIdentifierError: loginIdentifierError !== null && loginIdentifierError !== void 0 ? loginIdentifierError : '',
            lastIdentifier: lastIdentifierError,
            isPhoneFocused: data.isPhone,
            emailEnabled: data.isEmailAvailable,
            usernameEnabled: data.isUsernameAvailable,
            phoneEnabled: data.isPhoneAvailable,
            conditionalUIChallenge: data.conditionalUIChallenge,
            socialData: {
                providers: ((_c = (_b = data.socialData) === null || _b === void 0 ? void 0 : _b.providers) === null || _c === void 0 ? void 0 : _c.map(provider => {
                    return { name: provider };
                })) || [],
                oAuthUrl: (_d = data.socialData) === null || _d === void 0 ? void 0 : _d.oauthUrl,
                started: ((_e = data.socialData) === null || _e === void 0 ? void 0 : _e.status) === SocialDataStatusEnum.Started || false,
                finished: ((_f = data.socialData) === null || _f === void 0 ? void 0 : _f.status) === SocialDataStatusEnum.Finished || false,
            },
        };
        // errors in social logins should not be displayed in the login form (like we do for identifiers) but should appear on top of the screen
        if (data.error) {
            this.setError(data.error);
        }
    }
    async start(loginIdentifier, isPhone) {
        const b = await this.app.authProcessService.initLogin(loginIdentifier, isPhone);
        this.updateProcess(b);
    }
    switchToSignup() {
        const newPrimary = this.alternatives[0];
        const newAlternatives = [this];
        this.updateProcessFrontend(newPrimary, newAlternatives);
    }
    isSignupEnabled() {
        return this.alternatives.filter(b => b.type === BlockTypes.SignupInit).length > 0;
    }
    // only if the browser supports conditional UI and is not affected by user gesture detection we start conditional UI on initial page load
    async startConditionalUIOnPageLoad() {
        const supported = await this.app.authProcessService.isConditionalUISupported();
        if (!supported) {
            return false;
        }
        return !__classPrivateFieldGet(this, _LoginInitBlock_instances, "m", _LoginInitBlock_isEnvAffectedByUserGestureDetection).call(this);
    }
    // only if the browser supports conditional UI and browser is affected by user gesture detection we start conditional UI on first user interaction
    async startConditionalUIOnFirstUserInteraction() {
        const supported = await this.app.authProcessService.isConditionalUISupported();
        if (!supported) {
            return false;
        }
        return __classPrivateFieldGet(this, _LoginInitBlock_instances, "m", _LoginInitBlock_isEnvAffectedByUserGestureDetection).call(this);
    }
    async continueWithConditionalUI() {
        if (!this.data.conditionalUIChallenge) {
            return;
        }
        if (__classPrivateFieldGet(this, _LoginInitBlock_conditionalUIStarted, "f")) {
            console.log('Conditional UI already started');
            return;
        }
        __classPrivateFieldSet(this, _LoginInitBlock_conditionalUIStarted, true, "f");
        console.log('starting conditional UI');
        const b = await this.app.authProcessService.loginWithPasskeyChallenge(this.data.conditionalUIChallenge);
        if (b.err && (b.val instanceof PasskeyChallengeCancelledError || b.val.ignore)) {
            // we ignore this type of error
            return;
        }
        this.updateProcess(b);
    }
    async startSocialVerify(providerType) {
        const redirectUrl = window.location.origin + window.location.pathname;
        const res = await this.app.authProcessService.startSocialVerification(providerType, redirectUrl, AuthType.Login);
        if (!res) {
            return;
        }
        this.updateProcess(res);
    }
    async finishSocialVerify(abortController) {
        const res = await this.app.authProcessService.finishSocialVerification(abortController);
        this.updateProcess(res);
    }
    discardOfferedLastIdentifier() {
        this.app.authProcessService.dropLastIdentifier(undefined);
    }
}
_LoginInitBlock_conditionalUIStarted = new WeakMap(), _LoginInitBlock_instances = new WeakSet(), _LoginInitBlock_isEnvAffectedByUserGestureDetection = function _LoginInitBlock_isEnvAffectedByUserGestureDetection() {
    // parse user-agent to check if the browser is WebKit on iOS/iPadOS and version is below 17.4
    const userAgent = navigator.userAgent;
    const isWebKit = userAgent.includes('WebKit');
    const isIOS = userAgent.includes('iPhone') || userAgent.includes('iPad');
    if (!isWebKit || !isIOS) {
        return false;
    }
    // we are pessimistic here and assume that by default the version is below 17.4
    const m = userAgent.match(/iPhone OS ([\d_]+)/);
    let safariVersionAboveOrEqual174 = false;
    if (m && m.length > 1) {
        const version = m[1];
        const versionParts = version.split('.');
        if (versionParts.length > 1) {
            const major = parseInt(versionParts[0], 10);
            const minor = parseInt(versionParts[1], 10);
            safariVersionAboveOrEqual174 = major > 17 || (major === 17 && minor >= 4);
        }
    }
    console.log(isWebKit, isIOS, safariVersionAboveOrEqual174);
    // all mobile WebKit browsers that have a iOS version < 17.4 are affected by user gesture detection
    return !safariVersionAboveOrEqual174;
};
