import passkeyHybridSrc from '@corbado/shared-ui/assets/passkey-hybrid.svg';
import passkeyHybridDarkSrc from '@corbado/shared-ui/assets/passkey-hybrid-dark.svg';
import React from 'react';
import useTheme from '../../../hooks/useTheme';
import { Icon } from './Icon';
export const PasskeyHybridIcon = props => {
    const { darkMode } = useTheme();
    return (React.createElement(Icon, { src: darkMode ? passkeyHybridDarkSrc : passkeyHybridSrc, ...props }));
};
export default PasskeyHybridIcon;
