import { AuthType } from '@corbado/shared-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { PhoneIcon } from '../../../components/ui/icons/PhoneIcon';
import { OtpInputGroup } from '../../../components/ui/input/OtpInputGroup';
import { Header } from '../../../components/ui/typography/Header';
import { Text } from '../../../components/ui/typography/Text';
import { UserInfo } from '../../../components/ui/UserInfo';
export const PhoneOtp = ({ block }) => {
    const { t } = useTranslation('translation', { keyPrefix: `${block.authType}.phone-verify.phone-otp` });
    const [loading, setLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const timer = useRef();
    const phoneNumberRef = useRef('');
    const otpHasError = !loading && !!block.data.translatedError;
    useEffect(() => {
        setLoading(false);
        if (block.data.retryNotBefore) {
            const secondsNow = Math.floor(Date.now() / 1000);
            setRemainingTime(block.data.retryNotBefore - secondsNow);
        }
        const timer = startTimer();
        phoneNumberRef.current = block.getFormattedPhoneNumber();
        return () => clearInterval(timer);
    }, [block]);
    const descriptionTexts = useMemo(() => {
        if (block.data.isPostLoginVerification) {
            return {
                header: t('postLoginVerification.header'),
                bodyTitle: t('postLoginVerification.body_title'),
                bodyDescription: t('postLoginVerification.body_description'),
            };
        }
        return {
            header: t('header'),
            bodyTitle: t('body_title'),
            bodyDescription: t('body_description'),
        };
    }, [t]);
    const bodyResendText = useMemo(() => t('body_resend'), [t]);
    const resendButtonText = useMemo(() => {
        if (remainingTime < 1) {
            if (timer.current) {
                clearInterval(timer.current);
            }
            return t('button_resend');
        }
        return (React.createElement(Trans, { i18nKey: 'button_resendWaitingText', t: t, values: {
                remainingTime: remainingTime,
            } }));
    }, [remainingTime]);
    function startTimer() {
        timer.current = setInterval(() => setRemainingTime(time => time - 1), 1000);
        return timer.current;
    }
    const handleOtpChange = useCallback((userOtp) => {
        const otp = userOtp.join('');
        if (otp.length !== 6) {
            return;
        }
        setLoading(true);
        void block.validateCode(otp);
    }, [block]);
    async function resendCode() {
        setLoading(true);
        await block.resendCode();
        startTimer();
    }
    async function phoneChange() {
        if (block.authType === AuthType.Login) {
            setLoading(true);
            await block.confirmAbort();
            setLoading(false);
        }
        block.showEditPhone();
        return;
    }
    return (React.createElement("div", { className: 'cb-phone-otp-block' },
        React.createElement(Header, { className: 'cb-phone-otp-block-header' }, descriptionTexts.header),
        React.createElement(UserInfo, { className: 'cb-phone-otp-user-info-section', userData: phoneNumberRef.current, leftIcon: React.createElement(PhoneIcon, { className: 'cb-user-info-section-left-icon' }), onRightIconClick: () => void phoneChange() }),
        React.createElement(Text, { level: '2', fontWeight: 'bold', fontFamilyVariant: 'secondary', className: 'cb-row' }, descriptionTexts.bodyTitle),
        React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, descriptionTexts.bodyDescription),
        React.createElement(OtpInputGroup, { className: 'cb-phone-otp-input-container', emittedOTP: handleOtpChange, loading: loading, error: block.data.translatedError, showErrorMessage: otpHasError }),
        React.createElement(Text, { fontFamilyVariant: 'secondary', className: 'cb-phone-otp-resend-text' }, bodyResendText),
        React.createElement(PrimaryButton, { className: 'cb-phone-otp-resend-button', isLoading: loading, disabled: remainingTime > 0, onClick: () => void resendCode() }, resendButtonText)));
};
