import '../i18n';
import { handleTheming } from '@corbado/shared-ui';
import React, { useEffect } from 'react';
import { CorbadoSessionProvider } from '../contexts/CorbadoSessionProvider';
import ErrorHandlingProvider from '../contexts/ErrorHandlingProvider';
import { ThemeProvider } from '../contexts/ThemeProvider';
import { handleDynamicLocaleSetup } from '../i18n';
const CorbadoProvider = ({ children, defaultLanguage, autoDetectLanguage, customTranslations, darkMode, theme, corbadoAppInstance, customerSupportEmail, isDevMode, ...corbadoAppParams }) => {
    const [darkModeState, setDarkModeState] = React.useState();
    useEffect(() => {
        handleDynamicLocaleSetup(autoDetectLanguage, defaultLanguage, customTranslations);
    }, [autoDetectLanguage, defaultLanguage, customTranslations]);
    useEffect(() => {
        const { darkModeState, removeTheme } = handleTheming(darkMode !== null && darkMode !== void 0 ? darkMode : 'auto', theme);
        setDarkModeState(darkModeState);
        return removeTheme;
    }, [darkMode, theme]);
    return (React.createElement(CorbadoSessionProvider, { corbadoAppInstance: corbadoAppInstance, corbadoAppParams: corbadoAppParams },
        React.createElement(ErrorHandlingProvider, { customerSupportEmail: customerSupportEmail !== null && customerSupportEmail !== void 0 ? customerSupportEmail : '', isDevMode: isDevMode !== null && isDevMode !== void 0 ? isDevMode : false },
            React.createElement(ThemeProvider, { theme: theme, darkModeSubject: darkModeState }, children))));
};
export default CorbadoProvider;
