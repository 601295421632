import { styled } from "baseui";

export const AppHome = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  width: "100%",
  backgroundColor: "#334155",
  textAlign: "center",
  flexDirection: "column"
});

export const AppContentWrapper = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export const StealChartContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
});

export const ControlsContainer = styled("div", {
  display: "flex",
  padding: "1rem",
  border: "1px solid #E5E7EB", // TODO: Replace with the actual color code for border-gray-200
  borderRadius: "0.5rem"
});

export const ControlContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  paddingInlineStart: "1rem"
});
