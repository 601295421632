import { useCorbado } from "@corbado/react";

import { useStyletron } from "baseui";
import LogRocket from "logrocket";

import { LoadingScreen } from "@smc-legacy/components/LoadingScreen";

import { LoggedOutNavBar, NavBar } from "@smc/navbar";
import {
  DarkStealTheme,
  LightStealTheme,
  useTheme,
  useThemeDetector
} from "@smc/theme";

import { AuthPage } from "./login";

export function Layout(props: any) {
  const { loading, isAuthenticated, user, logout } = useCorbado();
  let content = loading ? <LoadingScreen /> : props.children;

  const [css] = useStyletron();
  const theme = useTheme();
  const isDarkTheme = useThemeDetector();

  const appBackground = css({
    backgroundColor: theme.theme.colors.backgroundPrimary,
    height: "100%"
  });

  if (isDarkTheme) {
    theme.chooseTheme(DarkStealTheme);
  } else {
    theme.chooseTheme(LightStealTheme);
  }

  if (!isAuthenticated) {
    content = (
      <div className={appBackground}>
        <LoggedOutNavBar />;
        <AuthPage />;
      </div>
    );
  }

  if (isAuthenticated && user) {
    props.appInsights.setAuthenticatedUserContext(user.sub, user.email, true);
    LogRocket.identify(user.sub, {
      name: user.name,
      email: user.email
    });
  }

  return (
    <div className={appBackground}>
      <NavBar user={user} logout={logout} />
      <div>{content}</div>
    </div>
  );
}
