import React from 'react';
import { EditIcon } from './icons/EditIcon';
import { EmailIcon } from './icons/EmailIcon';
import { Text } from './typography/Text';
const defaultLeftIcon = React.createElement(EmailIcon, { className: 'cb-user-info-section-left-icon' });
const defaultRightIcon = React.createElement(EditIcon, { className: 'cb-user-info-section-right-icon' });
export const UserInfo = ({ userData, className, leftIcon = defaultLeftIcon, showLeftIcon = true, rightIcon = defaultRightIcon, showRightIcon = true, onLeftIconClick, onRightIconClick, }) => {
    return (React.createElement("div", { className: `cb-user-info-section ${className ? ` ${className}` : ''}` },
        showLeftIcon && (React.createElement("div", { className: 'cb-user-info-section-left', onClick: onLeftIconClick }, leftIcon)),
        React.createElement("div", { className: 'cb-user-info-section-middle' },
            React.createElement(Text, { level: '2', fontWeight: 'normal', fontFamilyVariant: 'primary' }, userData)),
        showRightIcon && (React.createElement("div", { className: 'cb-user-info-section-right', onClick: onRightIconClick }, rightIcon))));
};
