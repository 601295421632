import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import { PhoneInputField } from '../../../components/ui/input/PhoneInputField';
import { Header } from '../../../components/ui/typography/Header';
export const EditPhone = ({ block }) => {
    const { t } = useTranslation('translation', { keyPrefix: `${block.authType}.phone-verify.edit-phone` });
    const [phone, setPhone] = useState(block.data.phone);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const phoneInputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = phoneInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const headerText = useMemo(() => t('header'), [t]);
    const primaryButtonText = useMemo(() => t('button_submit'), [t]);
    const secondaryButtonText = useMemo(() => t('button_cancel'), [t]);
    const handleConfirm = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        const error = await block.updatePhone(phone);
        if (error) {
            setErrorMessage(error);
            setLoading(false);
            return;
        }
    }, [block, phone]);
    return (React.createElement("form", { className: 'cb-edit-data-section', onSubmit: e => void handleConfirm(e) },
        React.createElement(Header, { size: 'md', className: 'cb-edit-data-section-header' }, headerText),
        React.createElement(PhoneInputField, { initialPhoneNumber: phone, errorMessage: errorMessage, onChange: setPhone }),
        React.createElement(PrimaryButton, { isLoading: loading, onClick: e => {
                const noChange = phone === block.data.phone;
                if (noChange) {
                    block.showPhoneOtpScreen();
                    return;
                }
                void handleConfirm(e);
            } }, primaryButtonText),
        React.createElement(SecondaryButton, { className: 'cb-edit-data-section-back-button', onClick: () => block.showPhoneOtpScreen() }, secondaryButtonText)));
};
