import React, { useMemo } from 'react';
import { LoadingSpinner, Text } from '..';
export const IconButton = ({ icon, label, href, className, labelProps, disabled, loading, showIconOnly = false, onClick, }) => {
    const buttonClasses = `cb-icon-button${showIconOnly ? `-with-icon-only` : ''}${className ? ` ${className}` : ''}`;
    const content = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'cb-icon-button-icon' }, icon),
            !showIconOnly && React.createElement(Text, { ...labelProps }, label)));
    }, [icon, label, showIconOnly, labelProps]);
    if (href) {
        return (React.createElement("a", { className: buttonClasses, href: href, target: '_blank' }, content));
    }
    return (React.createElement("button", { className: buttonClasses, onClick: onClick, title: showIconOnly ? label : undefined, disabled: disabled || loading }, loading ? React.createElement(LoadingSpinner, { className: 'cb-social-login-button-loading-spinner' }) : content));
};
