import { useEffect, useState } from "react";

import { FullUser } from "@corbado/node-sdk/cjs/generated/api";

import { faBiohazard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyletron } from "baseui";
import { Button, SHAPE } from "baseui/button";
import { ARTWORK_SIZES, ListItem, ListItemLabel } from "baseui/list";

import { LoadingV5, personIcon } from "@smc/components";
import { useAxios } from "@smc/hooks";

import UserDrawer from "./UserDrawer";

export default function Users() {
  const [css] = useStyletron();

  const { apiClient } = useAxios();

  const [users, setUsers] = useState<FullUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<FullUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  const fetchUsers = async () => {
    const response = await apiClient.get("users");
    setUsers(response.data.data.users);
    setLoading(false);
  };
  const updateUsers = async () => {
    setUpdateLoading(true);
    await apiClient.get("users/update");
    setUpdateLoading(false);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={css({
        textAlign: "center",
        height: "100vh",
        width: "50vw",
        margin: "auto"
      })}
    >
      {loading ? (
        <LoadingV5 />
      ) : (
        users.map((user) => (
          <ListItem
            key={user.ID}
            artwork={personIcon}
            artworkSize={ARTWORK_SIZES.LARGE}
            onClick={() => setSelectedUser(user)}
          >
            <ListItemLabel>{user.emails[0].email ?? user.ID}</ListItemLabel>
          </ListItem>
        ))
      )}
      <UserDrawer
        isOpen={!!selectedUser}
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <div style={{ height: "20px" }} />
      <Button
        kind="secondary"
        shape={SHAPE.circle}
        onClick={updateUsers}
        isLoading={updateLoading}
      >
        <FontAwesomeIcon icon={faBiohazard} />
      </Button>
    </div>
  );
}
