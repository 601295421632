import { useEffect } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import InputField from '../../../components/ui/input/InputField';
import { PhoneInputField } from '../../../components/ui/input/PhoneInputField';
import { SocialLoginButtons } from '../../../components/ui/SocialLoginButtons';
import { Header } from '../../../components/ui/typography/Header';
import { SubHeader } from '../../../components/ui/typography/SubHeader';
import { Text } from '../../../components/ui/typography/Text';
export const SignupInit = ({ block }) => {
    const { t } = useTranslation('translation', { keyPrefix: `signup.signup-init.signup-init` });
    const [loading, setLoading] = useState(false);
    const [socialLoadingInProgress, setSocialLoadingInProgress] = useState(undefined);
    const [phoneInput, setPhoneInput] = useState('');
    const usernameRef = useRef();
    const emailRef = useRef();
    const fullNameRef = useRef();
    const hasFocusField = useRef(false);
    useEffect(() => {
        setLoading(false);
        const socialAbort = new AbortController();
        if (block.data.socialData.finished && !block.error) {
            void block.finishSocialVerification(socialAbort).finally(() => setSocialLoadingInProgress(false));
            setSocialLoadingInProgress(true);
        }
        else if (block.data.socialData.finished) {
            setSocialLoadingInProgress(false);
        }
        return () => {
            socialAbort.abort();
        };
    }, [block]);
    const headerText = useMemo(() => t('header'), [t]);
    const subheaderText = useMemo(() => t('subheader'), [t]);
    const loginText = useMemo(() => t('text_login'), [t]);
    const flowChangeButtonText = useMemo(() => t('button_login'), [t]);
    const submitButtonText = useMemo(() => t('button_submit'), [t]);
    const fullNameFieldLabel = useMemo(() => t('textField_fullName'), [t]);
    const emailFieldLabel = useMemo(() => t('textField_email'), [t]);
    const phoneFieldLabel = useMemo(() => t('textField_phone'), [t]);
    const usernameFieldLabel = useMemo(() => t('textField_username'), [t]);
    const textDivider = useMemo(() => t('text_divider'), [t]);
    const handleSubmit = useCallback((e) => {
        var _a, _b, _c;
        e.preventDefault();
        setLoading(true);
        const identifiers = {
            email: (_a = emailRef.current) === null || _a === void 0 ? void 0 : _a.value,
            phone: phoneInput,
            userName: (_b = usernameRef.current) === null || _b === void 0 ? void 0 : _b.value,
        };
        const fullName = (_c = fullNameRef.current) === null || _c === void 0 ? void 0 : _c.value;
        void block.updateUserData(identifiers, fullName);
    }, [block, phoneInput, setLoading]);
    const attacheRef = (ref, value, el) => {
        if (el && !ref.current) {
            ref.current = el;
            if (!ref.current.value) {
                ref.current.value = value || '';
            }
            if (!hasFocusField.current) {
                ref.current.focus();
                hasFocusField.current = true;
            }
        }
    };
    const startSocialLogin = (providerType) => {
        setSocialLoadingInProgress(true);
        void block.startSocialVerify(providerType);
    };
    const fullName = block.data.fullName;
    const userName = block.data.userName;
    const email = block.data.email;
    const phone = block.data.phone;
    const foucsPhoneField = !!(phone && !email && !userName && !fullName);
    if (foucsPhoneField) {
        hasFocusField.current = true;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { size: 'lg' }, headerText),
        React.createElement(SubHeader, null,
            subheaderText,
            block.common.appName),
        React.createElement("form", { className: 'cb-form', onSubmit: handleSubmit },
            fullName && (React.createElement(InputField, { id: 'name', name: 'name', label: fullNameFieldLabel, errorMessage: fullName === null || fullName === void 0 ? void 0 : fullName.translatedError, ref: attacheRef.bind(null, fullNameRef, fullName === null || fullName === void 0 ? void 0 : fullName.value) })),
            userName && (React.createElement(InputField, { label: usernameFieldLabel, id: 'username', name: 'username', autoComplete: 'username', errorMessage: userName === null || userName === void 0 ? void 0 : userName.translatedError, ref: attacheRef.bind(null, usernameRef, userName === null || userName === void 0 ? void 0 : userName.value) })),
            email && (React.createElement(InputField, { label: emailFieldLabel, id: 'email', name: 'email', type: 'email', autoComplete: 'email', errorMessage: email === null || email === void 0 ? void 0 : email.translatedError, ref: attacheRef.bind(null, emailRef, email === null || email === void 0 ? void 0 : email.value) })),
            phone && (React.createElement(PhoneInputField, { label: phoneFieldLabel, id: 'phone', errorMessage: phone === null || phone === void 0 ? void 0 : phone.translatedError, initialCountry: 'US', initialPhoneNumber: phone === null || phone === void 0 ? void 0 : phone.value, autoFocus: foucsPhoneField, onChange: setPhoneInput })),
            React.createElement(PrimaryButton, { type: 'submit', className: 'cb-signup-form-submit-button', isLoading: loading, disabled: socialLoadingInProgress, onClick: handleSubmit }, submitButtonText)),
        React.createElement(SocialLoginButtons, { socialLogins: block.data.socialData.providers, dividerText: textDivider, t: t, socialLoadingInProgress: socialLoadingInProgress, onClick: startSocialLogin }),
        React.createElement(Text, { level: '2', fontWeight: 'normal', textColorVariant: 'script', className: 'cb-auth-change-section' },
            loginText,
            React.createElement(SecondaryButton, { colorVariant: 'link', disabled: loading, onClick: () => block.switchToLogin() }, flowChangeButtonText))));
};
