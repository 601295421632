import React, { ChangeEvent } from "react";

import { Input } from "baseui/input";

interface NumberInputProps {
  readonly placeholder: string;
  readonly id: string;
  readonly value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void; // Update this line
}

function NumberInput({
  placeholder = "Number",
  id,
  value,
  onChange
}: NumberInputProps) {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onChange) {
      // Asserting the target as HTMLInputElement
      const inputEvent = event as ChangeEvent<HTMLInputElement>;
      onChange(inputEvent);
    }
  };
  return (
    <Input
      type="number"
      id={id}
      data-testid={id}
      step={0.01}
      placeholder={placeholder}
      onChange={handleChange}
      overrides={{
        Root: {
          style: {
            marginBottom: "1rem"
          }
        }
      }}
    />
  );
}

export default NumberInput;
