import appleSrc from '@corbado/shared-ui/assets/apple.svg';
import React, { useRef } from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const AppleIcon = props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, appleSrc);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'apple-logo', ...props }));
};
export default AppleIcon;
