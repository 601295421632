var _CorbadoError_translatedMessage;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import log from 'loglevel';
export class CorbadoError extends Error {
    constructor(recoverable, ignore) {
        super('An error has occurred');
        _CorbadoError_translatedMessage.set(this, void 0);
        this.name = 'CorbadoError';
        this.recoverable = recoverable;
        this.ignore = ignore;
    }
    get translatedMessage() {
        if (!__classPrivateFieldGet(this, _CorbadoError_translatedMessage, "f")) {
            // if the error can not be translated we show the untranslated message
            return this.message;
        }
        return __classPrivateFieldGet(this, _CorbadoError_translatedMessage, "f");
    }
    set translatedMessage(message) {
        __classPrivateFieldSet(this, _CorbadoError_translatedMessage, message, "f");
    }
    static missingInit() {
        return new CorbadoError(false, false);
    }
    static invalidConfig() {
        return new CorbadoError(false, false);
    }
    static ignore() {
        return new CorbadoError(true, true);
    }
    static conditionalUINotSupported() {
        return new CorbadoError(false, true);
    }
    static fromAxiosError(error) {
        log.error('axios error', error);
        if (!error.response || !error.response.data) {
            return NonRecoverableError.unhandledBackendError('no_data_in_response');
        }
        const errorRespRaw = error.response.data;
        console.log('errorRespRaw', errorRespRaw.error.type);
        const errorResp = errorRespRaw.error;
        switch (errorResp.type) {
            case 'process_not_available':
                return new ProcessNotFound();
        }
        return NonRecoverableError.unhandledBackendError(errorResp.type);
    }
    static fromDOMException(e) {
        console.log('e', e.name, e.message);
        switch (e.name) {
            case 'NotAllowedError':
            case 'AbortError':
                return new PasskeyChallengeCancelledError();
            case 'SecurityError':
                return new NonRecoverableError('SecurityError');
            default:
                log.warn('unhandled DOM exception', e.name, e.message);
                return new NonRecoverableError(e.message);
        }
    }
    static fromUnknownFrontendError(e) {
        if (e instanceof Error) {
            if (e.name === 'CanceledError') {
                return CorbadoError.ignore();
            }
        }
        return NonRecoverableError.unhandledFrontendError(`unknown ${e}`);
    }
    static noPasskeyAvailable() {
        return new NoPasskeyAvailableError();
    }
    static onlyHybridPasskeyAvailable() {
        return new OnlyHybridPasskeyAvailableError();
    }
}
_CorbadoError_translatedMessage = new WeakMap();
/**
 * RecoverableError can be handled by either showing an error message to the user, by retrying the operation or by calling a fallback function.
 * Most errors fall into this category.
 */
export class RecoverableError extends CorbadoError {
    constructor(name) {
        super(true, false);
        this.name = name;
    }
    getTranslationKey() {
        return `errors.${this.name}`;
    }
    static unknown() {
        return new RecoverableError('unknown');
    }
}
/**
 * NonRecoverableErrors are only thrown when there is a big problem with the application (e.g. the application is misconfigured).
 * We can not recover from such an error.
 * Therefore, these errors are handled by showing a central error page.
 * Only a few errors should fall into this category.
 *
 * We don't offer translations for NonRecoverableError in general.
 * Only the generic version of the NonRecoverableError can be translated because it is intended to be shown to the end user.
 * The more detailed versions of NonRecoverableError are only shown to developers so there is no need to translate them.
 */
export class NonRecoverableError extends CorbadoError {
    constructor(message, link, requestId) {
        super(false, false);
        this.message = message;
        this.link = link;
        this.requestId = requestId;
    }
    static unhandledFrontendError(message) {
        return new NonRecoverableError(`An error occurred in the frontend was not handled: ${message}`);
    }
    static unhandledBackendError(code, message) {
        return new NonRecoverableError(`This error is not being handled by the frontend: ${message} (${code})`);
    }
}
export class UserAlreadyExistsError extends RecoverableError {
    constructor() {
        super('User already exists');
        this.name = 'errors.userAlreadyExists';
    }
}
export class PasskeyAlreadyExistsError extends RecoverableError {
    constructor() {
        super('Passkey for this device already exists');
        this.name = 'errors.passkeyAlreadyExists';
    }
}
export class PasskeysNotSupported extends RecoverableError {
    constructor() {
        super('Passkeys are not supported for this device');
        this.name = 'errors.passkeysNotSupported';
    }
}
export class UnknownUserError extends RecoverableError {
    constructor() {
        super('User does not exist');
        this.name = 'errors.unknownUser';
    }
}
export class NoPasskeyAvailableError extends RecoverableError {
    constructor() {
        super('No passkey available');
        this.name = 'errors.noPasskeyAvailable';
    }
}
export class OnlyHybridPasskeyAvailableError extends RecoverableError {
    constructor() {
        super('Only hybrid passkey available');
        this.name = 'errors.onlyHybridPasskeyAvailable';
    }
}
export class InvalidPasskeyError extends RecoverableError {
    constructor() {
        super('The provided passkey is no longer valid.');
        this.name = 'errors.invalidPasskey';
    }
}
export class PasskeyChallengeCancelledError extends RecoverableError {
    constructor() {
        super('Passkey challenge cancelled');
        this.name = 'errors.passkeyChallengeCancelled';
    }
}
export class InvalidFullnameError extends RecoverableError {
    constructor() {
        super('Invalid fullName');
        this.name = 'errors.invalidFullname';
    }
}
export class InvalidEmailError extends RecoverableError {
    constructor() {
        super('Invalid email');
        this.name = 'errors.invalidName';
    }
}
export class InvalidOtpInputError extends RecoverableError {
    constructor() {
        super('The provided OTP is no longer valid');
        this.name = 'errors.invalidOtp';
    }
}
export class InvalidTokenInputError extends RecoverableError {
    constructor() {
        super('The provided token is not valid for user verification');
        this.name = 'errors.invalidToken';
    }
}
export class ConditionalUiNotSupportedError extends RecoverableError {
    constructor() {
        super('Conditional UI is not supported by your device');
        this.name = 'errors.noConditionalUiSupport';
    }
}
export class ConditionalUiUnconfirmedCredential extends RecoverableError {
    constructor() {
        super('Unconfirmed credential');
        this.name = 'errors.conditionalUiUnconfirmedCredential';
    }
}
export class UnknownError extends RecoverableError {
    constructor() {
        super('An unknown error occurred');
        this.name = 'errors.unknownError';
    }
}
export class ProcessNotFound extends RecoverableError {
    constructor() {
        super('processNotFound');
    }
}
