import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class PhoneVerifyBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.PhoneVerify;
        this.initialScreen = ScreenNames.PhoneOtp;
        this.getFormattedPhoneNumber = () => Block.getFormattedPhoneNumber(this.data.phone);
        const data = blockBody.data;
        this.authType = blockBody.authType;
        this.data = {
            phone: data.identifier,
            translatedError: errorTranslator.translate(data.error),
            retryNotBefore: data.retryNotBefore,
            isPostLoginVerification: data.isPostLoginVerification,
        };
    }
    showEditPhone() {
        this.updateScreen(ScreenNames.EditPhone);
    }
    showPhoneOtpScreen() {
        this.updateScreen(ScreenNames.PhoneOtp);
    }
    async validateCode(code) {
        const newBlock = await this.app.authProcessService.finishPhoneOtpVerification(code);
        this.updateProcess(newBlock);
        return;
    }
    async resendCode() {
        const newBlock = await this.app.authProcessService.startPhoneOtpVerification();
        this.updateProcess(newBlock);
        return;
    }
    async updatePhone(value) {
        const newBlock = await this.app.authProcessService.updatePhone(value);
        if (newBlock.err) {
            this.updateProcess(newBlock);
            return;
        }
        const error = newBlock.val.blockBody.error;
        //If the new phone number is invalid, we don't want to update the block because the new block data from BE has no indicator for ScreenNames.EditPhone
        //So, the FE needs to maintain state and we just  want to show the translated error message
        if (error) {
            return this.errorTranslator.translateWithIdentifier(error, 'phone');
        }
        await this.resendCode();
        this.showPhoneOtpScreen();
        return;
    }
}
