import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Text } from '../../../components/ui';
import { EmailLinkSuccessIcon } from '../../../components/ui/icons/EmailLinkSuccess';
export const EmailLinkSuccess = ({ block, isOldTab = false }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `${block.authType}.email-verify.email-link-success`,
    });
    const headerText = useMemo(() => t('header'), [t]);
    const subheaderText = useMemo(() => t(`subheader.${isOldTab ? 'isOldTab' : 'isNewTab'}`), [t]);
    const bodyText = useMemo(() => t('body'), [t]);
    return (React.createElement("div", { className: 'cb-email-link-success-block' },
        React.createElement(Header, null, headerText),
        React.createElement(Text, { level: '2', className: 'cb-email-link-success-subheader' }, subheaderText),
        React.createElement("div", { className: 'cb-email-link-success-icon-container' },
            React.createElement(EmailLinkSuccessIcon, { className: 'cb-email-link-success-icon' })),
        React.createElement(Text, { level: '1', fontFamilyVariant: 'secondary' }, bodyText)));
};
