import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, PrimaryButton, SecondaryButton, Text, UserInfo } from '../../../components';
import { PasskeyErrorIcon } from '../../../components/ui/icons/PasskeyErrorIcon';
import { PersonIcon } from '../../../components/ui/icons/PersonIcon';
export const PasskeyError = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `login.passkey-verify.passkey-error`,
    });
    const [loading, setLoading] = useState(false);
    const [changingBlock, setChangingBlock] = useState(false);
    const [userInfo, setUserInfo] = useState(block.data.identifierValue);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyTitleText = useMemo(() => t('body_title'), [t]);
    const bodyDescriptionText = useMemo(() => t('body_description'), [t]);
    const dividerText = useMemo(() => t('text_divider'), [t]);
    const tryAgainButtonText = useMemo(() => t('button_tryAgain'), [t]);
    const fallbackButtonText = useMemo(() => { var _a, _b; return t((_b = (_a = block.data.preferredFallbackOnError) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ''); }, [t, block.data.preferredFallbackOnError]);
    const primaryAction = useCallback(async () => {
        setLoading(true);
        if (block.data.preferredFallbackOnError) {
            setChangingBlock(true);
            await block.data.preferredFallbackOnError.action();
        }
        else {
            await block.passkeyLogin();
        }
        setLoading(false);
    }, [block]);
    useEffect(() => {
        return () => {
            block.cancelPasskeyOperation();
        };
    }, []);
    useEffect(() => {
        setUserInfo(block.getFormattedPhoneNumber());
    }, [block]);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void primaryAction();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [primaryAction]);
    async function userInfoChange() {
        setLoading(true);
        await block.confirmAbort();
        setLoading(false);
    }
    async function secondaryAction() {
        setLoading(true);
        await block.passkeyLogin();
        setLoading(false);
    }
    return (React.createElement("div", { className: 'cb-pk-error' },
        React.createElement(Header, null, headerText),
        React.createElement("div", { className: 'cb-pk-error-bloc-icon' },
            React.createElement(PasskeyErrorIcon, null)),
        React.createElement("div", { className: 'cb-pk-error-user-info-edit-section' },
            React.createElement(Text, { level: '2', fontWeight: 'bold', className: 'cb-pk-error-user-info-edit-section-title' }, bodyTitleText),
            React.createElement(UserInfo, { userData: userInfo, leftIcon: React.createElement(PersonIcon, { className: 'cb-user-info-section-left-icon' }), onRightIconClick: () => void userInfoChange() })),
        React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', className: 'cb-pk-error-bloc-description' }, bodyDescriptionText),
        React.createElement(PrimaryButton, { onClick: () => void primaryAction(), isLoading: loading, disabled: changingBlock }, block.data.preferredFallbackOnError ? fallbackButtonText : tryAgainButtonText),
        block.data.preferredFallbackOnError && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { label: dividerText, className: 'cb-pk-error-bloc-divider' }),
            React.createElement(SecondaryButton, { onClick: () => void secondaryAction(), disabled: changingBlock }, tryAgainButtonText)))));
};
