import { getSemanticVersion } from "../../functions/utils";
import { StealMyVersion } from "../../types/steal-my-version";

export function LineChartFooter(props: { apiVersionInfo?: StealMyVersion }) {
  const apiVersion = getSemanticVersion(
    props.apiVersionInfo?.dllVersion as string
  );
  return (
    <ul
      className="list-disc text-xs bold font-sans text-zinc-950"
      data-testid="version-info"
    >
      <li data-testid="api-version">API Version: {apiVersion}</li>
      <li data-testid="infrastructure-version">
        Infrastructure Version:{" "}
        {props.apiVersionInfo?.infrastructureVersion ?? "Unknown"}
      </li>
    </ul>
  );
}
