// https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/-0.3684,51.5502,17.49,0,60/200x200?access_token=
import { useStyletron } from "baseui";

import { Drive } from "@smc/types/drive";

type MapImageProps = {
  drive: Drive;
};

export default function MapImage({ drive }: Readonly<MapImageProps>) {
  const [css, theme] = useStyletron();

  return (
    <div className={css({ textAlign: "center" })}>
      <img
        src={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/${drive.longitude},${drive.latitude},14,0,0/150x75?access_token=${process.env.REACT_APP_MAPBOX}`}
        alt="Map"
        className={css({
          margin: "auto",
          border: `3px solid ${theme.colors.contentSecondary}`
        })}
      />
    </div>
  );
}
