import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { Header } from '../typography/Header';
import { Text } from '../typography/Text';
export const ComponentUnavailableError = ({ customerSupportEmail }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'errors.componentUnavailable' });
    return (React.createElement("div", { className: 'cb-error-page' },
        React.createElement(Header, { size: 'lg' }, t('header')),
        React.createElement("div", { className: 'cb-error-page-body' },
            React.createElement("div", null,
                React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, t('subheader'))),
            React.createElement("div", null,
                React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, customerSupportEmail ? (React.createElement(Trans, { i18nKey: 'body_withCustomerSupport', t: t, values: {
                        customerSupportEmail: customerSupportEmail,
                    } })) : (t('body_noCustomerSupport'))))),
        React.createElement(PrimaryButton, { onClick: () => window.location.reload() }, t('button'))));
};
