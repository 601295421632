import React, { useEffect, useState } from "react";

import { Button } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { ANCHOR, Drawer } from "baseui/drawer";
import { OnChangeParams, SIZE, Select } from "baseui/select";
import { DateTime } from "luxon";

import {
  createLockwindows,
  getDaySelectOption,
  getDaysSinceDataStarted,
  hasMetMinimumDayThreshold
} from "../functions/utils";
import { DeeTeeOh } from "../types/dee-tee-oh";
import LockWindow from "../types/lock-window";
import { IHistoryGraphProps } from "../types/state/HistoryGraphProps";
import { LoadingScreen } from "./LoadingScreen";
import { LineChartFooter } from "./line-chart/Footer";
import { LineChartHeader } from "./line-chart/Header";
import { StealChart } from "./line-chart/StealChart";
import { PetrolForm } from "./petrol-form/form";
import {
  AppContentWrapper,
  ControlContainer,
  ControlsContainer,
  StealChartContainer
} from "./primitives/containers";

function HistoryGraph(props: Readonly<IHistoryGraphProps>) {
  const [jaguar, setJaguar] = useState<DeeTeeOh | undefined>(undefined);
  const [lockWindows, setLockWindows] = useState<LockWindow[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [selectedDays, setSelectedDays] = useState(14);
  const [enablePetrol, setEnablePetrol] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchHistoryData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/history/deets?bosh=${props.bish}&days=${selectedDays}`
      );
      const data: DeeTeeOh = await response.json();
      for (const status of data.history) {
        status.timestamp = DateTime.fromISO(status.timestamp.toString());
      }
      const lockWindows = createLockwindows(data.history);
      setJaguar(data);
      setLockWindows(lockWindows);
      setLoading(false);
    };

    fetchHistoryData();
  }, [props.bish, selectedDays]);

  const handleDaysSelectChange = (params: OnChangeParams) => {
    const selectedValue = params.value;
    if (selectedValue.length > 0 && selectedValue[0].id) {
      setSelectedDays(+selectedValue[0].id);
    }
  };

  const handlePetrolCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnablePetrol(event.target.checked);
  };

  const renderLineChart = (
    deeteeoh?: DeeTeeOh,
    lockWindows?: LockWindow[],
    enablePetrol?: boolean
  ) => {
    return (
      <StealChartContainer>
        <LineChartHeader
          count={deeteeoh?.history.length}
          totalMileage={deeteeoh?.totalMileage}
          averageMileage={deeteeoh?.mileagePerDay}
        />
        <StealChart
          deeteeoh={deeteeoh}
          lockWindows={lockWindows}
          enablePetrol={enablePetrol}
        />
        <LineChartFooter apiVersionInfo={deeteeoh?.apiVersionInfo} />
      </StealChartContainer>
    );
  };

  const daysOptions = [
    getDaySelectOption(3),
    getDaySelectOption(7),
    getDaySelectOption(14),
    getDaySelectOption(30),
    getDaySelectOption(90),
    getDaySelectOption(180)
  ];

  if (hasMetMinimumDayThreshold(365)) {
    daysOptions.push({ id: "365", label: "1 Year" });
  }

  const daysSince = getDaysSinceDataStarted();
  daysOptions.push({ id: daysSince.days.toFixed(0), label: "Forever" });

  let contents = loading ? (
    <LoadingScreen />
  ) : (
    <>
      {renderLineChart(jaguar, lockWindows, enablePetrol)}
      <ControlsContainer>
        <ControlContainer>
          <Checkbox
            checked={enablePetrol}
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={handlePetrolCheckboxChange}
            labelPlacement={LABEL_PLACEMENT.left}
          >
            Petrol {enablePetrol ? "Off" : "On"}
          </Checkbox>
        </ControlContainer>
        <ControlContainer>
          <Select
            size={SIZE.compact}
            options={daysOptions}
            value={[
              {
                id: selectedDays.toString(),
                label: `${selectedDays} Days`
              }
            ]}
            onChange={handleDaysSelectChange}
            clearable={false}
            labelKey="label" // TODO: are we sure we're using this?
            valueKey="id"
            placeholder="Select days"
            searchable={false}
          />
        </ControlContainer>
        <ControlContainer>
          <Button onClick={() => setIsOpen(true)}>Refuel</Button>
        </ControlContainer>
      </ControlsContainer>
    </>
  );

  return (
    <AppContentWrapper>
      {contents}
      <Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => setIsOpen(false)}
        anchor={ANCHOR.bottom}
      >
        <PetrolForm />
      </Drawer>
    </AppContentWrapper>
  );
}

export default HistoryGraph;
