import { Err, Ok } from 'ts-results';
import { InvalidTokenInputError } from '../errors';
export const getEmailLinkToken = () => {
    var _a;
    const searchParams = new URLSearchParams(window.location.search);
    const emailLinkId = searchParams.get('corbadoEmailLinkID');
    if (!emailLinkId) {
        return Err(new InvalidTokenInputError());
    }
    const token = (_a = searchParams.get('corbadoToken')) === null || _a === void 0 ? void 0 : _a.split('#email-link-confirm')[0];
    if (!token) {
        return Err(new InvalidTokenInputError());
    }
    return Ok({ emailLinkId, token });
};
