import * as React from "react";

import { useStyletron } from "baseui";
import { AppNavBar, NavItem } from "baseui/app-nav-bar";

import { useThemeDetector } from "@smc/theme/ThemeContext";

import { SiteTitle } from "./site-title";

export default function LoggedOutNavBar() {
  const [css] = useStyletron();
  const isDarkTheme = useThemeDetector();

  const [mainItems, setMainItems] = React.useState<NavItem[]>([
    {
      label: isDarkTheme ? "🌚" : "🌝"
    }
  ]);

  React.useEffect(() => {
    setMainItems([
      {
        label: isDarkTheme ? "🌚" : "🌝"
      }
    ]);
  }, [isDarkTheme]);

  return (
    <>
      <div
        className={css({
          boxSizing: "border-box",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0"
        })}
      >
        <AppNavBar title={<SiteTitle />} mainItems={mainItems} />
      </div>
      {/* TODO: this isn't right */}
      <div className={css({ height: "64px" })} />
    </>
  );
}
