import { BehaviorSubject } from 'rxjs';
const loadTheme = (theme, isDarkMode) => {
    const classList = document.body.classList;
    const newClass = typeof theme === 'string' ? theme : isDarkMode ? theme.dark : theme.light;
    if (classList.contains(newClass)) {
        classList.remove(newClass);
    }
    classList.add(newClass);
};
const removeTheme = (theme) => {
    const classList = document.body.classList;
    const newClass = typeof theme === 'string' ? theme : theme.dark;
    if (classList.contains(newClass)) {
        classList.remove(newClass);
    }
};
export const hasDarkMode = () => {
    return document.body.classList.contains('cb-dark');
};
const addDarkMode = () => {
    if (hasDarkMode()) {
        return;
    }
    document.body.classList.add('cb-dark');
};
const removeDarkMode = () => {
    document.body.classList.remove('cb-dark');
};
const autoDetectSystemTheme = (darkModeState, customTheme) => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const darkModeListener = (e) => {
        if (e.matches) {
            darkModeState.next(true);
            addDarkMode();
        }
        else {
            darkModeState.next(false);
            removeDarkMode();
        }
        if (customTheme) {
            loadTheme(customTheme, e.matches);
        }
    };
    darkModeMediaQuery.addEventListener('change', darkModeListener);
    if (darkModeMediaQuery.matches) {
        addDarkMode();
        darkModeState.next(true);
    }
    else {
        removeDarkMode();
        darkModeState.next(false);
    }
    if (customTheme) {
        loadTheme(customTheme, darkModeMediaQuery.matches);
    }
    return () => {
        darkModeMediaQuery.removeEventListener('change', darkModeListener);
    };
};
export const handleTheming = (darkMode, customTheme) => {
    let removeDarkModeListener;
    const darkModeState = new BehaviorSubject(darkMode === 'on');
    switch (darkMode) {
        case 'on':
            addDarkMode();
            if (customTheme) {
                loadTheme(customTheme, true);
            }
            break;
        case 'off':
            removeDarkMode();
            if (customTheme) {
                loadTheme(customTheme, false);
            }
            break;
        case 'auto':
            removeDarkModeListener = autoDetectSystemTheme(darkModeState, customTheme);
            break;
        default:
            break;
    }
    return {
        darkModeState,
        removeTheme: () => {
            if (customTheme) {
                removeTheme(customTheme);
            }
            if (darkMode === 'auto' && removeDarkModeListener) {
                removeDarkModeListener();
            }
        },
    };
};
export var CorbadoThemes;
(function (CorbadoThemes) {
    CorbadoThemes["EmeraldFunk"] = "cb-emerald-funk-theme";
})(CorbadoThemes || (CorbadoThemes = {}));
