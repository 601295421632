var _SessionService_instances, _a, _SessionService_usersApi, _SessionService_webAuthnService, _SessionService_setShortSessionCookie, _SessionService_isPreviewMode, _SessionService_projectId, _SessionService_frontendApiUrlSuffix, _SessionService_sessionConfig, _SessionService_shortSession, _SessionService_longSession, _SessionService_refreshIntervalId, _SessionService_userChanges, _SessionService_shortSessionChanges, _SessionService_authStateChanges, _SessionService_onShortSessionChange, _SessionService_setApisV2, _SessionService_createAxiosInstanceV2, _SessionService_getShortTermSessionToken, _SessionService_getCookieValue, _SessionService_deleteLongSessionToken, _SessionService_getLongSessionToken, _SessionService_setShortTermSessionToken, _SessionService_deleteShortTermSessionToken, _SessionService_getShortSessionCookieString, _SessionService_getDeleteShortSessionCookieString, _SessionService_setLongSessionToken, _SessionService_handleRefreshRequest, _SessionService_refresh, _SessionService_handleVisibilityChange, _SessionService_updateUser, _SessionService_updateAuthState, _SessionService_getSessionConfig, _SessionService_getShortSessionCookieConfig, _SessionService_loadSessionConfig, _SessionService_getDefaultFrontendApiUrl, _SessionService_buildCorbadoFlags;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
import axios from 'axios';
import log from 'loglevel';
import { BehaviorSubject } from 'rxjs';
import { Err, Ok, Result } from 'ts-results';
import { Configuration } from '../api/v1';
import { ConfigsApi, UsersApi } from '../api/v2';
import { ShortSession } from '../models/session';
import { AuthState, CorbadoError, PasskeyAlreadyExistsError, PasskeysNotSupported, } from '../utils';
import { WebAuthnService } from './WebAuthnService';
const shortSessionKey = 'cbo_short_session';
const longSessionKey = 'cbo_long_session';
// controls how long before the shortSession expires we should refresh it
const shortSessionRefreshBeforeExpirationSeconds = 60;
// controls how often we check if we need to refresh the session
const shortSessionRefreshIntervalMs = 10000;
const packageVersion = '0.0.0';
/**
 * The SessionService manages user sessions for the Corbado Application, handling shortSession and longSession.
 * It offers methods to set, delete, and retrieve these tokens and the username,
 * as well as a method to fetch the full user object from the Corbado API.
 *
 * The longSession should not be exposed from this service as it is only used for session refresh.
 */
export class SessionService {
    constructor(projectId, setShortSessionCookie, isPreviewMode, frontendApiUrlSuffix) {
        _SessionService_instances.add(this);
        _SessionService_usersApi.set(this, new UsersApi());
        _SessionService_webAuthnService.set(this, void 0);
        _SessionService_setShortSessionCookie.set(this, void 0);
        _SessionService_isPreviewMode.set(this, void 0);
        _SessionService_projectId.set(this, void 0);
        _SessionService_frontendApiUrlSuffix.set(this, void 0);
        _SessionService_sessionConfig.set(this, void 0);
        _SessionService_shortSession.set(this, void 0);
        _SessionService_longSession.set(this, void 0);
        _SessionService_refreshIntervalId.set(this, void 0);
        _SessionService_userChanges.set(this, new BehaviorSubject(undefined));
        _SessionService_shortSessionChanges.set(this, new BehaviorSubject(undefined));
        _SessionService_authStateChanges.set(this, new BehaviorSubject(AuthState.LoggedOut));
        _SessionService_updateUser.set(this, (user) => {
            const currentUser = __classPrivateFieldGet(this, _SessionService_userChanges, "f").value;
            if (currentUser === user) {
                return;
            }
            if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) === (user === null || user === void 0 ? void 0 : user.email) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.name) === (user === null || user === void 0 ? void 0 : user.name) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.orig) === (user === null || user === void 0 ? void 0 : user.orig) &&
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.sub) === (user === null || user === void 0 ? void 0 : user.sub)) {
                return;
            }
            __classPrivateFieldGet(this, _SessionService_userChanges, "f").next(user);
        });
        _SessionService_updateAuthState.set(this, (authState) => {
            if (__classPrivateFieldGet(this, _SessionService_authStateChanges, "f").value === authState) {
                return;
            }
            __classPrivateFieldGet(this, _SessionService_authStateChanges, "f").next(authState);
        });
        _SessionService_getSessionConfig.set(this, () => {
            if (!__classPrivateFieldGet(this, _SessionService_sessionConfig, "f")) {
                throw CorbadoError.missingInit();
            }
            return __classPrivateFieldGet(this, _SessionService_sessionConfig, "f");
        });
        _SessionService_getShortSessionCookieConfig.set(this, () => {
            const cfg = __classPrivateFieldGet(this, _SessionService_getSessionConfig, "f").call(this).shortSessionCookieConfig;
            if (!cfg) {
                throw CorbadoError.invalidConfig();
            }
            return cfg;
        });
        _SessionService_loadSessionConfig.set(this, async () => {
            const config = new Configuration({
                apiKey: __classPrivateFieldGet(this, _SessionService_projectId, "f"),
            });
            const axiosInstance = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_createAxiosInstanceV2).call(this);
            const configsApi = new ConfigsApi(config, __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDefaultFrontendApiUrl).call(this), axiosInstance);
            return Result.wrapAsync(async () => {
                const r = await configsApi.getSessionConfig();
                return r.data;
            });
        });
        _SessionService_buildCorbadoFlags.set(this, () => {
            const flags = [];
            if (__classPrivateFieldGet(this, _SessionService_isPreviewMode, "f")) {
                flags.push('preview');
            }
            return flags.join(',');
        });
        __classPrivateFieldSet(this, _SessionService_projectId, projectId, "f");
        __classPrivateFieldSet(this, _SessionService_frontendApiUrlSuffix, frontendApiUrlSuffix, "f");
        __classPrivateFieldSet(this, _SessionService_webAuthnService, new WebAuthnService(), "f");
        __classPrivateFieldSet(this, _SessionService_longSession, undefined, "f");
        __classPrivateFieldSet(this, _SessionService_setShortSessionCookie, setShortSessionCookie, "f");
        __classPrivateFieldSet(this, _SessionService_isPreviewMode, isPreviewMode, "f");
    }
    /**
     * Initializes the SessionService by registering a callback that is called when the shortSession changes.
     */
    async init() {
        const sessionConfig = await __classPrivateFieldGet(this, _SessionService_loadSessionConfig, "f").call(this);
        if (sessionConfig.err) {
            return sessionConfig.val;
        }
        __classPrivateFieldSet(this, _SessionService_sessionConfig, sessionConfig.val, "f");
        __classPrivateFieldSet(this, _SessionService_longSession, __classPrivateFieldGet(_a, _a, "m", _SessionService_getLongSessionToken).call(_a), "f");
        __classPrivateFieldSet(this, _SessionService_shortSession, __classPrivateFieldGet(_a, _a, "m", _SessionService_getShortTermSessionToken).call(_a), "f");
        // if the session is valid, we emit it
        if (__classPrivateFieldGet(this, _SessionService_shortSession, "f") && __classPrivateFieldGet(this, _SessionService_shortSession, "f").isValidForXMoreSeconds(0)) {
            log.debug('emit shortsession', __classPrivateFieldGet(this, _SessionService_shortSession, "f"));
            __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onShortSessionChange).call(this, __classPrivateFieldGet(this, _SessionService_shortSession, "f"));
        }
        else {
            await __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
        }
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setApisV2).call(this, __classPrivateFieldGet(this, _SessionService_longSession, "f"));
        // init scheduled session refresh
        __classPrivateFieldSet(this, _SessionService_refreshIntervalId, setInterval(() => {
            void __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
        }, shortSessionRefreshIntervalMs), "f");
        document.addEventListener('visibilitychange', () => {
            __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleVisibilityChange).call(this);
        });
        return null;
    }
    /**
     * Getter method for retrieving the short term session token.
     * @returns The short term session token or null if it's not set.
     */
    get shortSession() {
        return __classPrivateFieldGet(this, _SessionService_shortSession, "f");
    }
    /**
     * Getter method for retrieving the username.
     * @returns The username or null if it's not set.
     */
    getUser() {
        if (!__classPrivateFieldGet(this, _SessionService_shortSession, "f")) {
            return;
        }
        const sessionParts = __classPrivateFieldGet(this, _SessionService_shortSession, "f").value.split('.');
        const sessionPayload = JSON.parse(atob(sessionParts[1]));
        return sessionPayload;
    }
    /**
     * Exposes changes to the user object
     */
    get userChanges() {
        return __classPrivateFieldGet(this, _SessionService_userChanges, "f");
    }
    /**
     * Exposes changes to the shortSession
     */
    get shortSessionChanges() {
        return __classPrivateFieldGet(this, _SessionService_shortSessionChanges, "f");
    }
    /**
     * Exposes changes to the auth state
     */
    get authStateChanges() {
        return __classPrivateFieldGet(this, _SessionService_authStateChanges, "f");
    }
    dispose() {
        __classPrivateFieldGet(this, _SessionService_webAuthnService, "f").abortOngoingOperation();
    }
    async getFullUser(abortController) {
        return this.wrapWithErr(async () => __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserGet({ signal: abortController.signal }));
    }
    async appendPasskey() {
        const canUsePasskeys = await WebAuthnService.doesBrowserSupportPasskeys();
        const clientHandle = WebAuthnService.getClientHandle();
        const respStart = await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyAppendStart({
            clientInformation: {
                bluetoothAvailable: await WebAuthnService.canUseBluetooth(),
                canUsePasskeys: canUsePasskeys,
                clientEnvHandle: clientHandle !== null && clientHandle !== void 0 ? clientHandle : undefined,
                javaScriptHighEntropy: await WebAuthnService.getHighEntropyValues(),
            },
        });
        if (respStart.data.newClientEnvHandle) {
            WebAuthnService.setClientHandle(respStart.data.newClientEnvHandle);
        }
        if (respStart.data.appendNotAllowedReason) {
            switch (respStart.data.appendNotAllowedReason) {
                case 'passkey_already_exists':
                    return Err(new PasskeyAlreadyExistsError());
                case 'passkeys_not_supported':
                    return Err(new PasskeysNotSupported());
                default:
                    return Err(CorbadoError.ignore());
            }
        }
        const signedChallenge = await __classPrivateFieldGet(this, _SessionService_webAuthnService, "f").createPasskey(respStart.data.attestationOptions);
        if (signedChallenge.err) {
            return signedChallenge;
        }
        await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyAppendFinish({
            attestationResponse: signedChallenge.val,
            clientInformation: {
                bluetoothAvailable: await WebAuthnService.canUseBluetooth(),
                canUsePasskeys: canUsePasskeys,
                clientEnvHandle: clientHandle !== null && clientHandle !== void 0 ? clientHandle : undefined,
                javaScriptHighEntropy: await WebAuthnService.getHighEntropyValues(),
            },
        });
        return Ok(void 0);
    }
    async passkeyList(abortController) {
        return this.wrapWithErr(async () => __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyGet({ signal: abortController.signal }));
    }
    async passkeyDelete(id) {
        return Result.wrapAsync(async () => {
            await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserPasskeyDelete(id);
            return void 0;
        });
    }
    async logout() {
        log.debug('logging out user');
        await Result.wrapAsync(async () => {
            await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserSessionLogout({});
        });
        this.clear();
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onShortSessionChange).call(this, undefined);
    }
    /** Method to set Session
     * It sets the short term session token, long term session token, and username for the Corbado Application.
     * @param shortSessionValue The short term session token to be set.
     * @param longSession The long term session token to be set.
     */
    setSession(shortSessionValue, longSession) {
        const shortSession = new ShortSession(shortSessionValue);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setShortTermSessionToken).call(this, shortSession);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setApisV2).call(this, longSession !== null && longSession !== void 0 ? longSession : '');
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_onShortSessionChange).call(this, shortSession);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_setLongSessionToken).call(this, longSession);
    }
    /**
     * Method to delete Session.
     * It deletes the short term session token, long term session token, and username for the Corbado Application.
     */
    clear() {
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_deleteShortTermSessionToken).call(this);
        __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_deleteLongSessionToken).call(this);
        if (__classPrivateFieldGet(this, _SessionService_refreshIntervalId, "f")) {
            clearInterval(__classPrivateFieldGet(this, _SessionService_refreshIntervalId, "f"));
        }
    }
    async wrapWithErr(callback) {
        try {
            const r = await callback();
            return Ok(r.data);
        }
        catch (e) {
            if (e instanceof CorbadoError) {
                return Err(e);
            }
            return Err(CorbadoError.fromUnknownFrontendError(e));
        }
    }
}
_a = SessionService, _SessionService_usersApi = new WeakMap(), _SessionService_webAuthnService = new WeakMap(), _SessionService_setShortSessionCookie = new WeakMap(), _SessionService_isPreviewMode = new WeakMap(), _SessionService_projectId = new WeakMap(), _SessionService_frontendApiUrlSuffix = new WeakMap(), _SessionService_sessionConfig = new WeakMap(), _SessionService_shortSession = new WeakMap(), _SessionService_longSession = new WeakMap(), _SessionService_refreshIntervalId = new WeakMap(), _SessionService_userChanges = new WeakMap(), _SessionService_shortSessionChanges = new WeakMap(), _SessionService_authStateChanges = new WeakMap(), _SessionService_updateUser = new WeakMap(), _SessionService_updateAuthState = new WeakMap(), _SessionService_getSessionConfig = new WeakMap(), _SessionService_getShortSessionCookieConfig = new WeakMap(), _SessionService_loadSessionConfig = new WeakMap(), _SessionService_buildCorbadoFlags = new WeakMap(), _SessionService_instances = new WeakSet(), _SessionService_onShortSessionChange = function _SessionService_onShortSessionChange(shortSession) {
    const user = this.getUser();
    if (user && shortSession) {
        __classPrivateFieldGet(this, _SessionService_shortSessionChanges, "f").next(shortSession.value);
        __classPrivateFieldGet(this, _SessionService_updateAuthState, "f").call(this, AuthState.LoggedIn);
        __classPrivateFieldGet(this, _SessionService_updateUser, "f").call(this, user);
    }
    else {
        console.log('user is logged out', user, shortSession);
        __classPrivateFieldGet(this, _SessionService_shortSessionChanges, "f").next(undefined);
        __classPrivateFieldGet(this, _SessionService_updateAuthState, "f").call(this, AuthState.LoggedOut);
        __classPrivateFieldGet(this, _SessionService_updateUser, "f").call(this, undefined);
    }
}, _SessionService_setApisV2 = function _SessionService_setApisV2(longSession) {
    let frontendApiUrl = __classPrivateFieldGet(this, _SessionService_getSessionConfig, "f").call(this).frontendApiUrl;
    if (!frontendApiUrl || frontendApiUrl.length === 0) {
        frontendApiUrl = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDefaultFrontendApiUrl).call(this);
    }
    const config = new Configuration({
        apiKey: __classPrivateFieldGet(this, _SessionService_projectId, "f"),
        basePath: frontendApiUrl,
    });
    const axiosInstance = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_createAxiosInstanceV2).call(this, longSession);
    __classPrivateFieldSet(this, _SessionService_usersApi, new UsersApi(config, frontendApiUrl, axiosInstance), "f");
}, _SessionService_createAxiosInstanceV2 = function _SessionService_createAxiosInstanceV2(longSession) {
    const corbadoVersion = {
        name: 'web-core',
        sdkVersion: packageVersion,
    };
    const headers = {
        'Content-Type': 'application/json',
        'X-Corbado-WC-Version': JSON.stringify(corbadoVersion), // Example default version
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    };
    headers['X-Corbado-Flags'] = __classPrivateFieldGet(this, _SessionService_buildCorbadoFlags, "f").call(this);
    let axiosInstance;
    if (longSession) {
        axiosInstance = axios.create({
            withCredentials: true,
            headers: { ...headers, Authorization: `Bearer ${longSession}` },
        });
    }
    else {
        axiosInstance = axios.create({
            withCredentials: true,
        });
    }
    // We transform AxiosErrors into CorbadoErrors using axios interceptors.
    axiosInstance.interceptors.response.use(response => response, (error) => {
        const e = CorbadoError.fromAxiosError(error);
        log.warn('error', e);
        return Promise.reject(e);
    });
    return axiosInstance;
}, _SessionService_getShortTermSessionToken = function _SessionService_getShortTermSessionToken() {
    const localStorageValue = localStorage.getItem(shortSessionKey);
    if (localStorageValue) {
        return new ShortSession(localStorageValue);
    }
    // we currently only add this here to be backwards compatible with the legacy webcomponent
    // the idea is that a user can log into an application that uses the legacy webcomponent
    // the webcomponent will set the short term session token in a cookie and this package can then take it from there
    // as soon as the legacy webcomponent is removed, this can be removed as well
    const cookieValue = __classPrivateFieldGet(this, _a, "m", _SessionService_getCookieValue).call(this, shortSessionKey);
    if (cookieValue) {
        return new ShortSession(cookieValue);
    }
    return undefined;
}, _SessionService_getCookieValue = function _SessionService_getCookieValue(name) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
        return match[2];
    }
    return undefined;
}, _SessionService_deleteLongSessionToken = function _SessionService_deleteLongSessionToken() {
    localStorage.removeItem(longSessionKey);
    __classPrivateFieldSet(this, _SessionService_longSession, '', "f");
}, _SessionService_getLongSessionToken = function _SessionService_getLongSessionToken() {
    var _b;
    return (_b = localStorage.getItem(longSessionKey)) !== null && _b !== void 0 ? _b : '';
}, _SessionService_setShortTermSessionToken = function _SessionService_setShortTermSessionToken(value) {
    localStorage.setItem(shortSessionKey, value.toString());
    __classPrivateFieldSet(this, _SessionService_shortSession, value, "f");
    if (__classPrivateFieldGet(this, _SessionService_setShortSessionCookie, "f")) {
        const cookieConfig = __classPrivateFieldGet(this, _SessionService_getShortSessionCookieConfig, "f").call(this);
        document.cookie = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getShortSessionCookieString).call(this, cookieConfig, value);
    }
}, _SessionService_deleteShortTermSessionToken = function _SessionService_deleteShortTermSessionToken() {
    localStorage.removeItem(shortSessionKey);
    __classPrivateFieldSet(this, _SessionService_shortSession, undefined, "f");
    if (__classPrivateFieldGet(this, _SessionService_setShortSessionCookie, "f")) {
        const cookieConfig = __classPrivateFieldGet(this, _SessionService_getShortSessionCookieConfig, "f").call(this);
        document.cookie = __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_getDeleteShortSessionCookieString).call(this, cookieConfig);
    }
}, _SessionService_getShortSessionCookieString = function _SessionService_getShortSessionCookieString(config, value) {
    const expires = new Date(Date.now() + config.lifetimeSeconds * 1000).toUTCString();
    return `${shortSessionKey}=${value}; domain=${config.domain}; secure=${config.secure}; sameSite=${config.sameSite}; path=${config.path}; expires=${expires}`;
}, _SessionService_getDeleteShortSessionCookieString = function _SessionService_getDeleteShortSessionCookieString(config) {
    return `${shortSessionKey}=; domain=${config.domain}; secure=${config.secure}; sameSite=${config.sameSite}; path=${config.path}; expires=${new Date().toUTCString()}`;
}, _SessionService_setLongSessionToken = function _SessionService_setLongSessionToken(longSessionToken) {
    if (!longSessionToken) {
        return;
    }
    localStorage.setItem(longSessionKey, longSessionToken);
    __classPrivateFieldSet(this, _SessionService_longSession, longSessionToken, "f");
}, _SessionService_handleRefreshRequest = async function _SessionService_handleRefreshRequest() {
    // no shortSession => user is not logged in => nothing to refresh
    if (!__classPrivateFieldGet(this, _SessionService_shortSession, "f")) {
        log.debug('session refresh: no refresh, user not logged in');
        return;
    }
    // refresh, token too old
    if (!__classPrivateFieldGet(this, _SessionService_shortSession, "f").isValidForXMoreSeconds(shortSessionRefreshBeforeExpirationSeconds)) {
        await __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_refresh).call(this);
    }
    // nothing to do for now
    log.debug('no refresh, no refresh, token still valid');
    return;
}, _SessionService_refresh = async function _SessionService_refresh() {
    log.debug('session refresh: starting refresh');
    try {
        const options = {
            headers: {
                Authorization: `Bearer ${__classPrivateFieldGet(this, _SessionService_longSession, "f")}`,
            },
        };
        const response = await __classPrivateFieldGet(this, _SessionService_usersApi, "f").currentUserSessionRefresh(options);
        if (response.status !== 200) {
            log.warn(`refresh error, status code: ${response.status}`);
            return;
        }
        if (!response.data.shortSession) {
            log.warn('refresh error, missing short session');
            return;
        }
        this.setSession(response.data.shortSession, undefined);
    }
    catch (e) {
        // if it's a network error, we should do a retry
        // for all other errors, we should log out the user
        log.warn(e);
        await this.logout();
    }
}, _SessionService_handleVisibilityChange = function _SessionService_handleVisibilityChange() {
    if (document.hidden) {
        log.debug('session refresh: no refresh, page is hidden');
        return;
    }
    try {
        void __classPrivateFieldGet(this, _SessionService_instances, "m", _SessionService_handleRefreshRequest).call(this);
    }
    catch (e) {
        log.error(e);
    }
}, _SessionService_getDefaultFrontendApiUrl = function _SessionService_getDefaultFrontendApiUrl() {
    return `https://${__classPrivateFieldGet(this, _SessionService_projectId, "f")}.${__classPrivateFieldGet(this, _SessionService_frontendApiUrlSuffix, "f")}`;
};
