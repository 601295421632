import { useContext } from 'react';
import { CorbadoSessionContext } from '../contexts/CorbadoSessionContext';
export const useCorbadoExported = () => {
    const corbadoSession = useContext(CorbadoSessionContext);
    if (!corbadoSession) {
        throw new Error('Please make sure that your components are wrapped inside <CorbadoProvider />');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { corbadoApp: _, ...resp } = corbadoSession;
    return resp;
};
