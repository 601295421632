import { useStyletron } from "baseui";
import { Card, StyledBody, StyledThumbnail } from "baseui/card";
import { ToasterContainer } from "baseui/toast";

import { Title } from "@smc/components";
import { colors } from "@smc/theme/theme";

export function NotFound(props: any) {
  const [css] = useStyletron();

  return (
    <>
      <ToasterContainer autoHideDuration={10000} />
      <Title title="404" subtitle="Not Found" />
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh"
        })}
      >
        <Card
          title={`Nothing to see here.`}
          overrides={{
            Title: { style: { color: colors.emerald[700] } }
          }}
        >
          <StyledThumbnail src={"./jezza2.jpeg"} />
          <StyledBody>
            There's no page at this address. If you clicked a link (you didn't),
            it's probably broken.
          </StyledBody>
        </Card>
      </div>
    </>
  );
}
