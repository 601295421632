/**
 * Enum representing the authentication state of a user.
 * @enum {number}
 * @property {number} LoggedOut - The user is not authenticated.
 * @property {number} LoggedIn - The user is authenticated.
 */
export var AuthState;
(function (AuthState) {
    AuthState[AuthState["LoggedOut"] = 0] = "LoggedOut";
    AuthState[AuthState["LoggedIn"] = 1] = "LoggedIn";
})(AuthState || (AuthState = {}));
