import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LastIdentifier } from '../../../components/authentication/login-init/LastIdentifier';
import { LoginForm } from '../../../components/authentication/login-init/LoginForm';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import { SocialLoginButtons } from '../../../components/ui/SocialLoginButtons';
import { Header } from '../../../components/ui/typography/Header';
import { SubHeader } from '../../../components/ui/typography/SubHeader';
import { Text } from '../../../components/ui/typography/Text';
export const LoginInit = ({ block }) => {
    const { t } = useTranslation('translation', { keyPrefix: `login.login-init.login-init` });
    const [loading, setLoading] = useState(false);
    const [socialLoadingInProgress, setSocialLoadingInProgress] = useState(undefined);
    const [showLastIdentifier, setShowLastIdentifier] = useState(!!block.data.lastIdentifier);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [onComponentClick, setOnComponentClick] = useState(() => { });
    useEffect(() => {
        setLoading(false);
        if (block.data.socialData.finished && !block.error) {
            const socialAbort = new AbortController();
            void block.finishSocialVerify(socialAbort).finally(() => setSocialLoadingInProgress(false));
            setSocialLoadingInProgress(true);
            return () => {
                socialAbort.abort();
            };
        }
        else {
            setSocialLoadingInProgress(false);
        }
        if (block.data.loginIdentifierError) {
            setShowLastIdentifier(false);
        }
        return void 0;
    }, [block]);
    // we have to be very careful with conditionalUI as WebKit based browser historically had a number of "restrictions" on how we can start it
    useEffect(() => {
        void block.startConditionalUIOnPageLoad().then(result => {
            if (result) {
                console.log('CUI: page load');
                void block.continueWithConditionalUI();
            }
        });
        void block.startConditionalUIOnFirstUserInteraction().then(result => {
            if (result) {
                console.log('CUI: first click');
                setOnComponentClick(() => {
                    return () => {
                        console.log('calling continueWithConditionalUI');
                        void block.continueWithConditionalUI();
                    };
                });
            }
        });
    }, []);
    const headerText = useMemo(() => t('header'), [t]);
    const subheaderText = useMemo(() => t('subheader'), [t]);
    const signUpText = useMemo(() => t('text_signup'), [t]);
    const flowChangeButtonText = useMemo(() => t('button_signup'), [t]);
    const textDivider = useMemo(() => t('text_divider'), [t]);
    const startSocialLogin = (providerType) => {
        setSocialLoadingInProgress(true);
        void block.startSocialVerify(providerType);
    };
    // user explicitly discards the last identifier offered to him => we will clear if from localstorage
    const discardOfferedLastIdentifier = () => {
        block.discardOfferedLastIdentifier();
        setShowLastIdentifier(false);
    };
    return (React.createElement("div", { onClick: onComponentClick, className: 'cb-login-init' },
        React.createElement(Header, { size: 'lg' }, headerText),
        React.createElement(SubHeader, null,
            subheaderText,
            block.common.appName),
        showLastIdentifier ? (React.createElement(LastIdentifier, { block: block, socialLoadingInProgress: socialLoadingInProgress, loading: loading, setLoading: setLoading, switchToLoginForm: discardOfferedLastIdentifier })) : (React.createElement(LoginForm, { block: block, loading: loading, socialLoadingInProgress: socialLoadingInProgress, setLoading: setLoading })),
        React.createElement(SocialLoginButtons, { dividerText: textDivider, socialLogins: block.data.socialData.providers, t: t, socialLoadingInProgress: socialLoadingInProgress, onClick: startSocialLogin }),
        block.isSignupEnabled() && (React.createElement(Text, { level: '2', fontWeight: 'normal', textColorVariant: 'script', className: 'cb-auth-change-section' },
            signUpText,
            React.createElement(SecondaryButton, { colorVariant: 'link', disabled: loading, onClick: () => block.switchToSignup() }, flowChangeButtonText)))));
};
