import { useState } from "react";

import { useStyletron } from "baseui";
import { Tab, Tabs } from "baseui/tabs-motion";

import { Title } from "@smc/components";

import ComponentVersions from "./ComponentVersions";
import HealthPanel from "./HealthPanel";

export default function Vitals() {
  const [css] = useStyletron();
  const [activeKey, setActiveKey] = useState("0");

  return (
    <>
      <Title
        title="Vitals"
        subtitle={activeKey === "0" ? "Component versions" : "API health"}
      />
      <div
        className={css({
          paddingTop: "10px",
          height: "100vh",
          width: "75vw",
          justifyContent: "center",
          margin: "auto"
        })}
      >
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey as string);
          }}
          activateOnFocus
          fill="fixed"
        >
          <Tab title="Components">
            <ComponentVersions />
          </Tab>
          <Tab title="API Health">
            <HealthPanel />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
