import React from 'react';
import { SecondaryButton } from '../buttons/SecondaryButton';
import ErrorMessage from '../errors/ErrorMessage';
import { Text } from '../typography/Text';
import { PhoneInput } from './PhoneInput';
export const PhoneInputField = ({ label, labelLink, id, errorMessage, initialPhoneNumber, disabled, className, autoFocus, initialCountry = 'US', autoComplete = 'tel', onChange, }) => {
    const onChange_ = (value) => {
        if (onChange) {
            onChange(value || '');
        }
    };
    return (React.createElement("div", { className: `cb-input-field${className ? ` ${className}` : ''}` },
        label && (React.createElement("label", { htmlFor: id, className: 'cb-input-label' },
            React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', textColorVariant: 'script', className: 'cb-input-label-text' }, label),
            labelLink && (React.createElement("span", { className: 'cb-input-label-link' },
                React.createElement(SecondaryButton, { colorVariant: 'link', onClick: labelLink.onClick }, labelLink.text))))),
        React.createElement(PhoneInput, { initialCountry: initialCountry, initialPhoneNumber: initialPhoneNumber, hasError: !!errorMessage, autoComplete: autoComplete, disabled: disabled, autoFocus: autoFocus, onChange: onChange_ }),
        errorMessage && React.createElement(ErrorMessage, { message: errorMessage })));
};
