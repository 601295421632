import deviceIconSrc from '@corbado/shared-ui/assets/device-icon.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const DeviceIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, deviceIconSrc);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, alt: 'device-icon', ...props }));
});
