import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, LoadingSpinner, Text } from '../../../components';
import { EmailLinkError } from './EmailLinkError';
export const EmailLinkVerification = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `${block.authType}.email-verify.email-link-verification`,
    });
    useEffect(() => {
        const abortController = new AbortController();
        void block.validateEmailLink(abortController);
        return () => {
            abortController.abort();
        };
    }, []);
    const headerText = useMemo(() => t('header'), [t]);
    const subheaderText = useMemo(() => t('subheader'), [t]);
    if (block.data.translatedError) {
        return React.createElement(EmailLinkError, { block: block });
    }
    return (React.createElement("div", { className: 'cb-email-link-verification-block' },
        React.createElement(Header, null, headerText),
        React.createElement(Text, { level: '2' }, subheaderText),
        React.createElement(LoadingSpinner, { className: 'cb-email-link-verification-spinner' })));
};
