import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Header, PrimaryButton, SecondaryButton, Text } from '../../../components';
import { LockIcon } from '../../../components/ui/icons/LockIcon';
import { PasskeyAppendAfterHybridIcon } from '../../../components/ui/icons/PasskeyAppendAfterHybridIcon';
export const PasskeyAppendAfterHybrid = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `${block.authType}.passkey-append-after-hybrid.passkey-append-after-hybrid`,
    });
    const [loading, setLoading] = useState(false);
    const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyText = useMemo(() => t('body'), [t]);
    const dontShowAgainText = useMemo(() => t('text_dontShowAgain'), [t]);
    const primaryButtonText = useMemo(() => t('button_appendPasskey'), [t]);
    const secondaryButtonText = useMemo(() => t('button_continue'), [t]);
    const handleContinue = useCallback(() => {
        setLoading(true);
        return void block.passkeyAppend();
    }, [block]);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void handleContinue();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleContinue]);
    const handleDontShowAgainChange = useCallback(() => {
        setDontShowAgainChecked(!dontShowAgainChecked);
        block.skipBlockInFuture(!dontShowAgainChecked);
    }, [dontShowAgainChecked]);
    return (React.createElement("div", { className: 'cb-pk-append-after-hybrid' },
        React.createElement(Header, null, headerText),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-icon' },
            React.createElement(PasskeyAppendAfterHybridIcon, null)),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-description' },
            React.createElement(LockIcon, { className: 'cb-pk-append-after-hybrid-description-icon' }),
            React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary' }, bodyText)),
        React.createElement(Checkbox, { label: dontShowAgainText, checked: dontShowAgainChecked, onChange: handleDontShowAgainChange }),
        React.createElement(PrimaryButton, { onClick: handleContinue, isLoading: loading }, primaryButtonText),
        React.createElement("div", { className: 'cb-pk-append-after-hybrid-button' },
            React.createElement(SecondaryButton, { onClick: () => void block.skipPasskeyAppend(), disabled: loading }, secondaryButtonText))));
};
