import React from 'react';
import { Text } from '../typography/Text';
export const SecondaryButton = ({ disabled, className, children, onClick, }) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };
    const computedClassName = `cb-link cb-secondary-link ${disabled ? 'cb-disabled' : ''} ${className ? ` ${className}` : ''}`;
    return (React.createElement("button", { type: 'button', onClick: handleClick, className: 'cb-link' },
        React.createElement(Text, { level: '2', fontWeight: 'normal', fontFamilyVariant: 'secondary', textColorVariant: 'secondary', className: computedClassName }, children)));
};
