import { styled } from "styletron-react";

const spinKeyframes = `@keyframes spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }`;

const SpinningImage = styled("img", {
  animation: "spin 5s linear infinite"
});

const LoadingLabel = styled("label", {
  fontFamily: "sans-serif",
  fontStyle: "italic",
  color: "#38bdf8", // Approximate value for text-sky-600
  textDecoration: "underline",
  textDecorationColor: "#059669" // Approximate value for decoration-emerald-600
});

export function LoadingScreen() {
  return (
    <p>
      <LoadingLabel>Loading...</LoadingLabel>
      <br />
      <style>{spinKeyframes}</style>
      <SpinningImage alt="loading" src="./loading.png" />
    </p>
  );
}
