import { useStyletron } from "baseui";

import { colors } from "@smc/theme/theme";

type TitleProps = {
  title: string;
  subtitle: string;
};

const Title = ({ title, subtitle }: TitleProps) => {
  const [css] = useStyletron();
  return (
    <div className={css({ textAlign: "center" })}>
      <h1
        className={css({
          color: colors.sky[700],
          fontVariantCaps: "small-caps"
        })}
      >
        {title}
      </h1>
      <p
        className={css({
          color: colors.emerald[700],
          fontVariantCaps: "small-caps"
        })}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default Title;
