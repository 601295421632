import { DateTime, Duration } from "luxon";

import JaguarHistoryItem from "../types/jaguar-history-item";
import LockWindow from "../types/lock-window";

export function createLockwindows(array: JaguarHistoryItem[]): LockWindow[] {
  const lockWindows = [];
  let lockWindow = new LockWindow(
    array[0].isLocked,
    array[0].timestamp,
    array[0].timestamp
  );

  for (const element of array) {
    if (element.isLocked === lockWindow.IsLocked) {
      lockWindow.End = element.timestamp;
    } else {
      lockWindows.push(lockWindow);
      lockWindow = new LockWindow(
        element.isLocked,
        element.timestamp,
        element.timestamp
      );
    }
  }
  lockWindow.End = array[array.length - 1].timestamp;
  lockWindows.push(lockWindow);
  return lockWindows;
}

export function getCountForTimePeriod(timePeriodInDays: number): number {
  // 4 per hour
  // 96 per day
  // 672 per week
  // 2688 per month
  // 2734 per 2 weeks
  // 5470 per 4 weeks
  return timePeriodInDays * 96;
}

export function isIntervalStart(
  count: number,
  removeInterval: number
): boolean {
  return count === removeInterval;
}

export function isIntervalEnd(count: number): boolean {
  return count > 1;
}

export function toDecimalPlaces(number: number, decimalPlaces = 2): string {
  return number.toFixed(decimalPlaces);
}

export function getSemanticVersion(dotnetVersion?: string): string {
  if (dotnetVersion) {
    return dotnetVersion.replace(/\.0$/, "");
  } else {
    return "Unknown";
  }
}

export function getDaysSinceDataStarted(): Duration {
  return DateTime.now().diff(DateTime.fromISO("2023-04-10"), "days");
}

export function hasMetMinimumDayThreshold(threshold: number): boolean {
  return getDaysSinceDataStarted().days >= threshold;
}

export function getDaySelectOption(days: number): {
  id: string;
  label: string;
} {
  return {
    id: days.toString(),
    label: `${days} day${days === 1 ? "" : "s"}`
  };
}
