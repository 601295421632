import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import InputField from '../../../components/ui/input/InputField';
import { PhoneInputField } from '../../../components/ui/input/PhoneInputField';
import { Header } from '../../../components/ui/typography/Header';
import { SubHeader } from '../../../components/ui/typography/SubHeader';
export const MissingFields = ({ block }) => {
    var _a;
    const { t } = useTranslation('translation', { keyPrefix: `signup.missing-fields` });
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const usernameRef = useRef();
    const [phone, setPhone] = useState(null);
    const [phoneInput, setPhoneInput] = useState('');
    useEffect(() => {
        setUsername(block.data.userName);
        setPhone(block.data.phone);
        setLoading(false);
    }, [block]);
    const headerText = useMemo(() => t('header'), [t]);
    const subheaderText = useMemo(() => t('subheader'), [t]);
    const buttonText = useMemo(() => t('button'), [t]);
    const phoneFieldLabel = useMemo(() => t('textField_phone'), [t]);
    const usernameFieldLabel = useMemo(() => t('textField_username'), [t]);
    const handleSubmit = useCallback((e) => {
        var _a;
        e.preventDefault();
        setLoading(true);
        const identifiers = {
            phone: phoneInput,
            userName: (_a = usernameRef.current) === null || _a === void 0 ? void 0 : _a.value,
        };
        void block.updateUserData(identifiers);
    }, [block, phoneInput, setLoading]);
    const attacheRef = (el) => {
        var _a;
        if (el && !usernameRef.current) {
            usernameRef.current = el;
            if (!usernameRef.current.value) {
                usernameRef.current.value = ((_a = block.data.userName) === null || _a === void 0 ? void 0 : _a.value) || '';
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { size: 'lg' }, headerText),
        React.createElement(SubHeader, null,
            subheaderText,
            block.common.appName),
        React.createElement("form", { className: 'cb-form', onSubmit: handleSubmit },
            username && (React.createElement(InputField, { label: usernameFieldLabel, id: 'username', name: 'username', autoComplete: 'username', errorMessage: username === null || username === void 0 ? void 0 : username.translatedError, ref: attacheRef })),
            phone && (React.createElement(PhoneInputField, { label: phoneFieldLabel, id: 'phone', errorMessage: phone === null || phone === void 0 ? void 0 : phone.translatedError, initialCountry: 'US', initialPhoneNumber: (_a = block.data.phone) === null || _a === void 0 ? void 0 : _a.value, onChange: setPhoneInput })),
            React.createElement(PrimaryButton, { type: 'submit', className: 'cb-signup-form-submit-button', isLoading: loading, onClick: handleSubmit }, buttonText))));
};
