import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class PasskeyAppendedBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.data = {};
        this.type = BlockTypes.PasskeyAppended;
        this.initialScreen = ScreenNames.PasskeySuccess;
        this.authType = blockBody.authType;
    }
    async continue() {
        const newBlock = await this.app.authProcessService.skipBlock();
        this.updateProcess(newBlock);
        return;
    }
}
