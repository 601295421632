import { useEffect, useState } from "react";

import { useCorbado } from "@corbado/react";

import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { ToasterContainer, toaster } from "baseui/toast";
import { useStopwatch } from "react-timer-hook";

import { Position, StealMap, StealMarker } from "@smc/components";
import { useAxios } from "@smc/hooks";

export default function Tracker() {
  const { getFullUser } = useCorbado();
  const { apiClient } = useAxios();
  const { seconds, minutes, hours, isRunning, start, reset } = useStopwatch();
  const [css, theme] = useStyletron();
  const [position, setPosition] = useState<Position>({
    latitude: 2.7359083,
    longitude: 73.0122562
  });
  const [journeyId, setJourneyId] = useState<string | null>(null);

  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition(position.coords);
      },
      (error) => {
        toaster.negative(`Failed to get location: ${error}`, {});
      }
    );
  }

  const startJourney = async () => {
    getLocation();
    const fullUser = await getFullUser();
    if (fullUser.ok !== true || !fullUser.val.hasOwnProperty("id")) {
      toaster.negative("Failed to get user", {});
      return;
    }
    start();
    const response = await apiClient.post(
      "journeys",
      JSON.stringify({
        user: fullUser.val.id
      })
    );
    const journey = response.data;

    setJourneyId(journey.id);
  };

  const logJourneyStep = async () => {
    await apiClient.post(
      `journeys/${journeyId}/logs`,
      JSON.stringify({
        latitude: position.latitude,
        longitude: position.longitude
      })
    );
  };

  const endJourney = async () => {
    reset();
    logJourneyStep();
    setJourneyId(null);
  };

  useEffect(() => {
    getLocation();

    if (journeyId !== null && isRunning) {
      if (seconds % 5 === 0) {
        logJourneyStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <>
      <ToasterContainer autoHideDuration={3000} />
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          justifyItems: "center",
          display: "flex",
          padding: "1rem"
        }}
      >
        <Button
          onClick={(e) => {
            startJourney();
          }}
          kind={KIND.primary}
          disabled={journeyId !== null}
        >
          {journeyId === null
            ? "Start Tracking"
            : `Tracking ${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`}
        </Button>
        <div style={{ width: "1rem" }} />
        <Button
          onClick={(e) => {
            endJourney();
          }}
          kind={KIND.secondary}
          disabled={journeyId === null}
        >
          Stop Tracking{journeyId === null ? "" : ` ${journeyId}`}
        </Button>
      </div>
      <StealMap
        position={position}
        zoom={17}
       
        containerStyle={{
          height: "80vh",
          width: "80%",
          margin: "auto",
          border: `10px solid ${theme.colors.accent400}`
        }}
      >
        <StealMarker
          position={position}
          googleIcon={
            <img
              src="../loading.png"
              alt="Jag Icon"
              width="50%"
              className={css({
                transform: "scalex(-1)",
                transition: "transform 15s",
                transformStyle: "preserve-3d"
                // animation: "spin 15s linear infinite"
              })}
            />
          }
        />
      </StealMap>
    </>
  );
}
