import { CorbadoApp } from '@corbado/web-core';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { CorbadoSessionContext } from './CorbadoSessionContext';
export const CorbadoSessionProvider = ({ children, corbadoAppInstance, corbadoAppParams, }) => {
    const [corbadoApp] = useState(() => corbadoAppInstance !== null && corbadoAppInstance !== void 0 ? corbadoAppInstance : new CorbadoApp(corbadoAppParams));
    const [globalError, setGlobalError] = useState();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [shortSession, setShortSession] = useState();
    const init = async () => {
        setLoading(true);
        const res = await corbadoApp.init();
        if (res.err) {
            setGlobalError(res.val);
            return;
        }
        setLoading(false);
    };
    useEffect(() => {
        void init();
        const userSub = corbadoApp.sessionService.userChanges.subscribe(value => {
            setUser(value);
        });
        const authStateSub = corbadoApp.sessionService.authStateChanges.subscribe(value => {
            setIsAuthenticated(!!value);
        });
        const shortSessionSub = corbadoApp.sessionService.shortSessionChanges.subscribe((value) => {
            setShortSession(value);
        });
        return () => {
            userSub.unsubscribe();
            authStateSub.unsubscribe();
            shortSessionSub.unsubscribe();
        };
    }, []);
    const getPasskeys = useCallback((abortController) => {
        return corbadoApp.sessionService.passkeyList(abortController !== null && abortController !== void 0 ? abortController : new AbortController());
    }, [corbadoApp]);
    const appendPasskey = useCallback(() => {
        return corbadoApp.sessionService.appendPasskey();
    }, [corbadoApp]);
    const logout = useCallback(() => {
        return corbadoApp.sessionService.logout();
    }, [corbadoApp]);
    const deletePasskey = useCallback((id) => {
        return corbadoApp.sessionService.passkeyDelete(id);
    }, [corbadoApp]);
    const getFullUser = useCallback((abortController) => {
        return corbadoApp === null || corbadoApp === void 0 ? void 0 : corbadoApp.sessionService.getFullUser(abortController !== null && abortController !== void 0 ? abortController : new AbortController());
    }, [corbadoApp]);
    return (React.createElement(CorbadoSessionContext.Provider, { value: {
            corbadoApp,
            shortSession,
            loading,
            user,
            isAuthenticated,
            appendPasskey,
            getFullUser,
            getPasskeys,
            deletePasskey,
            logout,
            globalError,
        } }, children));
};
