import { getPlatformType } from '@corbado/shared-ui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, SecondaryButton, Text } from '../../../components';
import { FaceIdIcon } from '../../../components/ui/icons/FaceIdIcon';
import { FingerPrintIcon } from '../../../components/ui/icons/FingerPrintIcon';
export const PasskeyBackground = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `login.passkey-verify.passkey-background`,
    });
    const [loading, setLoading] = useState(false);
    const passkeyLoginStarted = useRef(false);
    const platform = useMemo(() => getPlatformType(), []);
    const headerText = useMemo(() => React.createElement("span", null, t('header')), [t]);
    const bodyTitleText = useMemo(() => t('body_title'), [t]);
    const bodyDescriptionText = useMemo(() => t('body_description'), [t]);
    useEffect(() => {
        if (passkeyLoginStarted.current) {
            return;
        }
        passkeyLoginStarted.current = true;
        void block.passkeyLogin(true);
    }, []);
    return (React.createElement("div", { className: 'cb-pk-verify' },
        React.createElement(Text, { level: '6', fontWeight: 'bold', className: 'cb-pk-verify-header', textColorVariant: 'header' }, headerText),
        React.createElement("span", { className: 'cb-pk-verify-icons-section' },
            React.createElement(FingerPrintIcon, { className: 'cb-pk-verify-icons-section-icon', platform: platform }),
            React.createElement(FaceIdIcon, { className: 'cb-pk-verify-icons-section-icon', platform: platform })),
        React.createElement("div", { className: 'cb-pk-verify-body-section' },
            React.createElement(Text, { level: '5', fontWeight: 'bold' }, bodyTitleText),
            React.createElement(Text, { level: '3', fontWeight: 'bold' }, bodyDescriptionText)),
        React.createElement(LoadingSpinner, { className: 'cb-pk-verify-spinner' }),
        React.createElement("div", { className: 'cb-pk-verify-fallbacks' }, block.data.availableFallbacks.map(fallback => (React.createElement(SecondaryButton, { key: fallback.label, disabled: loading, onClick: () => {
                setLoading(true);
                return void fallback.action();
            } }, t(fallback.label)))))));
};
