import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../ui/Dialog';
import { CancelIcon } from '../ui/icons/CancelIcon';
export const PasskeyDelete = ({ passkeyId, onPasskeyDelete }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'passkey-list.dialog_delete' });
    const [isDialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => {
        setDialogOpen(true);
    };
    const closeDialog = () => {
        setDialogOpen(false);
    };
    const confirmDelete = async () => {
        await onPasskeyDelete(passkeyId);
        closeDialog();
    };
    return (React.createElement("div", { className: 'cb-passkey-list-icon cb-passkey-list-icon-right' },
        React.createElement(CancelIcon, { className: 'cb-passkey-list-delete', onClick: openDialog }),
        React.createElement(Dialog, { isOpen: isDialogOpen, header: t('header'), body: t('body'), confirmText: t('button_confirm'), cancelText: t('button_cancel'), onClose: closeDialog, onConfirm: confirmDelete })));
};
