import { LoginIdentifierType } from '@corbado/shared-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, SecondaryButton, Text } from '../../ui';
import { PasskeyDefaultIcon } from '../../ui/icons/PasskeyDefaultIcon';
import { RightIcon } from '../../ui/icons/RightIcon';
export const LastIdentifier = ({ block, socialLoadingInProgress, loading, setLoading, switchToLoginForm, }) => {
    var _a;
    const { t } = useTranslation('translation', { keyPrefix: `login.login-init.login-init.last_identifier` });
    const title = useMemo(() => t('title'), [t]);
    const skipText = useMemo(() => t('text_skip'), [t]);
    const skipButtonText = useMemo(() => t('button_skip'), [t]);
    const isLoginButtonDisabled = socialLoadingInProgress || loading;
    const login = () => {
        if (isLoginButtonDisabled) {
            return;
        }
        setLoading(true);
        const lastIdentifier = block.data.lastIdentifier;
        if (!lastIdentifier) {
            return;
        }
        block
            .start(lastIdentifier.value, lastIdentifier.type === LoginIdentifierType.Phone)
            .finally(() => setLoading(false));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `cb-last-identifier ${isLoginButtonDisabled ? 'cb-last-identifier-disabled' : ''}`, onClick: login },
            React.createElement("div", { className: 'cb-last-identifier-icon' },
                React.createElement(PasskeyDefaultIcon, null)),
            React.createElement("div", { className: 'cb-last-identifier-details' },
                React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', fontWeight: 'bold' }, title),
                React.createElement(Text, { level: '2', className: 'cb-last-identifier-value-text', fontFamilyVariant: 'secondary', textColorVariant: 'secondary' }, (_a = block.data.lastIdentifier) === null || _a === void 0 ? void 0 : _a.value)),
            React.createElement("div", { className: 'cb-last-identifier-icon' }, loading ? React.createElement(LoadingSpinner, { className: 'cb-last-identifier-spinner' }) : React.createElement(RightIcon, null))),
        React.createElement(Text, { level: '2', fontWeight: 'normal', textColorVariant: 'script', className: 'cb-last-identifier-skip-button' },
            skipText,
            React.createElement(SecondaryButton, { colorVariant: 'link', disabled: loading, onClick: switchToLoginForm }, skipButtonText))));
};
