// Enum representing the type of user flow, either sign up or login
export var AuthType;
(function (AuthType) {
    AuthType["SignUp"] = "signup";
    AuthType["Login"] = "login";
})(AuthType || (AuthType = {}));
export var LoginIdentifierType;
(function (LoginIdentifierType) {
    LoginIdentifierType["Email"] = "email";
    LoginIdentifierType["Phone"] = "phone";
    LoginIdentifierType["Username"] = "username";
})(LoginIdentifierType || (LoginIdentifierType = {}));
export const createLoginIdentifierType = (v) => {
    switch (v) {
        case LoginIdentifierType.Email:
            return LoginIdentifierType.Email;
        case LoginIdentifierType.Phone:
            return LoginIdentifierType.Phone;
        case LoginIdentifierType.Username:
            return LoginIdentifierType.Username;
        default:
            throw new Error(`Unknown login identifier type: ${v}`);
    }
};
// Enum representing the names of different sign up flows
export var SignUpFlowNames;
(function (SignUpFlowNames) {
    SignUpFlowNames["PasskeySignupWithFallback"] = "PasskeySignupWithFallback";
    SignUpFlowNames["SignupWithPasskeyAppend"] = "SignupWithPasskeyAppend";
})(SignUpFlowNames || (SignUpFlowNames = {}));
// Enum representing the names of different login flows
export var LoginFlowNames;
(function (LoginFlowNames) {
    LoginFlowNames["PasskeyLoginWithFallback"] = "PasskeyLoginWithFallback";
})(LoginFlowNames || (LoginFlowNames = {}));
// Enum representing common screens that are used in multiple flows
export var ScreenNames;
(function (ScreenNames) {
    ScreenNames["SignupInit"] = "signup-init";
    ScreenNames["LoginInit"] = "login-init";
    ScreenNames["End"] = "end";
    ScreenNames["EmailOtpVerification"] = "email-otp-verification";
    ScreenNames["EmailLinkSent"] = "email-link-sent";
    ScreenNames["PhoneOtp"] = "phone-otp";
    ScreenNames["EmailLinkVerification"] = "email-link-verification";
    ScreenNames["SocialVerify"] = "social-verify";
    ScreenNames["MissingFields"] = "missing-fields";
    ScreenNames["PasskeyError"] = "passkey-error";
    ScreenNames["PasskeyErrorLight"] = "passkey-error-light";
    ScreenNames["PasskeyAppend"] = "passkey-append";
    ScreenNames["PasskeyAppendAfterHybrid"] = "passkey-append-after-hybrid";
    ScreenNames["PasskeySuccess"] = "passkey-success";
    ScreenNames["PasskeyBackground"] = "passkey-background";
    ScreenNames["PasskeyHybrid"] = "passkey-hybrid";
    ScreenNames["EditUserData"] = "edit-user-data";
    ScreenNames["EditEmail"] = "edit-email";
    ScreenNames["EditPhone"] = "edit-phone";
    ScreenNames["ContinueOnOtherEnv"] = "continue-on-other-device";
})(ScreenNames || (ScreenNames = {}));
export var BlockTypes;
(function (BlockTypes) {
    BlockTypes["LoginInit"] = "login-init";
    BlockTypes["SignupInit"] = "signup-init";
    BlockTypes["EmailVerify"] = "email-verify";
    BlockTypes["PhoneVerify"] = "phone-verify";
    BlockTypes["MissingFields"] = "missing-fields";
    BlockTypes["PasskeyAppend"] = "passkey-append";
    BlockTypes["PasskeyVerify"] = "passkey-verify";
    BlockTypes["PasskeyAppended"] = "passkey-appended";
    BlockTypes["PasskeyAppendAfterHybrid"] = "passkey-append-after-hybrid";
    BlockTypes["Completed"] = "completed";
    BlockTypes["ContinueOnOtherEnv"] = "continue-on-other-env";
})(BlockTypes || (BlockTypes = {}));
export var InitState;
(function (InitState) {
    InitState[InitState["Initializing"] = 0] = "Initializing";
    InitState[InitState["Failed"] = 1] = "Failed";
    InitState[InitState["Success"] = 2] = "Success";
})(InitState || (InitState = {}));
export var ContinueOnOtherEnvReasons;
(function (ContinueOnOtherEnvReasons) {
    ContinueOnOtherEnvReasons["EmailLinkVerified"] = "email-link-verified";
    ContinueOnOtherEnvReasons["ProcessAlreadyCompleted"] = "process-already-completed";
})(ContinueOnOtherEnvReasons || (ContinueOnOtherEnvReasons = {}));
export const initScreenBlocks = [BlockTypes.SignupInit, BlockTypes.LoginInit];
