import * as React from "react";

import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faBoxOpen,
  faChalkboardUser,
  faCircleXmark,
  faGasPump,
  faHeartPulse,
  faKey,
  faMap,
  faMoon,
  faOilCan,
  faPeopleRobbery,
  faPersonCane,
  faRightFromBracket,
  faRoad,
  faRoute,
  faSquarePersonConfined,
  faStreetView,
  faSun,
  faTableList,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const gasIcon = () => <FontAwesomeIcon icon={faGasPump} />;
export const oilIcon = () => <FontAwesomeIcon icon={faOilCan} />;
export const peopleIcon = () => <FontAwesomeIcon icon={faPeopleRobbery} />;
export const roadIcon = () => <FontAwesomeIcon icon={faRoad} />;
export const heartIcon = () => <FontAwesomeIcon icon={faHeartPulse} />;
export const xIcon = () => <FontAwesomeIcon icon={faCircleXmark} />;
export const logoutIcon = () => <FontAwesomeIcon icon={faRightFromBracket} />;
export const legacyIcon = () => <FontAwesomeIcon icon={faPersonCane} />;
export const darkIcon = () => <FontAwesomeIcon icon={faMoon} />;
export const lightIcon = () => <FontAwesomeIcon icon={faSun} />;
export const keyIcon = () => <FontAwesomeIcon icon={faKey} />;
export const mapIcon = () => <FontAwesomeIcon icon={faMap} />;
export const editIcon = () => <FontAwesomeIcon icon={faTableList} />;
export const personIcon = () => (
  <FontAwesomeIcon icon={faSquarePersonConfined} />
);
export const adminIcon = () => <FontAwesomeIcon icon={faChalkboardUser} />;
export const usersIcon = () => <FontAwesomeIcon icon={faUsers} />;
export const trackIcon = () => <FontAwesomeIcon icon={faStreetView} />;
export const routeIcon = () => <FontAwesomeIcon icon={faRoute} />;

export const mapboxIcon = () => <FontAwesomeIcon icon={faBoxOpen} />;
export const mapkitIcon = () => <FontAwesomeIcon icon={faApple} />;
export const googleIcon = () => <FontAwesomeIcon icon={faGoogle} />;
export const leafletIcon = () => <FontAwesomeIcon icon={faMap} />;
