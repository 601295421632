import { useStyletron } from "baseui";
import { Spinner } from "baseui/spinner";

export default function LoadingV5() {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        textAlign: "center",
        margin: "auto",
        width: "95vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      })}
    >
      <Spinner $size="150" $borderWidth="50" />;
    </div>
  );
}
