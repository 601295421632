import { useRef, useState } from "react";

import { useStyletron } from "baseui";
import { Stepper } from "baseui/stepper";
import ReactMapboxGl from "react-mapbox-gl";
import { StyleObject } from "styletron-react";

import Position from "./Position";

type MapProps = {
  containerStyle: StyleObject;
  center: Position;
  children?: any;
};

export const MapboxMap = ({ containerStyle, center, children }: MapProps) => {
  const mapContainerRef = useRef(null);
  const [css] = useStyletron();
  const [value, setValue] = useState(12);

  const Mapbox = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX ?? ""
  });

  return (
    <>
      <div
        className={css({
          position: "fixed",
          bottom: "10px",
          left: "10px",
          padding: "1rem",
          fontSize: "2rem",
          cursor: "cell",
          zIndex: 1000000
        })}
      >
        <Stepper
          value={value}
          setValue={(newValue) => setValue(newValue)}
          maxValue={12}
          minValue={8}
        />
      </div>
      <Mapbox
        ref={mapContainerRef}
        // eslint-disable-next-line react/style-prop-object
        style={`mapbox://styles/mapbox/streets-v${value}`}
        className={css(containerStyle)}
        center={[center.longitude, center.latitude]}
        zoom={[14]}
        children={children}
        />
    </>
  );
};
