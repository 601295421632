import { useContext } from 'react';
import FlowHandlerContext from '../contexts/FlowHandlerContext';
const useFlowHandler = (context = FlowHandlerContext) => {
    const flowHandler = useContext(context);
    if (!flowHandler) {
        throw new Error('Please make sure that your components are wrapped inside <FlowHandlerProvider />');
    }
    return flowHandler;
};
export default useFlowHandler;
