import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Text } from '../../../components';
import { ExclamationIcon } from '../../../components/ui/icons/ExclamationIcon';
export function EmailLinkError({ block }) {
    const { t } = useTranslation('translation', {
        keyPrefix: `${block.authType}.email-verify.email-link-error`,
    });
    const subheaderText = useMemo(() => t('subheader'), [t]);
    const bodyText = useMemo(() => t('body'), [t]);
    return (React.createElement("div", { className: 'cb-email-link-error-block' },
        React.createElement(Header, null, block.data.translatedError),
        React.createElement(Text, { level: '2', className: 'cb-email-link-error-subheader' }, subheaderText),
        React.createElement("div", { className: 'cb-email-link-error-icon-container' },
            React.createElement(ExclamationIcon, { className: 'cb-email-link-error-icon' })),
        React.createElement(Text, { level: '1', fontFamilyVariant: 'secondary' }, bodyText)));
}
