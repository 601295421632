import { useEffect, useState } from "react";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faBoxOpen,
  faCloudMeatball,
  faCode,
  faDatabase,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { Card } from "baseui/card";
import { ProgressBar } from "baseui/progress-bar";
import { KIND as TAG_KIND, Tag } from "baseui/tag";
import { Textarea } from "baseui/textarea";

import { useAxios } from "@smc/hooks";
import { Health, HealthStatus } from "@smc/types/health";

const healthStatusToTagKind = (status: HealthStatus) => {
  switch (status) {
    case HealthStatus.ok:
      return TAG_KIND.positive;
    case HealthStatus.degraded:
      return TAG_KIND.warning;
    case HealthStatus.error:
      return TAG_KIND.negative;
    case HealthStatus.unknown:
      return TAG_KIND.neutral;
    default:
      return TAG_KIND.brown;
  }
};

const unknownHealth: Health = {
  status: {
    name: "function",
    health: {
      status: HealthStatus.unknown,
      performance: 0,
      error: "Not Connected"
    }
  },
  dependencies: [
    {
      name: "auth",
      health: {
        status: HealthStatus.unknown,
        performance: 0,
        error: "Not Connected"
      }
    },
    {
      name: "database",
      health: {
        status: HealthStatus.unknown,
        performance: 0,
        error: "Not Connected"
      }
    }
  ]
};

const getDependencyIcon = (name: string) => {
  switch (name.toLowerCase()) {
    case "auth":
      return faKey;
    case "database":
      return faDatabase;
    case "googlemaps":
      return faGoogle;
    case "mapbox":
      return faBoxOpen;
    case "neon":
      return faCloudMeatball;
    default:
      return faCode;
  }
};

export default function HealthPanel() {
  const { apiClient } = useAxios();
  const [css] = useStyletron();
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const [healthOverall, setHealthOverall] = useState<Health>(unknownHealth);

  useEffect(() => {
    fetchHealth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHealth = async () => {
    try {
      const response = await apiClient.get("health/extended");
      setHealthOverall(await response.data);
    } catch (e) {
      setHealthOverall(unknownHealth);
    }
  };

  return (
    <Card title="API Health">
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        })}
      >
        <Tag
          variant="solid"
          kind={healthStatusToTagKind(healthOverall.status.health.status)}
          closeable={false}
        >
          {healthOverall.status.health.status}
        </Tag>
        <ProgressBar
          value={healthOverall.status.health.performance}
          maxValue={10000}
          showLabel
          getProgressLabel={(value) => `${value}ms`}
        />
      </div>
      <div className={css({ height: "10px" })} />

      {healthOverall.dependencies.map(({ name, health }) => (
        <>
          <Card>
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              })}
            >
              <Tag
                variant="solid"
                kind={healthStatusToTagKind(health.status)}
                closeable={false}
              >
                {<FontAwesomeIcon icon={getDependencyIcon(name)} />} {name}
              </Tag>

              {health.error && <Textarea value={health.error} error readOnly />}

              <ProgressBar
                value={health.performance ?? 20000}
                maxValue={healthOverall.status.health.performance}
                showLabel
                getProgressLabel={(value) => `${value}ms`}
                overrides={
                  health.performance >
                  healthOverall.status.health.performance! /
                    healthOverall.dependencies.length
                    ? {
                        BarProgress: {
                          style: ({ $theme }) => ({
                            backgroundColor: $theme.colors.negative
                          })
                        }
                      }
                    : {}
                }
              />
            </div>
          </Card>
          <div className={css({ height: "10px" })} />
        </>
      ))}

      <div className={css({ height: "10px" })}></div>
      <div
        className={css({
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        })}
      >
        <Button onClick={fetchHealth}>Refresh</Button>
        <Button
          kind={KIND.secondary}
          onClick={() => setIsAccordionActive(!isAccordionActive)}
        >
          Do Nothing
        </Button>
      </div>
    </Card>
  );
}
