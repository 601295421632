import React, { useMemo } from 'react';
import ErrorHandlingContext from './ErrorHandlingContext';
export const ErrorHandlingProvider = ({ children, customerSupportEmail, isDevMode, }) => {
    const contextValue = useMemo(() => ({
        customerSupportEmail,
        isDevMode,
    }), [customerSupportEmail, isDevMode]);
    return React.createElement(ErrorHandlingContext.Provider, { value: contextValue }, children);
};
export default ErrorHandlingProvider;
