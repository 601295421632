import { ChangeEvent, FormEvent, useState } from "react";

import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { DateTime } from "luxon";
import { styled } from "styletron-react";

import { Refuel } from "../../types/refuel";
import DatePickerComponent from "./datepicker";
import NumberInput from "./number-input";
import TextInput from "./text-input";

const FormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "1rem",
  boxSizing: "border-box",
  overflowY: "auto",
  overflowX: "hidden"
});

const PetrolFormContents = styled("form", {
  flexDirection: "column",
  alignItems: "center",
  height: "100%"
});

const PetrolFormHeader = styled("label", {
  color: "#0284c7",
  fontSize: "1.25rem",
  lineHeight: "2.5rem",
  fontWeight: "bold",
  fontVariant: "small-caps",
  fontFamily: "sans-serif",
  margin: 0
});

export const PetrolForm = () => {
  const [formData, setFormData] = useState<Refuel>({
    petrolStation: "",
    fillUpTime: DateTime.now(),
    location: "",
    litres: 0,
    pencePerLitre: 0,
    totalCost: 0
  });

  const handleInputChange =
    (field: keyof Refuel) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (
        field === "litres" ||
        field === "pencePerLitre" ||
        field === "totalCost"
      ) {
        setFormData({ ...formData, [field]: parseFloat(value) });
      } else {
        setFormData({ ...formData, [field]: value });
      }
    };

  // const handleDateChange = (date: Date) => {
  //   setFormData({ ...formData, fillUpTime: DateTime.fromJSDate(date) });
  // };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault(); // Prevents the default form submission action

    // const response =
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/petrol`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    });
    // const data: {} = await response.json();
  };

  return (
    <FormContainer>
      <PetrolFormHeader>Add Petrol</PetrolFormHeader>
      <PetrolFormContents>
        <FormControl>
          <TextInput
            id="petrolStation"
            value={formData.petrolStation}
            onChange={handleInputChange("petrolStation")}
            placeholder="Petrol Station"
          />
        </FormControl>
        <FormControl>
          <TextInput
            id="location"
            value={formData.location}
            onChange={handleInputChange("location")}
            placeholder="Location"
          />
        </FormControl>
        <FormControl>
          <DatePickerComponent />
        </FormControl>
        <FormControl>
          <NumberInput
            id="litres"
            value={formData.petrolStation}
            onChange={handleInputChange("litres")}
            placeholder="Litres"
          />
        </FormControl>
        <FormControl>
          <NumberInput
            id="pencePerLitre"
            value={formData.petrolStation}
            onChange={handleInputChange("pencePerLitre")}
            placeholder="Pence Per Litre"
          />
        </FormControl>
        <FormControl>
          <NumberInput
            id="totalCost"
            value={formData.petrolStation}
            onChange={handleInputChange("totalCost")}
            placeholder="Total Cost"
          />
        </FormControl>
        <FormControl>
          <Button
            overrides={{
              BaseButton: {
                style: {
                  width: "75%",
                  marginTop: "1rem"
                }
              }
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </FormControl>
      </PetrolFormContents>
    </FormContainer>
  );
};
