import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import { Divider } from '../../../components/ui/Divider';
import { PasskeyErrorIcon } from '../../../components/ui/icons/PasskeyErrorIcon';
import { Header } from '../../../components/ui/typography/Header';
import { Text } from '../../../components/ui/typography/Text';
export const PasskeyError = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `signup.passkey-append.passkey-error`,
    });
    const [loading, setLoading] = useState(false);
    const [changingBlock, setChangingBlock] = useState(false);
    const headerText = useMemo(() => t('header'), [t]);
    const bodyText = useMemo(() => t('body'), [t]);
    const skipButtonText = useMemo(() => t('button_cancel'), [t]);
    const dividerLabel = useMemo(() => t('text_divider'), [t]);
    const tryAgainButtonText = useMemo(() => t('button_tryAgain'), [t]);
    const fallbackButtonText = useMemo(() => { var _a, _b; return t((_b = (_a = block.data.preferredFallbackOnError) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : ''); }, [t, block.data.preferredFallbackOnError]);
    const primaryAction = useCallback(async () => {
        setLoading(true);
        if (block.data.preferredFallbackOnError) {
            setChangingBlock(true);
            await block.data.preferredFallbackOnError.action();
        }
        else {
            await block.passkeyAppend();
        }
        setLoading(false);
    }, [block]);
    useEffect(() => {
        return () => {
            block.cancelPasskeyOperation();
        };
    }, []);
    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Enter') {
                void primaryAction();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [primaryAction]);
    async function secondaryAction() {
        setLoading(true);
        await block.passkeyAppend();
        setLoading(false);
    }
    const showDivider = !block.data.canBeSkipped && !!block.data.preferredFallbackOnError;
    return (React.createElement("div", { className: 'cb-pk-error' },
        React.createElement(Header, null, headerText),
        React.createElement("div", { className: 'cb-pk-error-bloc-icon' },
            React.createElement(PasskeyErrorIcon, null)),
        React.createElement(Text, { level: '2', fontFamilyVariant: 'secondary', className: 'cb-pk-error-bloc-description' }, bodyText),
        React.createElement(PrimaryButton, { onClick: () => void primaryAction(), isLoading: loading, disabled: changingBlock }, block.data.preferredFallbackOnError ? fallbackButtonText : tryAgainButtonText),
        showDivider && (React.createElement(React.Fragment, null,
            React.createElement(Divider, { label: dividerLabel, className: 'cb-pk-error-bloc-divider' }),
            React.createElement(SecondaryButton, { disabled: changingBlock, onClick: () => void secondaryAction() }, tryAgainButtonText))),
        block.data.canBeSkipped && (React.createElement(SecondaryButton, { className: 'cb-pk-error-bloc-skip-button', disabled: changingBlock, onClick: () => {
                setChangingBlock(true);
                void block.skipPasskeyAppend();
            } }, skipButtonText))));
};
