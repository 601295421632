import { useCorbado } from "@corbado/react";

import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody, StyledThumbnail } from "baseui/card";
import { ToasterContainer, toaster } from "baseui/toast";

import { Title } from "@smc/components";
import { colors } from "@smc/theme/theme";

export function Home(props: any) {
  const [css] = useStyletron();
  const { user } = useCorbado();

  return (
    <>
      <ToasterContainer autoHideDuration={10000} />
      <Title title="Home" subtitle="The Jaaaagggg" />
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh"
        })}
      >
        <Card
          title={`Hello, ${user?.email ?? "Stranger"}`}
          overrides={{
            Title: { style: { color: colors.emerald[700] } }
          }}
        >
          <StyledThumbnail src={"./jezza2.jpeg"} />
          <StyledBody>Let's go for a drive in the Jaaaagggg.</StyledBody>
          <StyledAction>
            <Button
              onClick={() => {
                toaster.info("Twarrrt", {});
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
            >
              Let's Go
            </Button>
          </StyledAction>
        </Card>
      </div>
    </>
  );
}
