const storageKey = 'cbo_auth_process';
export class AuthProcess {
    constructor(id, expiresAt, frontendApiUrl) {
        this.id = id;
        this.expiresAt = expiresAt;
        this.frontendApiUrl = frontendApiUrl;
    }
    isValid() {
        return this.expiresAt > Date.now() / 1000;
    }
    static loadFromStorage() {
        const serialized = localStorage.getItem(storageKey);
        if (!serialized) {
            return undefined;
        }
        const { id, expiresAt, frontendApiUrl } = JSON.parse(serialized);
        const process = new AuthProcess(id, expiresAt, frontendApiUrl);
        if (!process.isValid()) {
            return undefined;
        }
        return process;
    }
    persistToStorage() {
        localStorage.setItem(storageKey, JSON.stringify({
            id: this.id,
            expiresAt: this.expiresAt,
            frontendApiUrl: this.frontendApiUrl,
        }));
    }
    static clearStorage() {
        localStorage.removeItem(storageKey);
    }
}
