import { useEffect, useState } from "react";

import { useStyletron } from "baseui";

import { StealMap, StealMarker } from "@smc/components";
import { useAxios } from "@smc/hooks";
import { Drive } from "@smc/types/drive";

export default function JourneysMap() {
  const { apiClient } = useAxios();
  const [css, theme] = useStyletron();
  const [drives, setDrives] = useState<Drive[]>([]);
  useEffect(() => {
    fetchDrives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDrives = async () => {
    const response = await apiClient.get("drives");
    setDrives(await response.data);
  };

  return (
    <StealMap
      containerStyle={{
        height: "80vh",
        width: "80%",
        margin: "auto",
        border: `10px solid ${theme.colors.accent400}`
      }}
    >
      {drives.map((drive) => (
        <StealMarker
          key={drive.id}
          position={{
            latitude: +drive.latitude,
            longitude: +drive.longitude
          }}
          googleIcon={
            <img
              src="../loading.png"
              alt="Jag Icon"
              width="24"
              height="24"
              className={css({
                transform: "scaleX(-1)"
              })}
            />
          }
        />
      ))}
    </StealMap>
  );
}
