import { createDarkTheme, createTheme } from "baseui";

/* colours
zinc-950 #09090b
slate-700 334155
sky-600 0284c7
emerald-600 059669
*/
export const colors = {
  slate: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155", // slate-700
    800: "#1e293b",
    900: "#0f172a",
    950: "#020617"
  },
  zinc: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    800: "#27272a",
    900: "#18181b",
    950: "#09090b" // zinc-950
  },
  emerald: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669", // emerald-600
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
    950: "#022c22"
  },
  sky: {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0284c7", // sky-600
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
    950: "#082f49"
  }
};

const primitives = {
  accent: colors.sky[600],
  accent50: colors.sky[50],
  accent100: colors.sky[100],
  accent200: colors.sky[200],
  accent300: colors.sky[300],
  accent400: colors.sky[400],
  accent500: colors.sky[500],
  accent600: colors.sky[600],
  accent700: colors.sky[700],
  accent800: colors.sky[800],
  accent900: colors.sky[900],
  accent950: colors.sky[950],
  accentNegative: colors.emerald[600],
  accentNegative50: colors.emerald[50],
  accentNegative100: colors.emerald[100],
  accentNegative200: colors.emerald[200],
  accentNegative300: colors.emerald[300],
  accentNegative400: colors.emerald[400],
  accentNegative500: colors.emerald[500],
  accentNegative600: colors.emerald[600],
  accentNegative700: colors.emerald[700],
  accentNegative800: colors.emerald[800],
  accentNegative900: colors.emerald[900],
  accentNegative950: colors.emerald[950],
  accentWarning: colors.emerald[600],
  accentWarning50: colors.emerald[50],
  accentWarning100: colors.emerald[100],
  accentWarning200: colors.emerald[200],
  accentWarning300: colors.emerald[300],

  accentWarning400: colors.emerald[400],
  accentWarning500: colors.emerald[500],
  accentWarning600: colors.emerald[600],
  accentWarning700: colors.emerald[700],
  accentWarning800: colors.emerald[800],
  accentWarning900: colors.emerald[900],
  accentWarning950: colors.emerald[950],
  accentPositive: colors.sky[600],
  accentPositive50: colors.sky[50],
  accentPositive100: colors.sky[100],
  accentPositive200: colors.sky[200],

  accentPositive300: colors.sky[300],
  accentPositive400: colors.sky[400],
  accentPositive500: colors.sky[500],
  accentPositive600: colors.sky[600],
  accentPositive700: colors.sky[700],
  accentPositive800: colors.sky[800],
  accentPositive900: colors.sky[900],
  accentPositive950: colors.sky[950],
  accentInformation: colors.sky[600],
  accentInformation50: colors.sky[50],
  accentInformation100: colors.sky[100],
  accentInformation200: colors.sky[200],
  accentInformation300: colors.sky[300],
  accentInformation400: colors.sky[400],
  accentInformation500: colors.sky[500],
  accentInformation600: colors.sky[600],
  accentInformation700: colors.sky[700],
  accentInformation800: colors.sky[800],
  accentInformation900: colors.sky[900],
  accentInformation950: colors.sky[950],
  accentLight: colors.zinc[50],
  accentLight50: colors.zinc[50],
  accentLight100: colors.zinc[100],

  accentLight200: colors.zinc[200],
  accentLight300: colors.zinc[300],
  accentLight400: colors.zinc[400],
  accentLight500: colors.zinc[500],
  accentLight600: colors.zinc[600],

  accentLight700: colors.zinc[700],
  accentLight800: colors.zinc[800],
  accentLight900: colors.zinc[900],
  accentLight950: colors.zinc[950],
  accentDark: colors.slate[700],
  accentDark50: colors.slate[50],
  accentDark100: colors.slate[100],
  accentDark200: colors.slate[200],
  accentDark300: colors.slate[300],
  accentDark400: colors.slate[400],
  accentDark500: colors.slate[500],
  accentDark600: colors.slate[600],
  accentDark700: colors.slate[700],
  accentDark800: colors.slate[800],
  accentDark900: colors.slate[900],
  accentDark950: colors.slate[950],
  contentPrimary: colors.sky[600],
  contentSecondary: colors.emerald[600],
  contentTertiary: colors.slate[700],
  contentNegative: colors.emerald[600],
  contentWarning: colors.emerald[600],
  contentPositive: colors.sky[600],
  contentInformation: colors.sky[600],
  contentLight: colors.zinc[600],
  contentDark: colors.slate[600],
  contentBackground: colors.zinc[950],
  backgroundPrimary: colors.slate[700],
  backgroundSecondary: colors.zinc[950],
  backgroundTertiary: colors.zinc[900],
  backgroundNegative: colors.emerald[600],
  backgroundWarning: colors.emerald[600],
  backgroundPositive: colors.sky[600]
};

const overrides = {
  primitives,
  colors: {
    buttonPrimaryFill: colors.sky[100],
    buttonPrimaryText: colors.sky[950],
    buttonPrimaryHover: colors.sky[200],
    buttonPrimaryActive: colors.sky[300],
    buttonPrimarySelectedFill: colors.sky[200],
    buttonPrimarySelectedText: colors.sky[950],
    buttonPrimarySpinnerForeground: colors.sky[700],
    buttonPrimarySpinnerBackground: colors.sky[300],

    buttonSecondaryFill: colors.emerald[100],
    buttonSecondaryText: colors.emerald[950],
    buttonSecondaryHover: colors.emerald[200],
    buttonSecondaryActive: colors.emerald[300],
    buttonSecondarySelectedFill: colors.emerald[200],
    buttonSecondarySelectedText: colors.emerald[950],
    buttonSecondarySpinnerForeground: colors.emerald[700],
    buttonSecondarySpinnerBackground: colors.emerald[300],

    contentPrimary: colors.sky[600],
    contentSecondary: colors.emerald[600],

    backgroundPrimary: colors.slate[700],
    primaryFontFamily: "Segoe UI, Roboto, sans-serif"
  }
};

export const LightStealTheme = createTheme({
  ...overrides,
  primitives,
  name: "LightStealTheme"
});
export const DarkStealTheme = createDarkTheme({
  ...overrides,
  primitives,
  name: "DarkStealTheme"
});
