import { useStyletron } from "baseui";
import { Card, StyledBody, StyledThumbnail } from "baseui/card";
import { ToasterContainer } from "baseui/toast";

import { Title } from "@smc/components";
import { colors } from "@smc/theme/theme";

export function NotAllowed(props: any) {
  const [css] = useStyletron();

  return (
    <>
      <ToasterContainer autoHideDuration={10000} />
      <Title title="403" subtitle="Not Allowed" />
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh"
        })}
      >
        <Card
          title={`You're not allowed to be here.`}
          overrides={{
            Title: { style: { color: colors.emerald[700] } }
          }}
        >
          <StyledThumbnail src={"./jezza2.jpeg"} />
          <StyledBody>
            You don't have permission to access this page. If your name's not
            Ben, it's probably not for you.
          </StyledBody>
        </Card>
      </div>
    </>
  );
}
