import { BlockTypes, ScreenNames } from '../constants';
import { Block } from './Block';
export class MissingFieldsBlock extends Block {
    constructor(app, flowHandler, common, errorTranslator, blockBody) {
        super(app, flowHandler, common, errorTranslator);
        this.type = BlockTypes.MissingFields;
        this.initialScreen = ScreenNames.MissingFields;
        //This is only temporary, the type should be GeneralBlockMissingFields
        //TODO: Change the type to GeneralBlockMissingFields
        const data = blockBody.data;
        let phone = null;
        let userName = null;
        data.identifiers.forEach(item => {
            switch (item.type) {
                case 'phone':
                    phone = {
                        value: item.identifier,
                        translatedError: errorTranslator.translateWithIdentifier(item.error, item.type),
                    };
                    break;
                case 'username':
                    userName = {
                        value: item.identifier,
                        translatedError: errorTranslator.translateWithIdentifier(item.error, item.type),
                    };
                    break;
            }
        });
        this.authType = blockBody.authType;
        this.data = {
            phone: phone,
            userName: userName,
        };
    }
    async updateUserData(identifiers) {
        const loginIdentifiers = [];
        if (identifiers.phone) {
            loginIdentifiers.push({ type: 'phone', identifier: identifiers.phone });
        }
        if (identifiers.userName) {
            loginIdentifiers.push({ type: 'username', identifier: identifiers.userName });
        }
        //This is only temporary, the method should be fillMissingFields
        //TODO: Change the method to fillMissingFields
        const b = await this.app.authProcessService.initSignup(loginIdentifiers);
        this.updateProcess(b);
    }
}
