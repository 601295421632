export interface Drive {
  id: number;
  latitude: string;
  longitude: string;
  timestamp: string;
  driveType: DriveType;
}

export enum DriveType {
  Start = "start",
  End = "end"
}
