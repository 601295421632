import passkeyAppendedSrc from '@corbado/shared-ui/assets/passkey-appended.svg';
import { memo, useRef } from 'react';
import React from 'react';
import { useIconWithTheme } from '../../../hooks/useIconWithTheme';
import { Icon } from './Icon';
export const PasskeyAppendedIcon = memo(props => {
    const svgRef = useRef(null);
    const { logoSVG } = useIconWithTheme(svgRef, passkeyAppendedSrc);
    return (React.createElement(Icon, { src: logoSVG, ref: svgRef, ...props }));
});
