import { LoginIdentifierType } from '@corbado/shared-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/ui/buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/ui/buttons/SecondaryButton';
import InputField from '../../../components/ui/input/InputField';
import { PhoneInputField } from '../../../components/ui/input/PhoneInputField';
import { Header } from '../../../components/ui/typography/Header';
export const EditUserData = ({ block }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: `signup.passkey-append.edit-user-data`,
    });
    const [passkeyUserHandle, setPasskeyUserHandle] = useState(block.data.userHandle);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const passkeyUserHandleInputRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = passkeyUserHandleInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const headerText = useMemo(() => t(`header.${block.data.userHandleType}`), [t]);
    const primaryButtonText = useMemo(() => t('button_submit'), [t]);
    const secondaryButtonText = useMemo(() => t('button_cancel'), [t]);
    const inputFieldComputedProps = useMemo(() => {
        let type, autoComplete, name;
        if (block.data.userHandleType === LoginIdentifierType.Email) {
            type = 'email';
            autoComplete = 'email';
            name = 'email';
        }
        else if (block.data.userHandleType === LoginIdentifierType.Username) {
            type = 'username';
            autoComplete = 'username';
            name = 'username';
        }
        else {
            return undefined;
        }
        return {
            type,
            autoComplete,
            name,
        };
    }, [block.data.userHandleType, errorMessage, passkeyUserHandle]);
    const handleConfirm = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        let error;
        switch (block.data.userHandleType) {
            case LoginIdentifierType.Email:
                error = await block.updateEmail(passkeyUserHandle);
                break;
            case LoginIdentifierType.Phone:
                error = await block.updatePhone(passkeyUserHandle);
                break;
            case LoginIdentifierType.Username:
                error = await block.updateUsername(passkeyUserHandle);
                break;
            default:
                throw new Error('Invalid user handle type');
        }
        if (error) {
            setErrorMessage(error);
            setLoading(false);
            return;
        }
    }, [block, passkeyUserHandle]);
    return (React.createElement("form", { className: 'cb-edit-data-section', onSubmit: e => void handleConfirm(e) },
        React.createElement(Header, { className: 'cb-edit-data-section-header' }, headerText),
        block.data.userHandleType === LoginIdentifierType.Phone ? (React.createElement(PhoneInputField, { initialPhoneNumber: passkeyUserHandle, errorMessage: errorMessage, onChange: setPasskeyUserHandle })) : (React.createElement(InputField, { ...inputFieldComputedProps, value: passkeyUserHandle, errorMessage: errorMessage, ref: passkeyUserHandleInputRef, onChange: e => setPasskeyUserHandle(e.target.value) })),
        React.createElement(PrimaryButton, { type: 'submit', isLoading: loading, onClick: e => {
                const noChange = passkeyUserHandle === block.data.userHandle;
                if (noChange) {
                    block.showPasskeyAppend();
                    return;
                }
                void handleConfirm(e);
            } }, primaryButtonText),
        React.createElement(SecondaryButton, { className: 'cb-edit-data-section-back-button', onClick: () => block.showPasskeyAppend() }, secondaryButtonText)));
};
